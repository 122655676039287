import React from "react";
import { Modal } from "antd";
import { useState } from "react";
import ViewTermsModal from "../components/ViewTermsModal";
import ViewPolicyModal from "../components/ViewPolicyModal";

export default function TermsAndPolicyModal({ open, onCancel }) {
  const [modalPolicy, setModalPolicy] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);

  const handleViewPolicy = () => {
    setModalPolicy(true);
  };

  const handleViewTerms = () => {
    setModalTerms(true);
  };

  return (
    <Modal
      style={{ top: 10 }}
      title="Privacy and Terms"
      onCancel={onCancel}
      open={open}
      footer={null}
    >
      <div>
        <h3>Please review the following:</h3>
        <div>
          <a href="#/" onClick={() => handleViewPolicy()}>
            1. View privacy policy
          </a>
        </div>
        <div>
          <a href="#/" onClick={() => handleViewTerms()}>
            2. View terms of use
          </a>
        </div>
      </div>
      {modalPolicy && (
        <ViewPolicyModal
          open={modalPolicy}
          onCancel={() => {
            setModalPolicy(false);
          }}
        />
      )}
      {modalTerms && (
        <ViewTermsModal
          open={modalTerms}
          onCancel={() => {
            setModalTerms(false);
          }}
        />
      )}
    </Modal>
  );
}
