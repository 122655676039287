import axios from "axios";
import Utils from "./utils/utils";

window.addEventListener("beforeunload", (event) => {
  const lastUnloadTime = Date.now();
  localStorage.setItem("lastUnloadTime", lastUnloadTime.toString());
});

const instance = axios.create({
  baseURL: `${Utils.ApiEndpoint}`,
});
instance.interceptors.request.use(
  (config) => {
    var date = new Date();
    var timezoneOffset = date.getTimezoneOffset();
    config.headers["x-timezone-offset"] = timezoneOffset;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (401 === error.response.status || 403 === error.response.status)
    ) {
      console.log("Got 401 response.");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_token_type");
      localStorage.removeItem("external_token");
      localStorage.removeItem("auth_company_id");
      console.log("Local storage cleared");
      if (window.location.pathname.indexOf("login") === -1) {
        const returnUrl = encodeURIComponent(
          `${window.location.pathname}${window.location.search ?? ""}`,
        );
        const url = `/login?ReturnUrl=${returnUrl}`;

        window.location.replace(url);
      } else {
        window.location.replace("/login");
      }
    } else {
      return Promise.reject(error);
    }
  },
);
/**
 *
 * @param {*} method
 * @param  {...any} data
 * @returns {Promise<AxiosResponse<any, any>>}
 */
const runAPI = (method, ...data) =>
  instance[method](...data).catch((e) => {
    return { error: e.message };
  });

export default instance;
function auth(pa) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const tk = auth_token_type + " " + auth_token;
  return { headers: { Authorization: tk }, params: pa };
}
function authForm(pa) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const tk = auth_token_type + " " + auth_token;
  return {
    headers: { Authorization: tk, "Content-Type": "multipart/form-data" },
  };
}
function Src(url) {
  return (
    Utils.ApiEndpoint +
    "/variable/image?path=" +
    url +
    "&token=" +
    localStorage.getItem("auth_token")
  );
}
const API = {
  document: {
    getForCreate: ({ brand_id, industry_id }) =>
      instance.get(
        "/documents/get-section-create",
        auth({ brand_id, industry_id }),
      ),
  },
  manifest: {
    activity: (id, page) => {
      let companyId = localStorage.getItem("auth_company_id");
      return instance.get(
        `/activity/${companyId}/manifest/${id}`,
        auth({ page: page }),
      );
    },
    details: (id) => {
      let companyId = localStorage.getItem("auth_company_id");
      return instance.get(`/activity/${companyId}/details/${id}`, auth());
    },
  },
  uploadImage: ({ FormData }) =>
    instance.post("/documents/upload-image", FormData, authForm()),
  role: {
    get: ({ id }) => instance.get("/roles/get/" + id, auth()),
    getAll: () => instance.get("/roles/get-all", auth()),
    getRolesLicenseType: ({ company_id }) =>
      instance.get(
        "/roles/get-roles-by-license-type",
        auth({ lt_company_id: company_id }),
      ),
    create: ({ data }) => runAPI("post", "/roles/create", data, auth()),
    update: ({ id, data }) =>
      runAPI("put", "/roles/update/" + id, data, auth()),
    remove: ({ id }) => runAPI("delete", "/roles/remove/" + id, auth()),
    getPermission: () => instance.get("/roles/get-permission", auth()),
  },
  permission: {
    getAll: () => instance.get("/permissions/get-all", auth()),
    create: ({ data }) => runAPI("post", "/permissions/create", data, auth()),
    update: ({ id, data }) =>
      runAPI("put", "/permissions/update/" + id, data, auth()),
    remove: ({ id }) => runAPI("delete", "/permissions/remove/" + id, auth()),
  },
  menu: {
    getAll: () => instance.get("/menus/get-all", auth()),
    getMenu: () => instance.get("/menus/get-menu", auth()),
  },
  profile: {
    me: () => instance.get("/profile/get-my-profile-by-user-id", auth()),
    get_signature_list: () =>
      instance.get("/profile/get-signature-list", auth()),

    updateDeviceToken: async (data) => {
      let header = auth();
      return await instance.post("/profile/update-device-token", data, header);
    },
  },
};
const keyEditor =
  "f94233f8035952b8ed1d02bd76712e35f8a0abf2e0902edc291d60793187b4fa";
// const keyEditor = 'wvjg6527soe5a11ml4srj57micpfnbvce7gm1u00bd4t8t10'
export { auth, API, Src, keyEditor };
