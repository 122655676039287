import React from "react";
import axios from "../configs";
import { toast } from "react-toastify";
import { useState } from "react";
import { List, theme, Segmented } from "antd";
import { Content } from "antd/es/layout/layout";

const CompanyReports = () => {
  const [byType, setbyType] = useState("Weekly");
  const [newUsers, setNewUsers] = React.useState([]);
  const [newOrders, setNewOders] = React.useState([]);
  const [totalNewUsers, setTotalNewUsers] = React.useState(0);
  const [totalNewOrders, setTotalNewOders] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;

  const fetchData = async (by_type) => {
    try {
      setIsLoading(true);
      await axios
        .get(`/reports/reports-to-admin?type=${by_type}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setNewUsers(response.data.users);
          setNewOders(response.data.orders);
          let count = 0;
          response.data.users.forEach((item) => {
            count += item.count;
          });
          setTotalNewUsers(count);
          count = 0;
          response.data.orders.forEach((item) => {
            count += item.count;
          });
          setTotalNewOders(count);

          toast.success(response.data.result);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData(byType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byType]);

  return (
    <>
      <div>
        <Content
          style={{
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Segmented
            disabled={isLoading}
            options={["Weekly", "Monthly", "Yearly"]}
            onChange={(value) => {
              setbyType(value);
            }}
          />
        </Content>
        <h3>
          New Users by {byType} ({totalNewUsers})
        </h3>
        <Content
          style={{
            padding: 24,
            margin: 0,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <List
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={newUsers}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <span>
                      Name: <strong>{item.company_name}</strong>
                    </span>
                  }
                  description={
                    <span>
                      Total: <strong>{item.count}</strong>
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        </Content>
        <h3>
          New Orders by {byType} ({totalNewOrders})
        </h3>
        <Content
          style={{
            padding: 24,
            margin: 0,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <List
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={newOrders}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <span>
                      Name: <strong>{item.company_name}</strong>
                    </span>
                  }
                  description={
                    <span>
                      Total: <strong>{item.count}</strong>
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        </Content>
      </div>
    </>
  );
};
export default CompanyReports;
