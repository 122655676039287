import React from "react";
import Utils from "../utils/utils";
import { Button, Avatar } from "antd";

const ExternalLogin = ({ props }) => {
  // const googleLogin = () => {
  //   window.location.href = `${Utils.ApiEndpoint}/auth/google/login`;
  // };

  const azureLogin = () => {
    // var auth_provider = "azure-oidc"
    // var login_url = props.producerLoginRedirectEndpoint + "?auth_provider=" + auth_provider
    // window.location.href = login_url
    window.location.href = `${Utils.ApiEndpoint}/auth/microsoft/login`;
    //window.location.replace("/auth/microsoft/login")
  };

  const authenticate = () => {
    var authToken = (window.location.search.match(/authToken=([^&]+)/) ||
      [])[1];
    if (authToken) {
      localStorage.setItem("auth_token", authToken);
      localStorage.setItem("auth_token_type", "Bearer");
      let user = Utils.getUserInfo();
      localStorage.setItem("auth_company_id", user.company_id);
      window.location.replace("/");
    }
  };

  React.useEffect(() => {
    authenticate();
  }, []);

  return (
    <section>
      {/* <div style={{padding: '15px'}}>
        <Button style={{height: '45px'}}  onClick={googleLogin} variant="outlined">
        <Avatar variant="square" alt="Google" src="/static/google.svg" />
         <span style={{textTransform: 'initial'}}>Sign in with Google</span> 
        </Button>
      </div> */}
      <div style={{ padding: "15px" }}>
        <Button
          style={{ height: "45px" }}
          onClick={azureLogin}
          variant="outlined"
        >
          <Avatar
            variant="square"
            alt="Microsoft"
            src="/static/microsoft.svg"
          />
          <span style={{ textTransform: "initial" }}>
            Sign in with Microsoft
          </span>
        </Button>
      </div>
    </section>
  );
};

export default ExternalLogin;
