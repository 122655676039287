import { createContext, useContext, useEffect, useState } from "react";
import { API } from "../configs";
import { useAppStore } from "./appstate";
import Utils from "./utils";
import { getToken } from "firebase/messaging";
import { messaging, onMessageListener } from "../firebase";
import { toast } from "react-toastify";
const AuthContext = createContext(null);

const useVisibilityChange = () => {
  const [isForeground, setIsForeground] = useState(true);
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsForeground(document.visibilityState === "visible");
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return isForeground;
};

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const token = localStorage.getItem("auth_token");
  const auth_company_id = localStorage.getItem("auth_company_id");
  const token_type = localStorage.getItem("auth_token_type");
  const appInfo = useAppStore((state) => state.AppInfo);
  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const isForeground = useVisibilityChange();

  const login = async (company_id, token, token_type, external_token) => {
    localStorage.setItem("auth_company_id", company_id);
    localStorage.setItem("auth_token", token);
    localStorage.setItem("auth_token_type", token_type);
    if (external_token) {
      localStorage.setItem("external_token", external_token);
    }
    let newInfo = { ...appInfo };
    let user = Utils.getUserInfo();
    newInfo.user = user;

    updateAppInfo(newInfo);
    const lastUnloadTime = Date.now();
    localStorage.setItem("lastUnloadTime", lastUnloadTime.toString());
    await getCurrentUser();
    requestPermission();
  };

  const logout = () => {
    localStorage.removeItem(
      "auth_token",
      "auth_token_type",
      "auth_company_id",
      "external_token",
    );
    setCurrentUser(null);
    setIsAuthenticated(null);
  };

  const getCurrentUser = async () => {
    try {
      const response = await API.profile.me();
      const result = response.data.result;
      setIsAuthenticated(true);
      setCurrentUser(result);
      setLoading(false);
      return response;
    } catch (error) {
      setCurrentUser(null);
      setIsAuthenticated(false);
      setLoading(false);
      return null;
    }
  };

  function requestPermission() {
    try {
      if (!token) {
        return;
      }

      getToken(messaging, {
        vapidKey:
          "BKW4S2GV-9ejdC1rjRWocvcFn9yQv1hKiRDjkgOYKiQ5hchAtxmkZBAnbGquwSE-RbEkqp0TNIXqHbX2mk3yMHw",
      })
        .then((token) => {
          const device_id = Utils.getDeviceId();
          const userAgent = window.navigator.userAgent;
          const now = new Date();
          let data = {
            name: userAgent,
            device_id: device_id,
            token: token,
            timezone: now.getTimezoneOffset(),
          };
          API.profile.updateDeviceToken(data).then(
            () => {},
            (error) => {},
          );

          localStorage.setItem("noti_token", token);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  }
  useEffect(() => {
    requestPermission();
    try {
      onMessageListener()
        .then((payload) => {
          if (isForeground) {
            const notificationTitle = payload.data.title;
            toast.info(`${notificationTitle}: ${payload.data.body}`, {
              autoClose: 10000,
              onClick: () => {
                window.location.href = payload.data.url;
              },
            });
          } else {
            const notificationTitle = payload.data.title;
            navigator.serviceWorker.ready.then((registration) => {
              new Notification(notificationTitle, {
                body: payload.data.body,
                icon: "/favicon.png",
                badge: "/favicon.png",
                image: payload.data.image,
                data: {
                  url: "",
                  title: notificationTitle,
                  data: { url: payload.data.url },
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      if (token) {
        await getCurrentUser();
      } else {
        setLoading(false);
      }
    };
    Utils.getDeviceId();
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth_company_id,
        token,
        token_type,
        setCurrentUser,
        currentUser,
        isAuthenticated,
        login,
        logout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
