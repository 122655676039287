import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input } from "antd";
import ModalSignatureCanvas from "../ModalSignatureCanvas";
import { ManifestAPI } from "../../modules/ManifestAPI";
import { toast } from "react-toastify";

const ManifestEditTransportModal = ({ onCancel, onOk, isOpen, model }) => {
  const [loading, setLoading] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setIsApproveModal(true);
  };
  const onApprove = (result) => {
    if (loading) return;
    const formData = form.getFieldsValue();
    const _now = new Date();
    const _timezone = _now.getTimezoneOffset();
    const output = {
      //transport: { ...formData },
      ...formData,
      signature: { ...result },
      timezone: _timezone,
    };

    // call server
    setLoading(true);
    new ManifestAPI().editTransport(output).then(
      (resp) => {
        setLoading(false);
        // fetchAllDataDetailOrder();
        // console.log("resp.data: ", resp.data);
        onOk(resp.data.result);
        toast.success(resp.data.detail);
      },
      (error) => {
        toast.error(error.response.data.detail);
        setLoading(false);
      },
    );
    // .catch((error) => {
    //   console.log("error", error);
    //   toast.error(error.response.data.detail);
    //   setLoading(false);
    // });
    // .finally(() => {
    //   setLoading(false);
    // });
    //--------
  };

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      style={{ top: 5, padding: "0" }}
      onClose={onCancel}
      width="100VW"
      title={`Edit Transport`}
      placement={"right"}
      extra={
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Save
        </Button>
      }
    >
      <section>
        <Form
          name="transport_form"
          initialValues={model}
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item name="manifest_id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            name="company_phone"
            label="Company Phone"
            type="phone"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input type="phone" />
          </Form.Item>
          <Form.Item
            name="vehicle_make"
            label="Vehicle Make"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicle_model"
            label="Vehicle Model"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="license_plate_number"
            label="License Plate #"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="state_on_license_plate"
            label="State on License Plate"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pre_determined_travel_route"
            label="Pre-determined Travel Route"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            {/* <Input /> */}
            <Input.TextArea style={{ height: 120 }} maxLength={500} />
          </Form.Item>
          <Form.Item
            name="details_of_any_existing_circumstances"
            label="Details of any Existing Circumstances"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            {/* <Input /> */}
            <Input.TextArea style={{ height: 120 }} maxLength={500} />
          </Form.Item>
        </Form>
      </section>
      {isApproveModal && (
        <ModalSignatureCanvas
          onCancel={() => {
            setIsApproveModal(false);
          }}
          onOk={(result) => {
            setIsApproveModal(false);
            onApprove(result);
          }}
          title={"Edit Transport Infomation"}
          isOpen={isApproveModal}
          displayInput={true}
          reasonLabel={"Reason for change"}
          //comment={"Edit Transport"}
          comment={""}
        />
      )}
    </Drawer>
  );
};

export default ManifestEditTransportModal;
