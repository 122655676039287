import React, { useState } from "react";
import { Divider, Modal } from "antd";
import axios from "axios";
import ViewPdfModal from "./ViewPdfModal";
import Utils from "../utils/utils";

const ViewListFileModal = ({ modelFile, open, onCancel }) => {
  debugger;
  const [modalViewPdf, setModalViewPdf] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const external_token = localStorage.getItem("external_token");
  const companyIdToken = localStorage.getItem("auth_company_id");
  let media = modelFile.media_info;

  //TODO: view file pdf and download file
  const downloadFileCertificate = async (item) => {
    try {
      setSelectedFile(item);

      const fileCertificate = `${Utils.ApiEndpoint}/media/${companyIdToken}/download_view_file?id=${item.media_id}&token=${external_token}`;
      //}
      const response = await axios.get(fileCertificate, {
        responseType: "blob",
      });

      const blob = new Blob([response.data]);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // Check the file extension
      const fileExtension = item.file_name.split(".").pop().toLowerCase();
      if (fileExtension === "pdf") {
        setModalViewPdf(true);
      } else {
        setModalViewPdf(false);
        // For other file types --> download
        link.download = item.file_name;
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <Modal title="File list" open={open} onCancel={onCancel} footer={null}>
        <Divider></Divider>
        <ol>
          {media && media.length > 0 && (
            <div>
              {media.map((file, fileIndex) => (
                <li key={fileIndex}>
                  <a
                    href="#/"
                    onClick={() => {
                      downloadFileCertificate(file);
                    }}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                  >
                    {file.file_name}
                  </a>
                </li>
              ))}
            </div>
          )}
        </ol>
      </Modal>

      {modalViewPdf && (
        <ViewPdfModal
          open={modalViewPdf}
          onCancel={() => {
            setModalViewPdf(false);
          }}
          media_info={selectedFile}
        />
      )}
    </>
  );
};
export default ViewListFileModal;
