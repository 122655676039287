import { Descriptions } from "antd";
const GenData = (data) =>
  Object.entries(data).map(([key, value], index) => ({
    key: index,
    label: key,
    children: value,
  }));
export default function Description({ data, column = 1 }) {
  const items = GenData(data);
  return <Descriptions column={column} items={items} />;
}
export { GenData };
