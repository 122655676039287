import React from "react";
import { Modal } from "antd";

const ViewPolicyModal = ({ open, onCancel }) => {
  return (
    <Modal
      title="Privacy Policy"
      centered
      open={open}
      onCancel={onCancel}
      width={1200}
      footer={null}
    >
      <embed
        id="ifameviewPdf"
        type="application/pdf"
        title="Privacy Policy"
        src="/static/term_policy/privacy-policy_2024.pdf"
        width="100%"
        height="800px"
      />
    </Modal>
  );
};

export default ViewPolicyModal;
