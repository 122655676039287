import React from "react";
import "leaflet/dist/leaflet.css";
import { Button, Drawer, Row, Space } from "antd";
// import ModalTakePicture from './ModalTakePicture';
import "../invoices.css";
import InvoiceReportComponent from "./InvoiceReportComponent";
const ModalReviewInvoice = ({ onOk, onCancel, isOpen, item }) => {
  return (
    <Drawer
      title=""
      placement={"right"}
      open={isOpen}
      onClose={onCancel}
      maskClosable={false}
      one
      width={"100%"}
      footer={
        item.status < 2 ? (
          <Space style={{ display: "flex", justifyContent: "right" }}>
            <Button
              size={"large"}
              type="primary"
              onClick={() => {
                onOk(item);
              }}
            >
              Edit
            </Button>
          </Space>
        ) : (
          <></>
        )
      }
    >
      <Row align="top" justify={"center"}>
        <InvoiceReportComponent item={item}></InvoiceReportComponent>
      </Row>
    </Drawer>
  );
};

export default ModalReviewInvoice;
