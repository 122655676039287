import React from "react";
import axios, { API } from "../configs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Space,
  Tag,
  Button,
  Flex,
  Modal,
  Form,
  Image,
  Card,
  Descriptions,
  Avatar,
  Row,
  Col,
} from "antd";
import {
  FileDoneOutlined,
  SaveOutlined,
  FileProtectOutlined,
  UserOutlined,
  BankOutlined,
  FileImageOutlined,
  CarOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import HighText from "../components/HL";
import { GenData } from "../components/Description";
import EditMyProfile from "./EditMyProfile";
import Utils from "./../utils/utils";
import dayjs from "dayjs";
import TermOfUseForm from "./TermOfUseForm";
import ModalSignatureCanvas from "../components/ModalSignatureCanvas";
import { toast } from "react-toastify";
import { DestinationAPI } from "../modules/destination/DestinationAPI";
import EditLicenseeDetail from "../components/EditLicenseeDetail";
import AddLicenseeDetail from "../components/AddLicenseeDetail";
import AddVehicleDetail from "../components/AddVehicleDetail";
import EditVehicleDetail from "../components/EditVehicleDetail";
import { useAppStore } from "../utils/appstate";
import CreateSignatureModal from "./CreateSignatureModal";

const MyProfile = () => {
  const navigate = useNavigate();
  const [search] = useState();
  const [companyData, setCompanyData] = React.useState([]);
  const [profileData, setProfileData] = React.useState({});
  const [licenseeData, setLicenseeData] = React.useState([]);
  const [vehicleData, setVehicleData] = React.useState([]);
  const [mySignatureData, setMySignatureData] = React.useState([]);
  // const [aaa, setaaa] = React.useState([null,null,null]);

  const [form] = Form.useForm();
  const [formadd] = Form.useForm();
  const [formterm] = Form.useForm();
  const [formeditprofile] = Form.useForm();
  const [formaddvehicle] = Form.useForm();
  const [formeditvehicle] = Form.useForm();
  const [modal, setModal] = useState({});
  const [modalAdd, setModalAdd] = useState({});
  const [modalEditProfile, setModalEditProfile] = useState({});
  const [modalAddVehicle, setModalAddVehicle] = useState({});
  const [modalEditVehicle, setModalEditVehicle] = useState({});
  const [fileUploadList, setFileUploadList] = useState([]);
  const [modalTermOfUse, setModalTermOfUse] = useState({});
  const [isApproveModal, setIsApproveModal] = useState();

  const [modalCreateSignature, setModalCreateSignature] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingLicense, setLoadingLicense] = useState(false);
  // const [loadingVehicle, setLoadingVehicle] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);

  const appInfo = useAppStore((state) => state.AppInfo);
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const external_token = localStorage.getItem("external_token");
  const companyIdToken = localStorage.getItem("auth_company_id");

  const fetchCompanyData = async () => {
    try {
      setLoadingCompany(true);
      await axios
        .get(`/profile/get-my-company-by-user-id`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setLoadingCompany(false);
          let listCompany = response.data.result.map((item) => ({
            company_id: item.company_id,
            company_name: item.company_name,
            company_type: item.company_type,
            company_phone: item.company_phone,
            primary_contact_name: item.primary_contact_name,
            primary_contact_phone: item.primary_contact_phone,
            email: item.email,
            //created_at: new Date(item.created_at).toLocaleDateString('vi-VN', options),
            created_at: Utils.formatDate(item.created_at),
            CreatedBy: item.CreatedBy,
          }));

          setCompanyData(listCompany);
        });
    } catch (e) {
      console.error(e);
      setLoadingCompany(false);
    }
  };
  const getProfileData = async () => {
    const response = await API.profile.me();
    const { user_info, roles } = response.data.result;
    setProfileData({
      user_info: user_info,
      roles: roles,
    });
  };

  const fetProfileData = async () => {
    try {
      setLoadingProfile(true);
      await axios
        .get(`/profile/get-my-profile-by-user-id`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setLoadingProfile(false);
          const data = response.data.result;
          setProfileData({
            user_info: data.user_info,
            roles: data.roles,
          });
        });
    } catch (e) {
      console.error(e);
      setLoadingProfile(false);
    }
  };

  const StopBehaveAs = async () => {
    if (!appInfo || !appInfo.user || !appInfo.user.by_id) {
      return;
    }
    try {
      setLoading(true);
      const data = {
        userId: appInfo.user.by_id,
        locked: 1,
        email:
          profileData && profileData.user_info
            ? profileData.user_info.email
            : appInfo.user.username,
      };
      await axios
        .post(`/profile/stop-behave-as-user-id`, data, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setLoading(false);
          navigate("/login");
        });
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // get-driver-licensee-for-my-profile
  const fetchDriverLicenseeData = async () => {
    try {
      setLoadingLicense(true);
      await axios
        .get(`/profile/get-driver-licensee-for-my-profile`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setLoadingLicense(false);
          let listLicensee = response.data.result.map((item) => ({
            licensee_id: item.licensee_id,
            company_id: item.company_id,
            company_name: item.company_name,
            licensee_name: item.licensee_name,
            licensee_address: item.licensee_address,
            license_number: item.license_number,
            licensee_phone: item.licensee_phone,
            // issued_date: new Date(item.issued_date).toLocaleDateString('vi-VN', options),
            // expired_date: new Date(item.expired_date).toLocaleDateString('vi-VN', options),
            // created_at: new Date(item.created_at).toLocaleDateString('vi-VN', options),
            issued_date: Utils.formatDate(item.issued_date),
            expired_date: Utils.formatDate(item.expired_date),
            created_at: Utils.formatDate(item.created_at),
            created_by: item.CreatedBy,
            is_deleted: item.is_deleted,
            issue_state: item.issue_state,
            media_info: item.media_info ?? [],
          }));

          setLicenseeData(listLicensee);
        });
    } catch (e) {
      console.error(e);
      setLoadingLicense(false);
    }
  };

  // get vehicle for my profile
  const fetchVehicleData = async (company_id) => {
    try {
      setLoading(true);
      await axios
        .get(`/profile/get-vehicle-for-my-profile`, {
          headers: { Authorization: token },
          params: { company_id: company_id },
        })
        .then((response) => {
          setLoading(false);
          let listVehicle = response.data.result.map((item) => ({
            vehicle_id: item.vehicle_id,
            company_id: item.company_id,
            company_name: item.company_name,
            vehicle_make: item.vehicle_make,
            vehicle_model: item.vehicle_model,
            license_plate: item.license_plate,
            state_license_plate: item.state_license_plate,
            created_at: Utils.formatDate(item.created_at),
            created_by: item.CreatedBy,
            is_deleted: item.is_deleted,
          }));

          setVehicleData(listVehicle);
        });
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // get-my-signature-tempalte-list
  const fetchMySignatureTemplateData = async () => {
    try {
      setLoadingSignature(true);
      await axios
        .get(`/profile/get-my-signature-tempalte-list`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setLoadingSignature(false);
          let listSignature = response.data.result.map((item) => ({
            signature_id: item.signature_id,
            signature_data: item.signature_data,
            reason_for_change: item.reason_for_change,
            created_on: Utils.formatDate(item.created_on),
          }));

          setMySignatureData(listSignature);
        });
    } catch (e) {
      console.error(e);
      setLoadingSignature(false);
    }
  };

  // get data
  React.useEffect(() => {
    fetchCompanyData();
    getProfileData();
    fetchDriverLicenseeData();
    fetchVehicleData(companyIdToken);
    fetchMySignatureTemplateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: profile user-----------------------
  // edit profile user
  const EditProfile = (item) => {
    formeditprofile.setFieldsValue({ ...item, id: item.id });
    setModalEditProfile({
      open: true,
      title: <>Edit My Profile</>,
      okText: "Save",
      type: "edit",
      icon: <SaveOutlined />,
    });
  };

  const onSubmitEditProfile = async (formData) => {
    // const fileData = await uploadPrfile(formData.image_file.file.originFileObj)
    let media_info = null;
    if (formData.media_info && formData.media_info !== "") {
      media_info = JSON.parse(formData.media_info);
    }
    const dataUpdate = {
      email: "",
      name: formData.name,
      password: "",
      phone_number: formData.phone_number,
      confirmpass: "",
      company_id: companyIdToken,
      address: formData.address,
      // profile_image_path: fileData.data.result,
      avatar_id: formData.avatar_id,
      media_info: media_info,
      is_complete_profile: false,
      roleid: [],
    };

    //user_id = formData.id
    //let url = `/profile/${companyIdToken}/avatar/${formData.id}/${dataUpdate.profile_image_path}?token=${auth_token}`;

    setLoadingProfile(true);
    await axios
      .post(`/profile/edit-my-profile-by-user-id`, dataUpdate, {
        headers: { Authorization: token },
      })
      .then(() => {
        setLoadingProfile(false);
        navigate("/myaccount/my-profile");
        setModalEditProfile({
          open: false,
          title: <>Edit My Profile</>,
          okText: "Save",
          type: "edit",
          icon: <SaveOutlined />,
        });
        fetProfileData();
        // getProfileData();
        toast.success("Success");
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);
        setLoadingProfile(false);
      });
  };
  //-----end profile user--------------------

  // TODO: license for driver
  const AddAction = () => {
    formadd.resetFields();
    formadd.setFieldsValue({ media_info: [], method: "get" });
    setModalAdd({
      open: true,
      title: <>Add Driver License</>,
      okText: "Save",
      type: "add",
      icon: <SaveOutlined />,
    });
  };

  const dateFormat = Utils.formatDateFormView; // 'MM/DD/YYYY';
  // edit driver license
  const EditAction = (item) => {
    const issued_date = dayjs(item.issued_date);
    const expired_date = dayjs(item.expired_date);
    form.setFieldsValue({
      ...item,
      licensee_id: item.licensee_id,
      issued_date: issued_date,
      expired_date: expired_date,
      issue_state: item.issue_state,
      licensee_type: "Driver",
    });
    if (item.media_info) {
      setFileUploadList(item.media_info);
    }
    setModal({
      open: true,
      title: <>Edit Driver License</>,
      okText: "Save",
      type: "edit",
      icon: <SaveOutlined />,
    });
  };
  //------------

  const onSubmitHandlerAddNew = async (formData, newFileList) => {
    formData.issued_date = formData.issued_date.hour(12);
    formData.expired_date = formData.expired_date.hour(12);
    const dataAdd = {
      licensee_id: "",
      company_id: companyData[0].company_id,
      license_number: formData.license_number,
      licensee_name: formData.licensee_name,
      licensee_address: formData.licensee_address,
      licensee_phone: formData.licensee_phone,
      issued_date: formData.issued_date.toISOString(),
      expired_date: formData.expired_date.toISOString(),
      user_id: "",
      licensee_type: "Driver",
      issue_state: formData.issue_state,
      //last_run: new Date(),
      //is_active: formData.is_active == undefined ? 1 : formData.is_active,
      created_by: "",
      is_deleted: false,
      media_info: newFileList,
    };
    setLoadingLicense(true);
    if (loadingLicense) {
      return;
    }
    await axios
      .post(`/profile/add-driver-licensee-for-my-profile`, dataAdd, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setLoadingLicense(false);
        navigate("/myaccount/my-profile");

        toast.success(response.data.detail);

        setModalAdd({
          open: false,
          title: <>Add Driver License</>,
          okText: "Save",
          type: "add",
          icon: <SaveOutlined />,
        });
        fetchDriverLicenseeData();
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.detail);
        setLoadingLicense(false);
      });
  };

  const onSubmitEditLicenseeDetail = async (formData, newFileList) => {
    formData.issued_date = formData.issued_date.hour(12);
    formData.expired_date = formData.expired_date.hour(12);
    const dataUpdate = {
      licensee_id: formData.licensee_id,
      company_id: "",
      license_number: formData.license_number,
      licensee_name: formData.licensee_name,
      licensee_address: formData.licensee_address,
      licensee_phone: formData.licensee_phone,
      issued_date: formData.issued_date.toISOString(),
      expired_date: formData.expired_date.toISOString(),
      issue_state: formData.issue_state,
      user_id: "",
      licensee_type: "Driver",
      created_by: "",
      is_deleted: false,
      media_info: newFileList,
    };
    setFileUploadList(newFileList);
    setLoadingLicense(true);
    if (loadingLicense) {
      return;
    }
    await axios
      .post(`/profile/edit-driver-licensee-for-my-profile`, dataUpdate, {
        headers: { Authorization: token },
      })
      .then(() => {
        setLoadingLicense(false);
        navigate("/myaccount/my-profile");

        setModal({
          open: false,
          title: <>Edit Driver License</>,
          okText: "Save",
          type: "edit",
          icon: <SaveOutlined />,
        });

        fetchDriverLicenseeData();

        toast.success("Success");
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
        setLoadingLicense(false);
      });
  };

  // remove driver license
  const RemoveAction = (item) => {
    const dataUpdate = {
      licensee_id: item.licensee_id,
      company_id: "",
      license_number: item.license_number,
      licensee_name: item.licensee_name,
      licensee_address: item.licensee_address,
      licensee_phone: item.licensee_phone,
      issued_date: new Date(),
      expired_date: new Date(),
      user_id: "",
      licensee_type: "Driver",
      created_by: "",
      is_deleted: true,
    };
    const handleDelete = async () => {
      setLoadingLicense(true);
      await axios
        .post(`/profile/remove-driver-licensee-for-my-profile`, dataUpdate, {
          headers: { Authorization: token },
        })
        .then(() => {
          setLoadingLicense(false);
          navigate("/myaccount/my-profile");

          fetchDriverLicenseeData();

          toast.success("Success");
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          setLoadingLicense(false);
        });
    };

    Modal.confirm({
      title: "Are you sure you want to delete this driver license?",
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: handleDelete,
    });
  };

  //-------end license---------------------------------------

  // TODO: vehicle --------------------------------------
  const AddVehicleAction = () => {
    formaddvehicle.resetFields();
    //formadd.setFieldsValue({ is_active: 1, method: 'get' })
    setModalAddVehicle({
      open: true,
      title: <>Add Vehicle</>,
      okText: "Save",
      type: "add",
      icon: <SaveOutlined />,
    });
  };

  const EditVehicleAction = (item) => {
    formeditvehicle.setFieldsValue({
      ...item,
      licensee_id: item.licensee_id,
    });
    setModalEditVehicle({
      open: true,
      title: <>Edit Vehicle</>,
      okText: "Save",
      type: "edit",
      icon: <SaveOutlined />,
    });
  };

  const onSubmitAddVehicle = async (formData) => {
    const dataAdd = {
      vehicle_id: "",
      company_id: companyIdToken,
      vehicle_make: formData.vehicle_make,
      vehicle_model: formData.vehicle_model,
      license_plate: formData.license_plate,
      state_license_plate: formData.state_license_plate,
      created_by: "",
      owner_user_id: "",
      is_deleted: false,
    };
    setLoading(true);
    await axios
      .post(`/profile/add-vehicle-for-company-id`, dataAdd, {
        headers: { Authorization: token },
        params: { company_id: companyIdToken },
      })
      .then((response) => {
        setLoading(false);
        navigate("/myaccount/my-profile");
        toast.success(response.data.detail);

        setModalAddVehicle({
          open: false,
          title: <>Add Vehicle</>,
          okText: "Save",
          type: "add",
          icon: <SaveOutlined />,
        });
        fetchVehicleData(companyIdToken);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.detail);
        setLoading(false);
      });
  };

  const onSubmitEditVehicleDetail = async (formData) => {
    const dataUpdate = {
      vehicle_id: formData.vehicle_id,
      company_id: companyIdToken,
      vehicle_make: formData.vehicle_make,
      vehicle_model: formData.vehicle_model,
      license_plate: formData.license_plate,
      state_license_plate: formData.state_license_plate,
      created_by: "",
      owner_user_id: "",
      is_deleted: false,
    };
    setLoading(true);

    await axios
      .post(`/profile/edit-vehicle-for-company`, dataUpdate, {
        headers: { Authorization: token },
      })
      .then(() => {
        setLoading(false);
        navigate("/myaccount/my-profile");

        setModalEditVehicle({
          open: false,
          title: <>Edit Vehicle</>,
          okText: "Save",
          type: "edit",
          icon: <SaveOutlined />,
        });

        fetchVehicleData(companyIdToken);
        toast.success("Success");
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
        setLoading(false);
      });
  };

  const RemoveVehicleAction = (item) => {
    const dataUpdate = {
      vehicle_id: item.vehicle_id,
      company_id: companyIdToken,
      vehicle_make: item.vehicle_make,
      vehicle_model: item.vehicle_model,
      license_plate: item.license_plate,
      state_license_plate: item.state_license_plate,
      created_by: "",
      is_deleted: true,
    };

    const handleDelete = async () => {
      setLoading(true);
      await axios
        .post(`/profile/remove-vehicle-for-my-profile`, dataUpdate, {
          headers: { Authorization: token },
        })
        .then(() => {
          setLoading(false);
          navigate("/myaccount/my-profile");

          fetchVehicleData(companyIdToken);

          toast.success("Success");
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          setLoading(false);
        });
    };

    Modal.confirm({
      title: "Are you sure you want to delete this vehicle?",
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: handleDelete,
    });
  };

  //----end vehicle-----------------------------------------

  // complete my profile CompleteProfileAction
  const AcceptTermOfUseAction = async () => {
    setIsApproveModal(true);
    setModalTermOfUse({
      open: false,
      title: <>Term Of Use</>,
      okText: "Accept",
      type: "edit",
      icon: <SaveOutlined />,
    });
  };

  const OpenTermsOfUseForm = () => {
    formterm.resetFields();
    //formadd.setFieldsValue({ is_active: 1, method: 'get' })
    setModalTermOfUse({
      open: true,
      title: <>Privacy and Terms</>,
      okText: "Accept",
      type: "add",
      icon: <SaveOutlined />,
    });
  };

  const onApprove = (result) => {
    // if (isLoading)
    //   return;

    const data = {
      destination_order_id: "",
      position_signature: 1,
      signature: { ...result },
    };

    setLoading(true);
    new DestinationAPI()
      .profileCompleteSignature(data)
      .then(
        (resp) => {
          setLoading(false);
          navigate("/home");
          toast.success(resp.data.detail);
        },
        () => {
          setLoading(false);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  // TODO: my signature template-----------------------
  const CreateSignature = () => {
    setModalCreateSignature(true);
  };

  //-----end my signature template--------------------

  const getImageLicenseByid = (item) => {
    let imageLicense = "";
    if (
      item &&
      item.media_info &&
      item.media_info.length > 0 &&
      item.media_info[0].media_id
    ) {
      imageLicense = `${Utils.ApiEndpoint}/media/${companyIdToken}/read?fname=${item.media_info[0].file_name}&id=${item.media_info[0].media_id}&token=${external_token}`;
    }

    return imageLicense;
  };

  const getImageAvatar = (item) => {
    const url = `${Utils.ApiEndpoint}/media/${companyIdToken}/avatar/${item.id}?token=${external_token}&aid=${item.avatar_id}`;
    return url;
  };

  const getImageAvatarPreview = (item) => {
    const url = `${Utils.ApiEndpoint}/media/${companyIdToken}/read?id=${item.avatar_id}&token=${external_token}&aid=${item.avatar_id}`;
    return url;
  };

  // TODO: get signature image
  const getSignatureByid = (signature_id) => {
    const urlSign1 = `${Utils.ApiEndpoint}/signature/${companyIdToken}/read?id=${signature_id}&token=${external_token}`;
    return urlSign1;
  };

  // TODO: my signature template
  const addMySignature = async (result) => {
    const data = result;
    setLoadingSignature(true);
    await axios
      .post(`/profile/add-my-signature-image-template`, data, {
        headers: { Authorization: token },
      })
      .then(() => {
        setLoadingSignature(false);
        navigate("/myaccount/my-profile");

        fetchMySignatureTemplateData();
        toast.success("Success");
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
        setLoadingSignature(false);
      });
  };

  // remove my signature template

  const RemoveSignature = async (item) => {
    const data = {
      signature_id: item,
      company_id: companyIdToken,
    };
    const handleDelete = async () => {
      setLoadingSignature(true);
      await axios
        .post(`/profile/remove-my-signature-image-template`, data, {
          headers: { Authorization: token },
        })
        .then(() => {
          setLoadingSignature(false);
          navigate("/myaccount/my-profile");

          fetchMySignatureTemplateData();

          toast.success("Success");
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          setLoadingSignature(false);
        });
    };

    Modal.confirm({
      title: "Are you sure you want to delete this signature?",
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: handleDelete,
    });
  };

  //---------end my signature template

  return (
    <>
      <Flex vertical style={{ margin: "0 auto" }}>
        {/* <Spin tip="Loading" size="large"> </Spin> */}
        {profileData &&
          profileData.user_info &&
          profileData.user_info.is_complete_profile === false && (
            <Flex
              style={{ padding: "0", marginTop: "10px" }}
              justify="end"
              wrap="wrap"
              align="center"
            >
              <Button
                type="primary"
                size="large"
                style={{ marginBottom: 16 }}
                onClick={() => OpenTermsOfUseForm()}
                icon={<FileDoneOutlined />}
              >
                Accept terms of use
              </Button>
            </Flex>
          )}

        {appInfo.user && appInfo.user.by_id && (
          <Flex
            style={{ padding: "0", marginTop: "10px" }}
            justify="end"
            wrap="wrap"
            align="center"
          >
            <Button
              type="primary"
              size="large"
              style={{ marginBottom: 16 }}
              onClick={() => StopBehaveAs()}
              icon={<FileDoneOutlined />}
            >
              Stop Behave As
            </Button>
          </Flex>
        )}

        {/* start profile infor */}
        <Card
          loading={loadingProfile}
          title={
            <span>
              <UserOutlined style={{ marginRight: "8px" }} />
              Profile
            </span>
          }
          bordered={false}
          style={{
            width: "100%",
          }}
          extra={
            <a href="#/" onClick={() => EditProfile(profileData.user_info)}>
              Edit
            </a>
          }
        >
          {profileData && profileData.user_info ? (
            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                sm={24}
                md={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ height: 100, width: 100 }}>
                  {profileData.user_info?.avatar_id ? (
                    <Avatar
                      size={100}
                      src={
                        <Image
                          src={getImageAvatar(profileData.user_info)}
                          alt={profileData.user_info.name}
                          preview={{
                            src: getImageAvatarPreview(profileData.user_info),
                          }}
                        />
                      }
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#cfcfcf",
                      }}
                      size={100}
                      icon={<UserOutlined style={{ color: "#000" }} />}
                    />
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={21}>
                <Card.Meta
                  description={
                    <Descriptions
                      column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 4,
                        xxl: 4,
                      }}
                      items={GenData({
                        Name: (
                          <HighText search={search}>
                            {profileData.user_info.name}
                          </HighText>
                        ),
                        Email: (
                          <HighText search={search}>
                            {profileData.user_info.email}
                          </HighText>
                        ),
                        Phone: (
                          <HighText search={search}>
                            {profileData.user_info.phone_number}
                          </HighText>
                        ),
                        Address: (
                          <HighText search={search}>
                            {profileData.user_info.address}
                          </HighText>
                        ),
                        Role: profileData.roles.map((x) => (
                          <Tag key={x}>
                            <HighText search={search}>{x}</HighText>
                          </Tag>
                        )),
                      })}
                    />
                  }
                />

                {profileData.user_info.term_signature_id &&
                  profileData.user_info.term_signature_id !== "None" && (
                    <Flex gap={10}>
                      {/* <Flex justify='center' vertical>{profileData.user_info.signature_name}</Flex> */}
                      {/* TODO: image */}
                      <Flex>
                        <span
                          style={{
                            color: "#00000073",
                          }}
                        >
                          Terms Accepted:
                        </span>{" "}
                      </Flex>
                      <Flex
                        style={{
                          height: 50,
                          //background: "#ccc",
                        }}
                        justify="center"
                      >
                        <Image
                          height={50}
                          src={getSignatureByid(
                            profileData.user_info.term_signature_id,
                          )}
                        />
                      </Flex>
                      {/* TODO: signature date */}
                      <Flex justify="center" vertical>
                        {Utils.formatDate(profileData.user_info.signature_date)}
                      </Flex>
                    </Flex>
                  )}
              </Col>
            </Row>
          ) : (
            <p>Loading profile data...</p>
          )}
        </Card>

        {/* start my signature */}
        <Card
          loading={loadingSignature}
          title={
            <span>
              <HighlightOutlined style={{ marginRight: "8px" }} />
              My Signature
            </span>
          }
          bordered={false}
          style={{
            width: "100%",
            marginTop: 16,
          }}
          extra={
            mySignatureData &&
            mySignatureData.length < 3 && (
              <div>
                <a href="#/" onClick={() => CreateSignature()}>
                  Create
                </a>
              </div>
            )
          }
        >
          <Row gutter={16}>
            {mySignatureData && mySignatureData.length > 0 ? (
              mySignatureData.map((item, index) => (
                <Col span={8} xs={24} sm={8} key={index}>
                  <Card
                    title={`Signature ${index + 1}`}
                    type="inner"
                    bordered={false}
                    extra={
                      <Space>
                        <a
                          href="#/"
                          onClick={() => RemoveSignature(item.signature_id)}
                        >
                          Remove
                        </a>
                      </Space>
                    }
                    style={{ height: 200 }}
                  >
                    {item && item.signature_id && (
                      <Flex
                        style={{
                          height: 50,
                          //background: "#ccc",
                        }}
                        justify="center"
                      >
                        <Image
                          height={50}
                          src={getSignatureByid(item.signature_id)}
                        />
                      </Flex>
                    )}
                  </Card>
                </Col>
              ))
            ) : (
              <p>No signature tempalte data</p>
            )}
          </Row>
        </Card>

        {/* start vehicle infor */}
        <Card
          loading={loading}
          title={
            <span>
              <CarOutlined style={{ marginRight: "8px" }} />
              Vehicle
            </span>
          }
          style={{ marginTop: 16 }}
          extra={
            <a href="#/" onClick={() => AddVehicleAction()}>
              Add new
            </a>
          }
        >
          {vehicleData && vehicleData.length > 0 ? (
            vehicleData.map((item, index) => (
              <Card
                key={index}
                type="inner"
                title={item.vehicle_make}
                bordered={false}
                style={{
                  width: "100%",
                  marginBottom: 16,
                }}
                extra={
                  <Space>
                    <a href="#/" onClick={() => EditVehicleAction(item)}>
                      Edit
                    </a>
                    ,
                    <a href="#/" onClick={() => RemoveVehicleAction(item)}>
                      Remove
                    </a>
                  </Space>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24}>
                    <Card.Meta
                      description={
                        <Descriptions
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 4,
                            xxl: 4,
                          }}
                          items={GenData({
                            Company: (
                              <HighText search={search}>
                                {item.company_name}
                              </HighText>
                            ),
                            "Vehicle model": (
                              <HighText search={search}>
                                {item.vehicle_model}
                              </HighText>
                            ),
                            "License plate": (
                              <HighText search={search}>
                                {item.license_plate}
                              </HighText>
                            ),
                            "State license plate": (
                              <HighText search={search}>
                                {item.state_license_plate}
                              </HighText>
                            ),
                            "Created on": (
                              <HighText search={search}>
                                {item.created_at}
                              </HighText>
                            ),
                            "Created by": (
                              <HighText search={search}>
                                {item.created_by}
                              </HighText>
                            ),
                          })}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Card>
            ))
          ) : (
            <p>No driver license data</p>
          )}
        </Card>
        {/* start licensee infor */}
        <Card
          loading={loadingLicense}
          title={
            <span>
              <FileProtectOutlined style={{ marginRight: "8px" }} />
              Driver License
            </span>
          }
          style={{ marginTop: 16 }}
          extra={
            <a href="#/" onClick={() => AddAction()}>
              Add new
            </a>
          }
        >
          {licenseeData && licenseeData.length > 0 ? (
            licenseeData.map((item, index) => (
              <Card
                key={index}
                type="inner"
                title={item.licensee_name}
                bordered={false}
                style={{
                  width: "100%",
                  marginBottom: 16,
                }}
                extra={
                  <Space>
                    <a href="#/" onClick={() => EditAction(item)}>
                      Edit
                    </a>
                    ,
                    <a href="#/" onClick={() => RemoveAction(item)}>
                      Remove
                    </a>
                  </Space>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ height: 100, width: 100 }}>
                      {item.media_info && item.media_info.length > 0 ? (
                        <Avatar
                          size={100}
                          src={
                            <Image
                              src={getImageLicenseByid(item)}
                              alt={item.licensee_name}
                            />
                          }
                        />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: "#cfcfcf",
                          }}
                          size={100}
                          icon={
                            <FileImageOutlined
                              style={{
                                color: "#000",
                              }}
                            />
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={21}>
                    <Card.Meta
                      description={
                        <Descriptions
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 4,
                            xxl: 4,
                          }}
                          items={GenData({
                            "License Number": (
                              <HighText search={search}>
                                {item.license_number}
                              </HighText>
                            ),
                            "Licensee Name": (
                              <HighText search={search}>
                                {item.licensee_name}
                              </HighText>
                            ),
                            "State Issued": (
                              <HighText search={search}>
                                {item.issue_state}
                              </HighText>
                            ),
                            "Licensee Address": (
                              <HighText search={search}>
                                {item.licensee_address}
                              </HighText>
                            ),
                            "Licensee Phone": (
                              <HighText search={search}>
                                {item.licensee_phone}
                              </HighText>
                            ),
                            "Issued Date": (
                              <HighText search={search}>
                                {item.issued_date}
                              </HighText>
                            ),
                            "Expired Date": (
                              <HighText search={search}>
                                {item.expired_date}
                              </HighText>
                            ),
                            "Created On": (
                              <HighText search={search}>
                                {item.created_at}
                              </HighText>
                            ),
                          })}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Card>
            ))
          ) : (
            <p>No driver license data</p>
          )}
        </Card>

        {/* start company infor */}
        <Card
          loading={loadingCompany}
          title={
            <span>
              <BankOutlined style={{ marginRight: "8px" }} />
              Company
            </span>
          }
          bordered={false}
          style={{
            width: "100%",
            marginTop: 16,
          }}
          // extra={<a href="#/">Edit</a>}
        >
          {companyData && companyData.length > 0 ? (
            <Flex gap={16} justify="space-between">
              <Card.Meta
                title={
                  <>
                    <HighText search={search}>
                      {companyData[0].company_name}
                    </HighText>
                  </>
                }
                description={
                  <Descriptions
                    column={{
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 4,
                      xxl: 4,
                    }}
                    items={GenData({
                      // "Company type": (
                      //   <HighText search={search}>
                      //     {companyData[0].company_type}
                      //   </HighText>
                      // ),
                      Phone: (
                        <HighText search={search}>
                          {companyData[0].company_phone}
                        </HighText>
                      ),
                      "Primary contact name": (
                        <HighText search={search}>
                          {companyData[0].primary_contact_name}
                        </HighText>
                      ),
                      "Primary contact phone": (
                        <HighText search={search}>
                          {companyData[0].primary_contact_phone}
                        </HighText>
                      ),
                      Email: (
                        <HighText search={search}>
                          {companyData[0].email}
                        </HighText>
                      ),
                    })}
                  />
                }
              ></Card.Meta>
            </Flex>
          ) : (
            <p>No company data</p>
          )}
        </Card>
      </Flex>
      {modalAdd && modalAdd.open === true && (
        <AddLicenseeDetail
          form={formadd}
          modal={[modalAdd, setModalAdd]}
          formAction={onSubmitHandlerAddNew}
          fileAction={[fileUploadList, setFileUploadList]}
          loading={loading}
          type="Driver"
        />
      )}
      {modal && modal.open === true && (
        <EditLicenseeDetail
          form={form}
          modal={[modal, setModal]}
          formAction={onSubmitEditLicenseeDetail}
          fileAction={[fileUploadList, setFileUploadList]}
          loading={loading}
          type="Driver"
        />
      )}
      {modalEditProfile && modalEditProfile.open === true && (
        <EditMyProfile
          form={formeditprofile}
          modal={[modalEditProfile, setModalEditProfile]}
          formAction={onSubmitEditProfile}
          userInfo={profileData.user_info}
          loading={loading}
        />
      )}
      <TermOfUseForm
        form={formterm}
        modal={[modalTermOfUse, setModalTermOfUse]}
        formAction={AcceptTermOfUseAction}
        loading={loading}
      />

      {isApproveModal && (
        <ModalSignatureCanvas
          onCancel={() => {
            setIsApproveModal(false);
          }}
          onOk={(result) => {
            setIsApproveModal(false);
            onApprove(result);
          }}
          title={"Accept Term Of Use"}
          isOpen={isApproveModal}
          displayInput={true}
          comment={"Accept"}
        />
      )}

      {modalAddVehicle && modalAddVehicle.open === true && (
        <AddVehicleDetail
          form={formaddvehicle}
          modal={[modalAddVehicle, setModalAddVehicle]}
          formAction={onSubmitAddVehicle}
          loading={loading}
        />
      )}
      {modalEditVehicle && modalEditVehicle.open === true && (
        <EditVehicleDetail
          form={formeditvehicle}
          modal={[modalEditVehicle, setModalEditVehicle]}
          formAction={onSubmitEditVehicleDetail}
          loading={loading}
        />
      )}

      {modalCreateSignature && (
        <CreateSignatureModal
          open={() => setModalCreateSignature(true)}
          onCancel={() => setModalCreateSignature(false)}
          onOk={(result) => {
            setModalCreateSignature(false);
            addMySignature(result);
          }}
        />
      )}
    </>
  );
};

export default MyProfile;
