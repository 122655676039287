import React from "react";
import { Form, Input, Modal } from "antd";

export default function AddVehicleDetail({
  modal: [item, setModalAddVehicle],
  formAction,
  form,
  loading,
}) {
  return (
    <Modal
      style={{ top: 10 }}
      {...item}
      maskClosable={false}
      onCancel={() => setModalAddVehicle({})}
      forceRender
      onOk={() => form.submit()}
      okButtonProps={{ loading: loading, icon: item.icon }}
    >
      <Form
        form={form}
        onFinish={formAction}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="vehicle_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="vehicle_make"
          label="Vehicle Make"
          required
          rules={[{ required: true, message: "Vehicle Make is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="vehicle_model"
          label="Vehicle Model"
          required
          rules={[{ required: true, message: "Vehicle Model is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="license_plate"
          label="License Plate"
          required
          rules={[{ required: true, message: "License Plate is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="state_license_plate"
          label="State License Plate"
          required
          rules={[
            { required: true, message: "State License Plate is required" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
