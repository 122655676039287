import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../configs";
import "./register.css";
import { Button, Result, Spin } from "antd";

export default function ChangeEmailConfirmation() {
  const navigate = useNavigate();

  const onSubmitEmailChange = async (token) => {
    debugger;
    const updatedFormData = {
      email: "change.email@aclassia.com",
      token: token,
    };
    setIsLoading(true);

    await axios
      .post(`/auth/confirm-change-email`, updatedFormData, {})
      .then((response) => {
        setStatus({
          status: "success",
          title: "Your email has been changed. ",
          message: "You can now log in using your new email address.",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setStatus({
          status: "error",
          title: "Unable to update email address.",
          message:
            "This request has been processed or There was a problem with your request. Please contact support for assistance.",
        });
        setIsLoading(false);
      });
  };

  const authenticate = () => {
    var authToken = (window.location.search.match(/token=([^&]+)/) || [])[1];
    if (authToken) {
      onSubmitEmailChange(authToken);
    } else {
      setStatus({
        status: "error",
        title: "Unable to update email address.",
        message:
          "This request has been processed or There was a problem with your request. Please contact support for assistance.",
      });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [status, setStatus] = useState({
    status: "success",
    title: "",
    message: "",
  }); //"error"
  const [isLoading, setIsLoading] = useState(true); //"error"

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Spin />{" "}
        </div>
      ) : (
        <Result
          status={status.status}
          title={status.title}
          subTitle={status.message}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                navigate("/login");
              }}
            >
              Go Sign in
            </Button>,
          ]}
        />
      )}
    </>
  );
}
