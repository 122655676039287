import axios from "../../configs";

export class ChatAPI {
  constructor() {
    this.companyId = localStorage.getItem("auth_company_id");
    if (!this.companyId) {
      this.companyId = "common";
    }
    this.token = localStorage.getItem("auth_token");
    this.auth_token_type = localStorage.getItem("auth_token_type");
    this.headers = {
      Authorization: `Bearer ${this.token}`,
      "X-Company-Id": this.companyId,
    };
  }

  async getGroupChat(data) {
    return await axios.get(`/chat/get-groups`, {
      headers: this.headers,
      params: data != null ? { ...data } : {},
    });
  }

  async getGroupChatByManifestId(data) {
    return await axios.get(`/chat/get-group-chat-by-manifest-id`, {
      headers: this.headers,
      params: data != null ? { ...data } : {},
    });
  }

  async getGroupNumber(data) {
    return await axios.get(`/chat/get-group-number`, {
      headers: this.headers,
      params: data != null ? { ...data } : {},
    });
  }

  async getChatDetail(manifest_id) {
    return await axios.get(`/chat/get-chat-detail`, {
      headers: this.headers,
      params: { manifest_id: manifest_id },
    });
  }

  async getAllChats() {
    return await axios.get(`/chat/get-all`, {
      headers: this.headers,
    });
  }

  async addChat(data) {
    return await axios.post(`/chat/add`, data, {
      headers: this.headers,
    });
  }

  async updateChat(data) {
    return await axios.post(`/chat/update`, data, {
      headers: this.headers,
    });
  }

  async removeChat(data) {
    return await axios.post(`/chat/remove`, data, {
      headers: this.headers,
    });
  }

  async removeGroupChat(data) {
    return await axios.post(`/chat/remove-group`, data, {
      headers: this.headers,
    });
  }
}
