import Highlighter from "react-highlight-words";

export default function HighText({ search, children }) {
  if (!children) {
    children = "";
  }
  return (
    <Highlighter
      searchWords={[search]}
      highlightClassName="hightlight"
      textToHighlight={children}
    />
  );
}
