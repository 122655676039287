import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  List,
  Input,
  Button,
  Avatar,
  Alert,
  Space,
  Card,
  Tabs,
  Flex,
  Spin,
} from "antd";
import { ChatAPI } from "../ChatAPI";
import { toast } from "react-toastify";
import Utils from "../../../utils/utils";
import { useAppStore } from "../../../utils/appstate";
import ManifestViewModal from "../../../components/manifest_management/ManifestViewModal";
import Description from "../../../components/Description";
import AppStatus from "../../../utils/app_status";
const { Content, Sider } = Layout;
const { Search } = Input;
const { Meta } = Card;
const { TabPane } = Tabs;

const ChatManagement = () => {
  const external_token = localStorage.getItem("external_token");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const [groupChat, setGroupChat] = useState();
  const [groupNumber, setGroupNumber] = useState(0);
  const [isViewManifestModal, setIsViewManifestModal] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeSelect, setActiveSelect] = useState("ongoing");

  const appInfo = useAppStore((state) => state.AppInfo);

  const ws = useRef();
  const scrollRef = useRef(null);
  const warningMessage =
    "Kindly review before sending. These messages are included in the manifest record.";

  const query = new URLSearchParams(window.location.search);
  const manifestId = query.get("id");

  const chatAPIs = new ChatAPI();

  const dataInfo = {
    "System Name": "Transport by aClassiA",
    Version: "1.0.0.1000",
    "Release Date": "08/08/2024",
    "Troubleshoot Information": "thuan@aclassia.com",
  };

  const getOrderStatus = (order) => {
    if (order.status === AppStatus.ORDER_COMPLETED) {
      return `${order.order_number} - Completed`;
    } else if (order.status === AppStatus.RECEIVER_REJECTED) {
      return `${order.order_number} - Rejected`;
    } else {
      return order.order_number;
    }
  };

  const getTextColorOrder = (status) => {
    if (status === AppStatus.ORDER_COMPLETED) {
      return "green";
    } else if (status === AppStatus.RECEIVER_REJECTED) {
      return "red";
    } else {
      return "";
    }
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSearch = (value) => {
    setTextSearch(value);
  };

  const handleSearchClick = () => {
    if (!loading) {
      getOrderGroupChat();
    }
  };

  const handleSearchEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  const handleViewManifest = () => {
    setIsViewManifestModal(true);
  };

  const handleOrderSelect = async (order) => {
    setSelectedOrder(order);
    setSelectedOrderId(order?.manifest_id);
    getConversation(order?.manifest_id);
    // let conversations = await chatAPIs.getChatDetail(order?.manifest_id);
    // if (order?.conversation?.length > 0) {
    //   const chats = order?.conversation.map((item) => {
    //     const created_date = Utils.formatDate(item.created_at);
    //     const created_time = Utils.formatTime(item.created_at);
    //     if (item?.avatar_id) {
    //       return {
    //         ...item,
    //         avatar_url: `${Utils.ApiEndpoint}/media/${item.company_id}/avatar/${item.user_id}?token=${external_token}`,
    //         created_date: created_date,
    //         created_time: created_time,
    //       };
    //     } else {
    //       return {
    //         ...item,
    //         created_date: created_date,
    //         created_time: created_time,
    //       };
    //     }
    //   });
    //   setChatHistory(chats);
    // }
  };

  const getConversation = async (manifestId) => {
    if (manifestId) {
      chatAPIs.getChatDetail(manifestId).then((res) => {
        const chats = res.data.result.map((item) => {
          const created_date = Utils.formatDate(item.created_at);
          const created_time = Utils.formatTime(item.created_at);
          if (item?.avatar_id) {
            return {
              ...item,
              avatar_url: `${Utils.ApiEndpoint}/media/${item.company_id}/avatar/${item.user_id}?token=${external_token}`,
              created_date: created_date,
              created_time: created_time,
            };
          } else {
            return {
              ...item,
              created_date: created_date,
              created_time: created_time,
            };
          }
        });

        const idx = groupChat?.findIndex((x) => x.manifest_id === manifestId);
        if (idx !== -1) {
          groupChat[idx].conversation = res.data.result;
        }

        if (selectedOrder.manifest_id === manifestId) {
          setChatHistory(chats);
        }
      });
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (message === "" || loading) {
      return;
    }
    setLoading(true);

    const data = {
      manifest_id: selectedOrder.manifest_id,
      order_number: selectedOrder.order_number,
      message: message,
      media_ids: "",
    };
    setMessage("");
    chatAPIs
      .addChat(data)
      .then(() => {
        setLoading(false);
        setMessage("");
      })
      .catch(() => {
        setLoading(false);
        setMessage("");
      });
  };

  const getOrderGroupChat = () => {
    setLoading(true);
    const param = {
      search: textSearch,
      status: activeSelect,
    };
    chatAPIs
      .getGroupChat(param)
      .then((response) => {
        setLoading(false);
        setGroupChat(response.data.result);

        if (response.data.result.length > 0) {
          if (manifestId) {
            if (!selectedOrder) {
              const order = response.data.result.find(
                (x) => x.manifest_id === manifestId,
              );
              setSelectedOrder(order);
            }
          } else {
            const lastOrder = response.data.result[0];
            setSelectedOrder(lastOrder);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.detail);
      });
  };

  const getGroupNumber = () => {
    setLoading(true);
    const param = {};
    chatAPIs
      .getGroupNumber(param)
      .then((response) => {
        setLoading(false);
        setGroupNumber(response.data.result.count);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.detail);
      });
  };

  const connnectChat = () => {
    if (!appInfo.user) {
      return;
    }
    const email = appInfo.user.username;
    let endpoint = `${Utils.SocketEndpoint}/chat/order?email=${encodeURIComponent(email)}`;
    // console.log("endpoint ", endpoint);
    ws.current = new WebSocket(endpoint);

    ws.current.onopen = () => {
      console.log("#Connected to WebSocket");
    };

    ws.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      // console.log("data ", data);
      // console.log("selectedOrder ", selectedOrder);
      if (data.manifest_id) {
        getConversation(data.manifest_id);
      }
    };

    ws.current.onclose = () => {
      console.log("## onclose ");
      // setTimeout(() => {
      //   if (appInfo.user) {
      //     const email = appInfo.user.username;
      //     let endpoint = `${Utils.SocketEndpoint}/chat/order?email=${encodeURIComponent(email)}`;
      //     ws.current = new WebSocket(endpoint);
      //   }
      // }, 100);
    };
  };

  const fetchData = async () => {
    getGroupNumber();
    if (manifestId) {
      const param = {
        manifest_id: manifestId,
      };
      const result = await chatAPIs.getGroupChatByManifestId(param);
      if (
        result?.data?.result?.status === AppStatus.ORDER_COMPLETED ||
        result?.data?.result?.status === AppStatus.RECEIVER_REJECTED
      ) {
        setActiveSelect("done");
      } else {
        setActiveSelect("ongoing");
      }
    } else {
      getOrderGroupChat();
    }
  };

  useEffect(() => {
    if (selectedOrder) {
      window.history.pushState(
        null,
        "",
        `/home?id=${selectedOrder.manifest_id}`,
      );
      setMessage("");
      handleOrderSelect(selectedOrder);
      connnectChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder, setSelectedOrder]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    getOrderGroupChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSelect]);

  useEffect(() => {
    fetchData();

    return () => {
      if (ws.current) {
        ws.current.close();
        console.log("Disconnect WebSocket");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {groupChat == null ? (
        <Space style={{ width: "100%", flex: 1, justifyContent: "center" }}>
          <Spin size="large" />
        </Space>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          {groupChat.length > 0 || groupNumber > 0 ? (
            <>
              {Utils.IsMobile ? (
                <Tabs
                  activeKey={activeTab}
                  onChange={(key) => {
                    setActiveTab(key);
                  }}
                >
                  <TabPane tab="Order List" key="1">
                    <Content
                      style={{
                        background: "#fff",
                        padding: 8,
                        paddingRight: 0,
                      }}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Search
                          placeholder="Search"
                          style={{ marginBottom: 5 }}
                          onChange={(e) => handleSearch(e.target.value)}
                          onSearch={handleSearchClick}
                          onPressEnter={handleSearchEnterKeyPress}
                        />
                        <Space style={{ marginBottom: 10 }}>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "center",
                              cursor: "pointer",
                              borderBottom:
                                activeSelect === "ongoing"
                                  ? "1px solid black"
                                  : "none",
                              color:
                                activeSelect === "ongoing"
                                  ? "black"
                                  : "#8a8d91",
                            }}
                            onClick={() => setActiveSelect("ongoing")}
                          >
                            Ongoing
                          </div>
                          <div
                            style={{
                              flex: 1,
                              textAlign: "center",
                              cursor: "pointer",
                              borderBottom:
                                activeSelect === "done"
                                  ? "1px solid black"
                                  : "none",
                              color:
                                activeSelect === "done" ? "black" : "#8a8d91",
                            }}
                            onClick={() => setActiveSelect("done")}
                          >
                            Done
                          </div>
                        </Space>
                        <div
                          style={{
                            height: "calc(100vh - 330px)",
                            overflowY: "auto",
                          }}
                        >
                          <List
                            itemLayout="vertical"
                            dataSource={groupChat}
                            renderItem={(order) => (
                              <List.Item
                                onClick={() => {
                                  setSelectedOrder(order);
                                  setActiveTab("2");
                                }}
                                style={{
                                  padding: 10,
                                  borderRadius: 5,
                                  cursor: "pointer",
                                  background:
                                    selectedOrderId === order.manifest_id
                                      ? "#f5f5f5"
                                      : "transparent",
                                }}
                              >
                                <List.Item.Meta
                                  title={
                                    <div
                                      style={{
                                        fontWeight:
                                          selectedOrderId === order.manifest_id
                                            ? "bold"
                                            : "normal",
                                        color: getTextColorOrder(order.status),
                                      }}
                                    >
                                      {order.order_number}
                                    </div>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                      </Space>
                    </Content>
                  </TabPane>
                  <TabPane tab="Conversation" key="2">
                    {selectedOrder && (
                      <Content
                        style={{
                          background: "#fff",
                          padding: 8,
                          paddingRight: 0,
                        }}
                      >
                        <div style={{ marginBottom: 16 }}>
                          <Button
                            type="link"
                            onClick={() =>
                              handleViewManifest(selectedOrder.manifest_id)
                            }
                          >
                            {getOrderStatus(selectedOrder)}
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            height: "calc(100vh - 420px)",
                            overflow: "auto",
                          }}
                        >
                          {chatHistory.map((data, index) => (
                            <Card
                              key={index}
                              style={{
                                width: "auto",
                                border: "none",
                                display: "flex",
                                alignItems: "flex-start",
                                alignSelf:
                                  data.sender === "You"
                                    ? "flex-end"
                                    : "flex-start",
                                background: "transparent",
                                // padding: 10,
                              }}
                              bodyStyle={{
                                padding: 8,
                              }}
                            >
                              <Meta
                                avatar={
                                  data.avatar_id ? (
                                    <Avatar src={data.avatar_url} />
                                  ) : (
                                    <Avatar>
                                      {data.name ? data.name[0] : ""}
                                    </Avatar>
                                  )
                                }
                                title={
                                  <div
                                    style={{
                                      color: "#00000073",
                                      fontSize: "small",
                                    }}
                                  >
                                    <div>{`${data.name} - ${data.company_name} Company`}</div>
                                    <div>
                                      {`${data.created_date} ${data.created_time}`}
                                    </div>
                                  </div>
                                }
                                description={
                                  <div
                                    style={{
                                      padding: 10,
                                      background:
                                        data.sender === "You"
                                          ? "#1890ff"
                                          : "#ececec",
                                      color:
                                        data.sender === "You" ? "#fff" : "#000",
                                      borderRadius: 10,
                                    }}
                                  >
                                    {data.message}
                                  </div>
                                }
                              />
                            </Card>
                          ))}
                          <div ref={scrollRef} />
                        </div>
                        <div
                          style={{
                            marginTop: 16,
                            display: "flex",
                            gap: "10px",
                            bottom: 0,
                          }}
                        >
                          <Input.TextArea
                            value={message}
                            onChange={handleMessageChange}
                            onKeyPress={handleEnterKeyPress}
                            placeholder="Type your message here..."
                            style={{ flex: 1 }}
                            autoSize
                            maxLength={500}
                            disabled={
                              (selectedOrder &&
                                selectedOrder.status ===
                                  AppStatus.ORDER_COMPLETED) ||
                              (selectedOrder &&
                                selectedOrder.status ===
                                  AppStatus.RECEIVER_REJECTED)
                            }
                          />
                          <Button
                            type="primary"
                            onClick={handleSendMessage}
                            loading={loading}
                            disabled={message === "" && !loading}
                          >
                            Send
                          </Button>
                        </div>
                        {message.length >= 500 && (
                          <span style={{ color: "red", fontSize: "small" }}>
                            Maximum character limit exceeded (500 characters)
                          </span>
                        )}

                        <Space
                          direction="vertical"
                          style={{ width: "100%", paddingTop: 16 }}
                        >
                          <Alert message={warningMessage} type="warning" />
                        </Space>
                      </Content>
                    )}
                  </TabPane>
                </Tabs>
              ) : (
                <Layout>
                  <Sider
                    width={300}
                    style={{ background: "#fff", padding: 10 }}
                  >
                    <Search
                      placeholder="Search"
                      style={{ marginBottom: 5 }}
                      onChange={(e) => handleSearch(e.target.value)}
                      onSearch={handleSearchClick}
                      onPressEnter={handleSearchEnterKeyPress}
                    />
                    <Space style={{ marginBottom: 10 }}>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                          cursor: "pointer",
                          borderBottom:
                            activeSelect === "ongoing"
                              ? "1px solid black"
                              : "none",
                          color:
                            activeSelect === "ongoing" ? "black" : "#8a8d91",
                        }}
                        onClick={() => setActiveSelect("ongoing")}
                      >
                        Ongoing
                      </div>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                          cursor: "pointer",
                          borderBottom:
                            activeSelect === "done"
                              ? "1px solid black"
                              : "none",
                          color: activeSelect === "done" ? "black" : "#8a8d91",
                        }}
                        onClick={() => setActiveSelect("done")}
                      >
                        Done
                      </div>
                    </Space>
                    <List
                      style={{
                        height: "calc(100vh - 250px)",
                        overflow: "auto",
                      }}
                      itemLayout="horizontal"
                      dataSource={groupChat}
                      renderItem={(order) => (
                        <List.Item
                          onClick={() => setSelectedOrder(order)}
                          style={{
                            padding: 10,
                            borderRadius: 5,
                            cursor: "pointer",
                            background:
                              selectedOrderId === order.manifest_id
                                ? "#f5f5f5"
                                : "transparent",
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <div
                                style={{
                                  fontWeight:
                                    selectedOrderId === order.manifest_id
                                      ? "bold"
                                      : "normal",
                                  color: getTextColorOrder(order.status),
                                }}
                              >
                                {order.order_number}
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Sider>
                  <Layout
                    style={{ background: "#ffff", border: "1px solid #e8e8e8" }}
                  >
                    <Content style={{ padding: 10 }}>
                      {selectedOrder ? (
                        <div>
                          <div style={{ marginBottom: 16 }}>
                            <Button
                              type="link"
                              onClick={() =>
                                handleViewManifest(selectedOrder.manifest_id)
                              }
                            >
                              {getOrderStatus(selectedOrder)}
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              height: "calc(100vh - 300px)",
                              overflow: "auto",
                            }}
                          >
                            {chatHistory.map((data, index) => (
                              <Card
                                key={index}
                                style={{
                                  width: "auto",
                                  maxWidth: "80%",
                                  border: "none",
                                  paddingTop: 5,
                                  display: "flex",
                                  alignItems: "flex-start",
                                  alignSelf:
                                    data.sender === "You"
                                      ? "flex-end"
                                      : "flex-start",
                                  background: "transparent",
                                  // padding: 10,
                                }}
                                bodyStyle={{
                                  padding: 0,
                                  paddingRight: 10,
                                }}
                              >
                                <Meta
                                  avatar={
                                    data.avatar_id ? (
                                      <Avatar src={data.avatar_url} />
                                    ) : (
                                      <Avatar>
                                        {data.name ? data.name[0] : ""}
                                      </Avatar>
                                    )
                                  }
                                  title={
                                    <div
                                      style={{
                                        color: "#00000073",
                                        fontSize: "small",
                                      }}
                                    >
                                      {`${data.name} - ${data.company_name} Company, ${data.created_date} ${data.created_time}`}
                                    </div>
                                  }
                                  description={
                                    <div
                                      style={{
                                        padding: 10,
                                        background:
                                          data.sender === "You"
                                            ? "#1890ff"
                                            : "#ececec",
                                        color:
                                          data.sender === "You"
                                            ? "#fff"
                                            : "#000",
                                        borderRadius: 10,
                                      }}
                                    >
                                      {data.message}
                                    </div>
                                  }
                                />
                              </Card>
                            ))}
                            <div ref={scrollRef} />
                          </div>
                          <div
                            style={{
                              marginTop: 16,
                              display: "flex",
                              gap: "10px",
                              bottom: 0,
                            }}
                          >
                            <Input.TextArea
                              value={message}
                              onChange={handleMessageChange}
                              onKeyPress={handleEnterKeyPress}
                              placeholder="Type your message here..."
                              style={{ flex: 1 }}
                              autoSize
                              maxLength={500}
                              disabled={
                                (selectedOrder &&
                                  selectedOrder.status ===
                                    AppStatus.ORDER_COMPLETED) ||
                                (selectedOrder &&
                                  selectedOrder.status ===
                                    AppStatus.RECEIVER_REJECTED)
                              }
                            />
                            <Button
                              type="primary"
                              onClick={handleSendMessage}
                              loading={loading}
                              disabled={message === "" && !loading}
                            >
                              Send
                            </Button>
                          </div>
                          {message.length >= 500 && (
                            <span style={{ color: "red", fontSize: "small" }}>
                              Maximum character limit exceeded (500 characters)
                            </span>
                          )}

                          <Space
                            direction="vertical"
                            style={{ width: "100%", paddingTop: 16 }}
                          >
                            <Alert message={warningMessage} type="warning" />
                          </Space>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Content>
                  </Layout>
                </Layout>
              )}

              {isViewManifestModal && (
                <ManifestViewModal
                  isOpen={isViewManifestModal}
                  manifest_id={selectedOrderId}
                  onCancel={() => {
                    setIsViewManifestModal(false);
                  }}
                  onOk={() => {
                    setIsViewManifestModal(false);
                  }}
                />
              )}
            </>
          ) : (
            <Flex justify="center">
              <div
                style={{
                  width: 500,
                  gap: 16,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Description data={dataInfo} column={1} />
              </div>
            </Flex>
          )}
        </div>
      )}
    </>
  );
};

export default ChatManagement;
