const AppStatus = {
  NEW: 1,
  WAITING_FOR_APPROVED: 2,
  TRANSPORT_READY_FOR_PICKUP: 3,
  ACKNOWLEDGED: 5,
  TRANSPORT_DECLINED: -11,
  VEHICLE_ASSIGNED: 6,
  VEHICLE_WAITING_ACCEPTED: 60,
  VEHICLE_ACCEPTED: 61,
  TRANSPORT_PICKINGUP: 7,
  TRANSPORT_SAMPLE_ACCEPTED: 8,
  TRANSPORT_SAMPLE_COLLECTION: 9,
  TRANSPORT_SAMPLE_COLLECTION_FINISHED: 10,
  TRANSPORT_SAMPLE_REJECTED: -9,
  TRANSPORT_IN_PROGRESS: 11,
  TRANSPORT_DELIVERED: 12,
  TRANSPORT_TRANSFERING: 13,
  TRANSPORT_DELIVERED_REJECTED: -13,
  ACKNOWLEDGE_DELIVERED: 14,
  RECEIVER_ACCEPTED: 15,
  RECEIVER_REJECTED: -16,
  IN_WORK_ORDER: 17,
  ORDER_COMPLETED: 99,
  ARCHIVE: 62,
};

export default AppStatus;
