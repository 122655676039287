import React, { useEffect, useState } from "react";
import ManifestOriginationEntityView from "./ManifestOriginationEntityView";
import ManifestTransportationEntityView from "./ManifestTransportationEntityView";
import ManifestDestinationEntityView from "./ManifestDestinationEntityView";
import ManifestProductEntityView from "./ManifestProductEntityView";
import ManifestRecceiptOrRejectEntityView from "./ManifestRecceiptOrRejectEntityView";
import { Button, Collapse } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import ManifestEditOriginationModal from "./ManifestEditOriginationModal";
import { ManifestAPI } from "../../modules/ManifestAPI";
import ManifestEditTransportModal from "./ManifestEditTransportModal";
import ManifestEditDestinationModal from "./ManifestEditDestinationModal";
import { useAppStore } from "../../utils/appstate";
import { AppAction } from "../../utils/constants";
import { ChatAPI } from "../../modules/chat/ChatAPI";
import ManifestConversationView from "./ManifestConversationView";
import ManifestAddProductItemModal from "./ManifestAddProductItemModal";
import constants from "../../utils/constants";

const ManifestDetailView = ({ manifest_id }) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState({
    origination: false,
    transport: false,
    destination: false,
    products: false,
  });

  const [editMode, setEditMode] = useState();
  const [data, setData] = useState();
  const appInfo = useAppStore((state) => state.AppInfo);
  const [conversation, setConversation] = useState([]);
  const [config, setConfig] = useState();
  const [dataProduct, setDataProduct] = useState({});
  const { BATCH_SIZE_UNIT_LIST, WEIGHT_UNIT_LIST, ITEM_UNIT_LIST } = constants;

  const items = [
    {
      key: "1",
      label: "ORIGINATING ENTITY DETAILS",
      children: (
        <ManifestOriginationEntityView
          model={data?.origination}
          loading={loading}
          editable={false}
        />
      ),
      extra:
        editable.origination && data?.origination.status !== 99 ? (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditMode("origination");
            }}
          />
        ) : null,
      //   extra: <CloseOutlined onClick={() => history("/origination/order")} />,
    },
    {
      key: "2",
      label: "TRANSPORT DETAILS",
      children: (
        <ManifestTransportationEntityView
          model={data?.transportation}
          loading={loading}
          editable={false}
        />
      ),
      extra:
        editable.transport && data?.transportation.status !== 99 ? (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditMode("transport");
            }}
          />
        ) : null,
    },
    {
      key: "3",
      label: "DESTINATION DETAILS",
      children: (
        <ManifestDestinationEntityView
          model={data?.destination}
          loading={loading}
          editable={false}
        />
      ),
      extra:
        editable.destination && data?.destination.status !== 99 ? (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditMode("destination");
            }}
          />
        ) : null,
    },
    {
      key: "4",
      label: "PRODUCT DETAILS",
      children: (
        <ManifestProductEntityView
          model={data?.products}
          order_number={data?.manifest.order_number}
          loading={loading}
          editable={editable.products}
          company_id={data?.destination.company_id}
        />
      ),
      extra:
        editable.products && data?.products.status !== 99 ? (
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setEditMode("product");
              setDataProduct({
                id: null,
                manifest_id: data?.manifest.id,
                batch_size_unit: BATCH_SIZE_UNIT_LIST[0].value,
                weight_unit: WEIGHT_UNIT_LIST[0].value,
                item_unit: ITEM_UNIT_LIST[0].value,
                sort_index: data?.products.length + 1,
              });
            }}
          />
        ) : null,
    },
    {
      key: "5",
      label: "PRODUCT RECEIPT CONFIRMATION OR REJECTION DISCLAIMER",
      children: (
        <ManifestRecceiptOrRejectEntityView
          receipt_confirm={data?.receipt_confirm}
          reject_disclaimer={data?.reject_disclaimer}
          loading={loading}
          editable={false}
        />
      ),
    },
    {
      key: "6",
      label: "CONVERSATION",
      children: (
        <ManifestConversationView
          conversation={conversation}
          loading={loading}
          editable={false}
        />
      ),
    },
  ];
  useEffect(() => {
    const getInit = () => {
      const api = new ManifestAPI();
      const chatAPIs = new ChatAPI();
      setLoading(true);
      Promise.all([
        api.getManifestById({ id: manifest_id }).then(
          (resp) => resp.data,
          (err) => null,
        ),
        chatAPIs.getChatDetail(manifest_id).then(
          (resp) => resp.data,
          (err) => null,
        ),
        api.getOriginationConfig().then(
          (resp) => resp.data,
          (err) => null,
        ),
      ])
        .then((response) => {
          if (response[0] != null) {
            const resp = response[0].result;
            setData(resp);
            const supper_editor = appInfo.Actions.find(
              (x) => x.menu_code === AppAction.SUPER_EDITOR,
            );
            if (supper_editor) {
              setEditable({
                origination: true,
                transport: true,
                destination: true,
                products: true,
              });
            } else {
              const origination_editor = appInfo.Actions.find(
                (x) => x.menu_code === AppAction.ORIGINATION_DETAIL_EDITOR,
              );

              const transport_editor = appInfo.Actions.find(
                (x) => x.menu_code === AppAction.TRANSPORT_DETAIL_EDITOR,
              );
              const denstination_editor = appInfo.Actions.find(
                (x) => x.menu_code === AppAction.DESTINATION_DETAIL_EDITOR,
              );

              const product_editor = appInfo.Actions.find(
                (x) => x.menu_code === AppAction.PRODUCT_DETAIL_EDITOR,
              );
              let permissions = {
                origination: origination_editor ? true : false,
                transport: transport_editor ? true : false,
                destination: denstination_editor ? true : false,
                products: product_editor ? true : false,
              };
              setEditable(permissions);
            }
          }
          if (response[1] != null) {
            const chats = response[1].result;
            setConversation(chats);
          }
          if (response[2] != null) {
            const configs = response[2].result;
            setConfig(configs);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getInit();

    return () => {
      setLoading(false);
      setEditable({
        origination: false,
        transport: false,
        destination: false,
        products: false,
      });
      setData();
    };
  }, []);

  const onChangeCollapse = () => {};

  return (
    <>
      <Collapse
        items={items}
        defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
        onChange={onChangeCollapse}
        collapsible="icon"
      />
      {editMode === "origination" && (
        <ManifestEditOriginationModal
          model={data?.origination}
          onCancel={() => {
            setEditMode();
          }}
          onOk={(resp) => {
            setEditMode();
            // const _origination = { ...data?.origination, ...resp };
            // setData((obj) => ({ ...obj, origination: _origination }));
            setData(resp);
          }}
          isOpen={editMode && editMode !== ""}
          type={editMode}
        />
      )}

      {editMode === "transport" && (
        <ManifestEditTransportModal
          model={data?.transportation}
          onCancel={() => {
            setEditMode();
          }}
          onOk={(resp) => {
            setEditMode();
            // const _transport = { ...data?.transportation, ...resp };
            // setData((obj) => ({ ...obj, transport: _transport }));
            setData(resp);
          }}
          isOpen={editMode && editMode !== ""}
          type={editMode}
        />
      )}

      {editMode === "destination" && (
        <ManifestEditDestinationModal
          model={data?.destination}
          onCancel={() => {
            setEditMode();
          }}
          onOk={(resp) => {
            setEditMode();
            // const _transport = { ...data?.transportation, ...resp };
            // setData((obj) => ({ ...obj, transport: _transport }));
            setData(resp);
          }}
          isOpen={editMode && editMode !== ""}
          type={editMode}
        />
      )}

      {editMode === "product" && dataProduct && (
        <ManifestAddProductItemModal
          model={dataProduct}
          onCancel={() => {
            setEditMode();
            setDataProduct(); // dataProduct=undefine
          }}
          onOk={(resp) => {
            setEditMode();

            setData(resp);
          }}
          isOpen={editMode && editMode !== ""}
          type={"Add Product"}
          config={config}
          company_id={data?.destination.company_id}
        />
      )}
    </>
  );
};

export default ManifestDetailView;
