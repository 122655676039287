import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "./../configs";
import "./register.css";
import { Button, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";

export default function Forgot() {
  const navigate = useNavigate();

  // Password Update Form
  const [formPasswordUpdate, setFormPasswordUpdate] = useState({
    email: "",
  });

  const onChangeForm = (event) => {
    setFormPasswordUpdate({ ...formPasswordUpdate, email: event.target.value });
  };

  const onSubmitHandler = async (event) => {
    debugger;
    event.preventDefault();
    // Post to register API

    await axios
      .post("/auth/forgot-password", formPasswordUpdate)
      .then((response) => {
        // move to sign in page
        navigate("/login");
        // add successfully notif
        toast.success(response.data.detail);
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);
      });
  };

  return (
    <div className="forgot_container ">
      <div
        className="forgot_header"
        style={{ paddingTop: 20, paddingBottom: 5 }}
      >
        <p style={{ padding: 0, margin: 0 }}>Forgot Password?</p>

        <Divider
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 10,
          }}
        />
      </div>
      <form onSubmit={onSubmitHandler}>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ paddingBottom: 10 }}>
            <label
              htmlFor="email"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Your email address
            </label>

            <Input
              prefix={<UserOutlined />}
              id="email"
              style={{ width: "100%", marginTop: 5 }}
              placeholder="Your email address"
              onChange={onChangeForm}
            />
          </div>
          <div
            style={{
              paddingBottom: 20,
              paddingTop: 15,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Button type="primary" htmlType="submit" size="large">
              Reset password
            </Button>
            <p style={{ textAlign: "center" }}>
              Already have login and password?{" "}
              <Link to="/login">
                <span>Sign In</span>
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}
