import { useState } from "react";
import React from "react";
import { Flex, Form, Input, Modal, Select, Switch } from "antd";
// import "./register.css";
import { DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import Utils from "../utils/utils";
import constants from "../utils/constants";

function AddLicenseeDetail({
  modal: [item, setModalAdd],
  formAction,
  form,
  fileAction: [fileUploadList, setFileUploadList],
  loading,
  type,
}) {
  const [, setPreviewOpen] = useState(false);
  const [, setPreviewImage] = useState("");
  const [, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const companyId = localStorage.getItem("auth_company_id");
  const token = auth_token_type + " " + auth_token;
  const { LICENSEE_TYPES } = constants;
  //const [isDefault, setIsDefault] = useState(form.getFieldValue("is_default"));
  const [isDefault, setIsDefault] = useState(true);

  const uploadProps = {
    name: "file",
    action: `${Utils.ApiEndpoint}/media/${companyId}/single_upload`,
    headers: {
      authorization: token,
    },
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    );
  };

  const onFinish = (formData) => {
    let newUploadFiles = [];

    fileList.forEach((nfile) => {
      let media = nfile?.response?.result;
      newUploadFiles.push(media);
    });

    formAction(formData, newUploadFiles);
  };

  const handleChange = ({ file, fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onSearch = (value) => {};

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => {
    // (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const label = (option?.test ?? "").toString();
    return label.includes(input.toLowerCase());
  };

  //TODO: set licence is default
  const onSwitchChange = (checked) => {
    setIsDefault(checked);
    form.setFieldsValue({ is_default: checked });
  };

  // useEffect(() => {
  //   setIsDefault(form.getFieldValue("is_default"));
  // }, [form.getFieldValue("is_default")]);

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      {...item}
      style={{ top: 10 }}
      maskClosable={false}
      onCancel={() => setModalAdd({})}
      forceRender
      onOk={() => handleSubmit()}
      okButtonProps={{ loading: loading, icon: item.icon }}
    >
      {/* <Modal {...item} onCancel={() => setModalAdd({})} forceRender onOk={handleUpload} okButtonProps={{ loading: item.status, icon: item.icon }}> */}
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="licensee_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="license_number"
          label="License#"
          required
          rules={[{ required: true, message: "License number is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="issue_state"
          label="State Issued"
          required
          rules={[{ required: true, message: "State Issued is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="licensee_name"
          label="Licensee Name"
          required
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>
        {(!type || type !== "Driver") && (
          <Form.Item
            name="licensee_type"
            label="License Type"
            required
            rules={[{ required: true, message: "License Type is required" }]}
          >
            <Select
              showSearch
              placeholder="Select a license type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={LICENSEE_TYPES.map((x) => ({
                value: x.code,
                test: x.name,
                label: <Flex justify="space-between">{x.name} </Flex>,
              }))}
            />
          </Form.Item>
        )}
        {(!type || type !== "Driver") && (
          <Form.Item
            name="is_default"
            label="License default"
            rules={[{ required: false, message: "" }]}
          >
            <Switch checked={isDefault} onChange={onSwitchChange} />
          </Form.Item>
        )}
        <Form.Item
          name="licensee_address"
          label="Licensee Address"
          required
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="licensee_phone"
          label="Licensee Phone"
          required
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="issued_date"
          label="Issued Date"
          required
          rules={[{ required: true, message: "Issued date is required" }]}
        >
          <DatePicker onChange={onChange} />
        </Form.Item>

        <Form.Item
          name="expired_date"
          label="Expired Date"
          required
          rules={[{ required: true, message: "Expired date is required" }]}
        >
          <DatePicker onChange={onChange} />
        </Form.Item>

        {/* start upload images */}
        <Form.Item label="License Images">
          <Upload
            name="file"
            fileList={fileList}
            listType="picture-card"
            onPreview={handlePreview}
            onChange={handleChange}
            accept="image/*"
            // defaultFileList={[...fileList]}
            {...uploadProps}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </Form.Item>

        {/* end upload images */}
      </Form>
    </Modal>
  );
}

export default AddLicenseeDetail;
