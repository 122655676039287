import React, { useEffect, useState } from "react";
import ManifestEditOrigination from "./ManifestEditOrigination";
import { Button, Drawer, Form, Input } from "antd";
import ModalSignatureCanvas from "../ModalSignatureCanvas";
import { ManifestAPI } from "../../modules/ManifestAPI";
import { toast } from "react-toastify";

// TODO:----------
const SubmitButton = ({ form, children, submitAction }) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable}
      onClick={submitAction}
    >
      {children}
    </Button>
  );
};
//----------

const ManifestEditOriginationModal = ({ onCancel, onOk, isOpen, model }) => {
  const [loading, setLoading] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setIsApproveModal(true);
  };
  const onApprove = (result) => {
    if (loading) return;
    const formData = form.getFieldsValue();
    const _now = new Date();
    const _timezone = _now.getTimezoneOffset();
    const output = {
      ...formData,
      signature: { ...result },
      timezone: _timezone,
    };

    // onOk(formData);

    // call server
    setLoading(true);
    new ManifestAPI()
      .editOrigination(output)
      .then(
        (resp) => {
          setLoading(false);
          // fetchAllDataDetailOrder();
          onOk(resp.data.result);
          toast.success(resp.data.detail);
        },
        (error) => {
          toast.error(error.response.data.detail);
          setLoading(false);
        },
      )
      .finally(() => {
        setLoading(false);
      });
    //--------
  };

  // useEffect(() => {
  //   if (isOpen) {
  //   }
  // }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      style={{ top: 5, padding: "0" }}
      onClose={onCancel}
      width="100VW"
      //width="800px"
      title={`Edit Origination`}
      placement={"right"}
      extra={
        // <Button
        //   type="primary"
        //   loading={loading}
        //   onClick={() => {
        //     form.submit();
        //   }}
        // >
        //   Save
        // </Button>
        <SubmitButton submitAction={() => form.submit()} form={form}>
          Save
        </SubmitButton>
      }
    >
      <section>
        <Form
          name="orignation_form"
          initialValues={model}
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item name="manifest_id" hidden>
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="company_name"
            label="Company Name"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            name="company_phone"
            label="Company Phone"
            type="phone"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input type="phone" />
          </Form.Item>
          <Form.Item
            name="contact_name"
            label="Contact Name"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact_phone"
            label="Contact Phone"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="license_number"
            label="License #"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_name"
            label="Licensee Name"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_phone"
            label="Licensee Phone"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_address"
            label="Licensee Address"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="origination_address"
            label="Origination Address"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
                    name="is_transport_to_laboratory"
                    label="Is this transport to a laboratory?"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select Yes or No",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="is_certificate_attached"
                    label="Certificate of Analysis Attached?"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select Yes or No",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(ev) =>
                        setIsCertificateAttached(ev.target.value)
                      }
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item> */}
        </Form>
      </section>
      {isApproveModal && (
        <ModalSignatureCanvas
          onCancel={() => {
            setIsApproveModal(false);
          }}
          onOk={(result) => {
            setIsApproveModal(false);
            onApprove(result);
          }}
          title={"Edit Origination Information"}
          isOpen={isApproveModal}
          displayInput={true}
          reasonLabel={"Reason for change"}
          //comment={"Edit Origination"}
          comment={""}
        />
      )}
    </Drawer>
  );
};

export default ManifestEditOriginationModal;
