import React from "react";
import { Form, Input, Modal } from "antd";
import "./register.css";
import { useState } from "react";
import ViewTermsModal from "../components/ViewTermsModal";
import ViewPolicyModal from "../components/ViewPolicyModal";

export default function TermOfUseForm({
  modal: [item, setModalAdd],
  formAction,
  form,
  loading,
}) {
  const [modalPolicy, setModalPolicy] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);

  //------------------
  const handleViewPolicy = () => {
    setModalPolicy(true);
  };

  const handleViewTerms = () => {
    setModalTerms(true);
  };

  return (
    <Modal
      style={{ top: 10 }}
      {...item}
      onCancel={() => setModalAdd({})}
      forceRender
      onOk={() => form.submit()}
      okButtonProps={{ loading: loading, icon: item.icon }}
    >
      <Form
        form={form}
        onFinish={formAction}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="company_id" hidden>
          <Input />
        </Form.Item>
      </Form>

      <div>
        <h3>Please review the following:</h3>
        <div>
          <a href="#/" onClick={() => handleViewPolicy()}>
            1. View privacy policy
          </a>
        </div>
        <div>
          <a href="#/" onClick={() => handleViewTerms()}>
            2. View terms of use
          </a>
        </div>
      </div>
      {modalPolicy && (
        <ViewPolicyModal
          open={modalPolicy}
          onCancel={() => {
            setModalPolicy(false);
          }}
        />
      )}
      {modalTerms && (
        <ViewTermsModal
          open={modalTerms}
          onCancel={() => {
            setModalTerms(false);
          }}
        />
      )}
    </Modal>
  );
}
