import React from "react";
import { useState } from "react";
import { Button, Segmented, Space, Statistic } from "antd";
import { Card, Col, Row } from "antd";
import { InvoiceApi } from "../InvoiceApi";
import { BuildTwoTone } from "@ant-design/icons";
import axios from "../../../configs";
import Utils from "../../../utils/utils";
import { InvocieStatus } from "../invoiceUtils";
import ModalExpense from "../components/ModalExpense";
import ModalRevenue from "../components/ModalRevenue";
import { useNavigate } from "react-router-dom";
const InvoiceDashboard = () => {
  const query = new URLSearchParams(window.location.search);
  const status = query.get("status");
  const [statusFilter, setStatusFilter] = useState(status);
  const [isOpenModalExpense, setIsOpenModalExpense] = useState(false);
  const [isOpenModalRevenue, setIsOpenModalRevenue] = useState(false);

  const navigate = useNavigate();
  let reftimeout = React.useRef(null);
  const [summary, setSummary] = useState({
    total: 0,
    value_total: 0,
    expense: {},
    revenue: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");

  const fetchSummary = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(
          `/accounting/${company_id}/common/summary?status=${statusFilter}`,
          {
            headers: { Authorization: token },
          },
        )
        .then((response) => {
          let listUser = response.data.result;

          setSummary(listUser);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  const handleInit = () => {
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      fetchSummary();
    }, 100);
  };

  React.useEffect(() => {
    handleInit();
    return () => {
      InvoiceApi.clearInstance();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  React.useEffect(() => {
    if (!status) {
      setStatusFilter("Weekly");
    } else {
      setStatusFilter(status);
    }
  }, [status]);

  return (
    <>
      <Col>
        <div>
          <Segmented
            disabled={isLoading}
            options={["Weekly", "Monthly", "Yearly"]}
            onChange={(value) => {
              setStatusFilter(value);
            }}
          />
        </div>
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card>
              <Statistic
                loading={isLoading}
                title={
                  <div
                    style={{ fontSize: "20px", color: InvocieStatus.NEW.color }}
                  >
                    Total Income
                  </div>
                }
                value={Utils.formatMoney(summary.value_total)}
                precision={2}
                valueStyle={{
                  color: InvocieStatus.NEW.color,
                }}
              />

              <Space>{summary.total} Bills</Space>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card
              title="Revenues"
              extra={
                <Button
                  onClick={() => {
                    setIsOpenModalRevenue(true);
                  }}
                  type="primary"
                >
                  Create{" "}
                </Button>
              }
            >
              <Row
                onClick={() => {
                  navigate("/invoice/revenue");
                }}
                justify="space-around"
                align="middle"
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Paid "
                    value={Utils.formatMoney(summary.revenue.value_paid)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.PAID.color,
                    }}
                  />
                  <Space>{summary.revenue.paid} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Overdue "
                    value={Utils.formatMoney(summary.revenue.value_overdue)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.OVERDUE.color,
                    }}
                  />
                  <Space>{summary.expense.overdue} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Projected "
                    value={Utils.formatMoney(summary.revenue.value_project)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.PROJECTED.color,
                    }}
                  />
                  <Space>{summary.expense.project} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total"
                    value={Utils.formatMoney(summary.revenue.value_total)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.NEW.color,
                    }}
                  />
                  <Space>{summary.expense.total} Bills</Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card
              title="Expenses"
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOpenModalExpense(true);
                  }}
                >
                  Create
                </Button>
              }
            >
              <Row
                onClick={() => {
                  navigate("/invoice/expense");
                }}
                justify="space-around"
                align="middle"
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Paid "
                    value={Utils.formatMoney(summary.expense.value_paid)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.PAID.color,
                    }}
                  />
                  <Space>{summary.expense.paid} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Overdue"
                    value={Utils.formatMoney(summary.expense.value_overdue)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.OVERDUE.color,
                    }}
                  />
                  <Space>{summary.revenue.overdue} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total Projected"
                    value={Utils.formatMoney(summary.expense.value_project)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.PROJECTED.color,
                    }}
                  />
                  <Space>{summary.revenue.project} Bills</Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Statistic
                    loading={isLoading}
                    title="Total"
                    value={Utils.formatMoney(summary.expense.value_total)}
                    // prefix={<DollarTwoTone />}
                    precision={2}
                    valueStyle={{
                      color: InvocieStatus.NEW.color,
                    }}
                  />
                  <Space>{summary.revenue.total} Bills</Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card onClick={() => {}} title="Payment in">
              <Row justify="space-around" align="middle">
                <Statistic
                  loading={isLoading}
                  title="Total"
                  value={summary.revenue.cash_in}
                  prefix={<BuildTwoTone />}
                  precision={2}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Card onClick={() => {}} title="Payment out">
              <Row justify="space-around" align="middle">
                <Statistic
                  loading={isLoading}
                  title="Total"
                  value={summary.expense.cash_out}
                  prefix={<BuildTwoTone />}
                  precision={2}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      {isOpenModalExpense && (
        <ModalExpense
          item={{}}
          isOpen={isOpenModalExpense}
          onCancel={() => {
            setIsOpenModalExpense(false);
          }}
          onOk={() => {
            handleInit();
            setIsOpenModalExpense(false);
          }}
        ></ModalExpense>
      )}
      {isOpenModalRevenue && (
        <ModalRevenue
          item={{}}
          isOpen={isOpenModalRevenue}
          onCancel={() => {
            setIsOpenModalRevenue(false);
          }}
          onOk={() => {
            setIsOpenModalRevenue(false);
            handleInit();
          }}
        ></ModalRevenue>
      )}
    </>
  );
};
export default InvoiceDashboard;
