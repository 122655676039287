import React, { useEffect, useState } from "react";
import ManifestEditOrigination from "./ManifestEditOrigination";
import { Button, Drawer, Form, Input, InputNumber } from "antd";
import ModalSignatureCanvas from "../ModalSignatureCanvas";
import { ManifestAPI } from "../../modules/ManifestAPI";
import { toast } from "react-toastify";

const ManifestEditDestinationModal = ({ onCancel, onOk, isOpen, model }) => {
  const [loading, setLoading] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setIsApproveModal(true);
  };
  const onApprove = (result) => {
    debugger;
    if (loading) return;
    const formData = form.getFieldsValue();
    const _now = new Date();
    const _timezone = _now.getTimezoneOffset();
    const output = {
      ...formData,
      signature: { ...result },
      timezone: _timezone,
    };

    // onOk(formData);

    // call server
    setLoading(true);
    new ManifestAPI()
      .editDestination(output)
      .then(
        (resp) => {
          setLoading(false);
          // fetchAllDataDetailOrder();
          onOk(resp.data.result);
          toast.success(resp.data.detail);
        },
        (error) => {
          toast.error(error.response.data.detail);
          setLoading(false);
        },
      )
      .finally(() => {
        setLoading(false);
      });
    //--------
  };

  // useEffect(() => {
  //   if (isOpen) {
  //   }
  // }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      style={{ top: 5, padding: "0" }}
      onClose={onCancel}
      width="100VW"
      title={`Edit Destination`}
      placement={"right"}
      extra={
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Save
        </Button>
      }
    >
      <section>
        <Form
          name="destination_form"
          initialValues={model}
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item name="manifest_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="company_phone"
            label="Company Phone"
            type="phone"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input type="phone" />
          </Form.Item>

          <Form.Item
            name="license_number"
            label="License #"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_name"
            label="Licensee Name"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_phone"
            label="Licensee Phone"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_address"
            label="Licensee Address"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="destination_address"
            label="Destination Address"
            type="text"
            rules={[{ required: true, message: "This field is required" }]}
            required
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="stop_number"
            label={"Stop Number"}
            required
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber style={{ width: "100%", maxWidth: "150px" }} min={1} />
          </Form.Item>

          <Form.Item
            name="max_number"
            label={"Max Number"}
            required
            rules={[
              { required: true, message: "This field is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const stop_number = getFieldValue("stop_number");
                  if (!value || stop_number <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The field is greater than or equal to stop number",
                    ),
                  );
                },
              }),
            ]}
          >
            <InputNumber style={{ width: "100%", maxWidth: "135px" }} min={1} />
          </Form.Item>
        </Form>
      </section>
      {isApproveModal && (
        <ModalSignatureCanvas
          onCancel={() => {
            setIsApproveModal(false);
          }}
          onOk={(result) => {
            setIsApproveModal(false);
            onApprove(result);
          }}
          title={"Edit Destination Infomation"}
          isOpen={isApproveModal}
          displayInput={true}
          reasonLabel={"Reason for change"}
          //comment={"Edit Origination"}
          comment={""}
        />
      )}
    </Drawer>
  );
};

export default ManifestEditDestinationModal;
