import { Button } from "antd";
import React from "react";
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, hasGlobalError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  componentDidMount() {
    window.onerror = (message, source, lineno, colno, error) => {
      this.setState({ hasGlobalError: true });
    };
  }

  render() {
    if (this.state.hasError || this.state.hasGlobalError) {
      return (
        <div style={{ textAlign: "center" }}>
          <h4>App have new version Or Some thing went wrong</h4>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}
