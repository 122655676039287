import React from "react";
import { Card, Row, Col, Flex, Image, Timeline, Typography } from "antd";
import Utils from "../../utils/utils";

const { Title } = Typography;

const ManifestConversationView = ({ conversation }) => {
  const conversationList = [];
  conversation.forEach((item) => {
    let created_date = Utils.formatDate(item.created_at);
    let created_time = Utils.formatTime(item.created_at);
    let logView = {
      // label: Utils.IsMobile ? null : (
      //   <>
      //     <div>{created_date}</div>
      //     <div>{created_time}</div>
      //   </>
      // ),
      children: (
        <>
          <div>
            {Utils.IsMobile ? (
              <div>
                <strong>{`${created_date} ${created_time}`}</strong>
              </div>
            ) : (
              <></>
            )}
            <div>
              <strong>{`${created_date} ${created_time}`} </strong>
            </div>
            <div>
              <strong>{`${item.name} - ${item.company_name} Company`}</strong>
            </div>
            <div>
              <span>{item.message}</span>
            </div>
          </div>
        </>
      ),
    };
    conversationList.push(logView);
  });

  return (
    <>
      {conversationList.length > 0 ? (
        <Card style={{ width: "100%", marginBottom: 16 }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Timeline items={conversationList} />
            </Col>
          </Row>
        </Card>
      ) : (
        <Card style={{ width: "100%", marginBottom: 16 }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4}>No Conversation</Title>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default ManifestConversationView;
