import { Flex, Form, Image, Input, Modal, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import Utils from "./../utils/utils";
import constants from "../utils/constants";

export default function EditLicenseeDetail({
  modal: [item, setModal],
  formAction,
  form,
  fileAction: [fileUploadList],
  loading,
  type,
}) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const external_token = localStorage.getItem("external_token");
  const companyId = localStorage.getItem("auth_company_id");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [licenseType, setLicenseType] = useState("");
  const [isDefault, setIsDefault] = useState(form.getFieldValue("is_default"));
  const { LICENSEE_TYPES } = constants;
  const updateFiles = () => {
    if (fileUploadList != null && fileUploadList.length > 0) {
      const mediaList = fileUploadList.map((item) => {
        return {
          uid: item.media_id,
          name: item.file_name,
          status: "done",
          url: `${Utils.ApiEndpoint}/media/${companyId}/read?fname=${item.file_name}&id=${item.media_id}&token=${external_token}`,
          response: {
            result: item,
          },
        };
      });
      return mediaList;
    }
    return [];
  };

  const loadLicenseTypes = () => {
    return LICENSEE_TYPES.map((x) => ({
      value: x.code,
      test: x.name,
      label: <Flex justify="space-between">{x.name} </Flex>,
    }));
  };

  const [licenseTypes, setLicenseTypes] = useState(loadLicenseTypes);

  const [fileList, setFileList] = useState(updateFiles);
  useEffect(() => {
    setFileList(updateFiles);
    let formData = form.getFieldsValue();
    let ltpye = type;
    if (!ltpye && formData) {
      setLicenseType(formData.licensee_type);
      ltpye = formData.licensee_type;
    } else {
      setLicenseType(ltpye);
    }
    debugger;
    if (ltpye === "Driver") {
      setLicenseTypes([
        ...licenseTypes,
        {
          value: "Driver",
          test: "Driver",
          label: <Flex justify="space-between">Driver</Flex>,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.open, type]);

  const onFinish = (formData) => {
    let newUploadFiles = [];
    fileList.forEach((nfile) => {
      let media = nfile?.response?.result;
      newUploadFiles.push(media);
    });

    let newFileList = new Map(newUploadFiles.map((i) => [i.media_id, i]));
    let currentFileList = new Map(fileUploadList.map((i) => [i.media_id, i]));

    let results = [];
    for (let [id, media] of currentFileList) {
      if (!newFileList.has(id)) {
        media.is_deleted = true;
        results.push(media);
      } else {
        results.push(media);
      }
    }

    for (let [id, media] of newFileList) {
      if (!currentFileList.has(id)) {
        results.push(media);
      }
    }

    formAction(formData, results);
  };

  const uploadProps = {
    name: "file",
    action: `${Utils.ApiEndpoint}/media/${companyId}/single_upload`,
    headers: {
      authorization: token,
    },
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    // );
  };
  const handleChange = ({ file, fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const dateFormat = Utils.formatDateFormView; //"MM/DD/YYYY"

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onSearch = (value) => {};

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => {
    // (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const label = (option?.test ?? "").toString();
    return label.includes(input.toLowerCase());
  };

  //TODO: set licence is default
  const onSwitchChange = (checked) => {
    setIsDefault(checked);
    form.setFieldsValue({ is_default: checked });
  };

  useEffect(() => {
    setIsDefault(form.getFieldValue("is_default"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("is_default")]);

  return (
    <>
      <Modal
        style={{ top: 10 }}
        {...item}
        maskClosable={false}
        onCancel={() => setModal({})}
        forceRender
        onOk={() => form.submit()}
        okButtonProps={{ loading: loading, icon: item.icon }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item name="licensee_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="license_number"
            label="License#"
            required
            rules={[{ required: true, message: "License number is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="issue_state"
            label="State Issued"
            required
            rules={[{ required: true, message: "State Issued is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="licensee_name"
            label="Licensee Name"
            required
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="licensee_type"
            label="License Type"
            required
            rules={[{ required: true, message: "License Type is required" }]}
          >
            <Select
              showSearch
              disabled={licenseType === "Driver"}
              placeholder="Select a licensee type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={licenseTypes}
            />
          </Form.Item>

          {(!licenseType || licenseType !== "Driver") && (
            <Form.Item
              name="is_default"
              label="License default"
              rules={[{ required: false, message: "" }]}
            >
              <Switch checked={isDefault} onChange={onSwitchChange} />
            </Form.Item>
          )}
          <Form.Item
            name="licensee_address"
            label="Licensee Address"
            required
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="licensee_phone"
            label="Licensee Phone"
            required
            rules={[{ required: true, message: "Phone is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="issued_date"
            label="Issued Date"
            required
            rules={[{ required: true, message: "Issued date is required" }]}
          >
            <DatePicker onChange={onChange} format={dateFormat} />
          </Form.Item>

          <Form.Item
            name="expired_date"
            label="Expired Date"
            required
            rules={[{ required: true, message: "Expired date is required" }]}
          >
            <DatePicker onChange={onChange} format={dateFormat} />
          </Form.Item>
          <Form.Item label="License Images">
            <Upload
              name="file"
              fileList={fileList}
              listType="picture-card"
              onPreview={handlePreview}
              onChange={handleChange}
              accept="image/*"
              // defaultFileList={[...fileList]}
              {...uploadProps}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}
