import { ProfileOutlined } from "@ant-design/icons";
import { Modal, Timeline } from "antd";
import React from "react";

const TestRequestModalView = ({
  title,
  items,
  extraItems,
  onOk,
  isOpen,
  width,
}) => {
  const handleOk = () => {
    onOk();
  };
  let data = [
    ...items.map((item, idx) => {
      return { children: item.name };
    }),
  ];
  if (extraItems && extraItems.length > 0) {
    data = [
      ...data,
      {
        dot: <ProfileOutlined style={{ fontSize: 16 }} />,
        color: "#e05050",
        children: <p>Individual Test(s)</p>,
      },
      ...extraItems.map((item, idx) => {
        return { children: item.name };
      }),
    ];
  }
  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={handleOk}
      width={width ?? 300}
      title={title ?? null}
      footer={null}
      maskClosable={false}
    >
      <div
        style={{
          padding: 10,
          maxHeight: "calc(100vh - 250px)",
          minHeight: "250px",
          overflowY: "auto",
        }}
      >
        <Timeline items={data} />
      </div>
      {/* <List bordered>
            {items.map((item,idx)=>{
                return <List.Item key={item.code}>{item.name}</List.Item>;
            })}
        </List> */}
    </Modal>
  );
};

export default TestRequestModalView;
