import React from "react";
import { Card, Row, Col, Flex, Image } from "antd";
import Utils from "../../utils/utils";

const ManifestRecceiptOrRejectEntityView = ({
  receipt_confirm,
  reject_disclaimer,
}) => {
  // TODO: get signature image
  const getSignatureByid = (signature_id) => {
    const urlSign1 = `${Utils.ApiEndpoint}/signature/${company_id}/read?id=${signature_id}&token=${external_token}`;
    return urlSign1;
  };

  // TODO: get signature image
  const external_token = localStorage.getItem("external_token");
  const company_id = localStorage.getItem("auth_company_id");

  return (
    <>
      <Card style={{ width: "100%", marginBottom: 16 }}>
        {receipt_confirm ? (
          <>
            {/* row 1 */}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <strong>
                  I attest that the contents of this shipment match the records
                  entered in Section 4 above, and I agree to take custody of
                  those portions of this shipment not circled above. Those
                  portions circled were returned to the individual delivering
                  this shipment.
                </strong>
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 2 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Signature and Date #1</strong>
              </Col>
              <Col span={18}>
                {/* {(receiptConfirmData && receiptConfirmData.length === 0) ||
                    (receiptConfirmData && receiptConfirmData.length > 0 && receiptConfirmData[0].signature_id_1 === "") &&
                    <Button onClick={() => handleApprove(1)} icon={<CheckOutlined />}>Sign Receipt confirmation 1</Button>
                  } */}

                {((reject_disclaimer &&
                  reject_disclaimer.length === 0 &&
                  receipt_confirm &&
                  receipt_confirm.length === 0) ||
                  (receipt_confirm &&
                    receipt_confirm.length > 0 &&
                    receipt_confirm[0].signature_id_1 === undefined)) && (
                  <span>N/A</span>
                )}

                {receipt_confirm &&
                  receipt_confirm.length > 0 &&
                  receipt_confirm[0].signed_date_1_on && (
                    <div>
                      {/* <div>{receiptConfirmData[0].signature_id_1} - {receiptConfirmData[0].signed_date_1_on}</div> */}
                      <Flex gap={10}>
                        <Flex justify="center" vertical>
                          {receipt_confirm[0].user_name_1}
                        </Flex>
                        {/* TODO: image */}
                        <Flex
                          style={{
                            height: 50,
                            width: 150,
                            //background: "#ccc",
                          }}
                          justify="center"
                        >
                          <Image
                            height={50}
                            src={getSignatureByid(
                              receipt_confirm[0].signature_id_1,
                            )}
                          />
                        </Flex>
                        {/* TODO: signature date */}
                        <Flex justify="center" vertical>
                          {Utils.formatDate(
                            receipt_confirm[0].signed_date_1_on,
                          )}
                        </Flex>
                      </Flex>
                    </div>
                  )}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 3 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Signature and Date #2</strong>
              </Col>
              <Col span={18}>
                {/* {(receiptConfirmData && receiptConfirmData.length == 0) || (receiptConfirmData && receiptConfirmData.length > 0 && receiptConfirmData[0].signature_id_2 === "") &&
                    <Button onClick={() => handleApprove(2)} icon={<CheckOutlined />}>Sign Receipt confirmation 2</Button>
                  } */}

                {((reject_disclaimer &&
                  reject_disclaimer.length === 0 &&
                  receipt_confirm &&
                  receipt_confirm.length === 0) ||
                  (receipt_confirm &&
                    receipt_confirm.length > 0 &&
                    receipt_confirm[0].signature_id_2 === undefined)) && (
                  <span>N/A</span>
                )}

                {receipt_confirm &&
                  receipt_confirm.length > 0 &&
                  receipt_confirm[0].signed_date_2_on && (
                    <div>
                      {/* {receiptConfirmData[0].signature_id_2} - {receiptConfirmData[0].signed_date_2_on} */}
                      <Flex gap={10}>
                        <Flex justify="center" vertical>
                          {receipt_confirm[0].user_name_2}
                        </Flex>
                        {/* TODO: image */}
                        <Flex
                          style={{
                            height: 50,
                            width: 150,
                            //background: "#ccc",
                          }}
                          justify="center"
                        >
                          <Image
                            height={50}
                            src={getSignatureByid(
                              receipt_confirm[0].signature_id_2,
                            )}
                          />
                        </Flex>
                        {/* TODO: signature date */}
                        <Flex justify="center" vertical>
                          {Utils.formatDate(
                            receipt_confirm[0].signed_date_2_on,
                          )}
                        </Flex>
                      </Flex>
                    </div>
                  )}
              </Col>
            </Row>
            <hr style={{ opacity: "10%" }} />
          </>
        ) : (
          <p>No destination details data</p>
        )}
      </Card>

      <Card style={{ width: "100%" }}>
        {reject_disclaimer ? (
          <>
            {/* row 1 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>
                  Name of Person Taking Receipt of Rejected Portion of this
                  Shipment:
                </strong>
              </Col>
              <Col span={6}>
                {reject_disclaimer &&
                  reject_disclaimer.length > 0 &&
                  reject_disclaimer[0].user_id && (
                    <div>{reject_disclaimer[0].name}</div>
                  )}
              </Col>
              <Col span={6}>
                <strong>Signature and Date:</strong>
              </Col>
              <Col span={6}>
                {reject_disclaimer &&
                reject_disclaimer.length > 0 &&
                reject_disclaimer[0].signed_date_on ? (
                  <div>
                    {/* {rejectDisclaimerData[0].signature_id} - {rejectDisclaimerData[0].signed_date_on} */}
                    <Flex gap={10}>
                      <Flex justify="center" vertical>
                        {reject_disclaimer[0].name}
                      </Flex>
                      {/* TODO: image */}
                      <Flex
                        style={{
                          height: 50,
                          width: 150,
                          //background: "#ccc",
                        }}
                        justify="center"
                      >
                        <Image
                          height={50}
                          src={getSignatureByid(
                            reject_disclaimer[0].signature_id,
                          )}
                        />
                      </Flex>
                      {/* TODO: signature date */}
                      <Flex justify="center" vertical>
                        {Utils.formatDate(reject_disclaimer[0].signed_date_on)}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  <span>N/A</span>
                )}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
          </>
        ) : (
          <p>No destination details data</p>
        )}
      </Card>
    </>
  );
};

export default ManifestRecceiptOrRejectEntityView;
