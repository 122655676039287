import axios from "../configs";

export class ManifestAPI {
  constructor() {
    this.companyId = localStorage.getItem("auth_company_id");
    if (!this.companyId) {
      this.companyId = "common";
    }
    this.token = localStorage.getItem("auth_token");
    this.auth_token_type = localStorage.getItem("auth_token_type");
    this.headers = {
      Authorization: `Bearer ${this.token}`,
      "X-Company-Id": this.companyId,
    };
  }
  async getManifestById(data) {
    return await axios.get(`/manifest/${this.companyId}/manifest-by-id`, {
      headers: this.headers,
      params: data != null ? { ...data } : {},
    });
  }
  async editOrigination(data) {
    return await axios.post(
      `/manifest/${this.companyId}/edit-origination`,
      data,
      {
        headers: this.headers,
      },
    );
  }
  async editTransport(data) {
    debugger;
    return await axios.post(
      `/manifest/${this.companyId}/edit-transport`,
      data,
      {
        headers: this.headers,
      },
    );
  }
  async editDestination(data) {
    return await axios.post(
      `/manifest/${this.companyId}/edit-destination`,
      data,
      {
        headers: this.headers,
      },
    );
  }
  async editProduct(data) {
    return await axios.post(`/manifest/${this.companyId}/edit-product`, data, {
      headers: this.headers,
    });
  }

  async getOriginationConfig() {
    return await axios.get(`/manifest/${this.companyId}/config`, {
      headers: this.headers,
    });
  }

  async addManifestProduct(data) {
    return await axios.post(
      `/manifest/${this.companyId}/add-manifest-product`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  async removeManifestProduct(data) {
    return await axios.post(
      `/manifest/${this.companyId}/remove-manifest-product`,
      data,
      {
        headers: this.headers,
      },
    );
  }
}
