import { Tag } from "antd";
import AppStatus from "../utils/app_status";
import constants from "../utils/constants";

export const getStatus = (value) => {
  if (value === AppStatus.NEW) return <Tag>NEW</Tag>;
  if (value === AppStatus.WAITING_FOR_APPROVED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        WAITING FOR APPROVAL
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_READY_FOR_PICKUP)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="blue">
        TRANSPORT READY FOR PICKUP
      </Tag>
    );
  if (value === AppStatus.ACKNOWLEDGED)
    return <Tag color="blue">ACKNOWLEDGED</Tag>;
  if (value === AppStatus.VEHICLE_ASSIGNED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        VEHICLE AND DRIVER ASSIGNED
      </Tag>
    );
  if (value === AppStatus.VEHICLE_WAITING_ACCEPTED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="orange">
        WAITING SECOND DRIVER ACCEPTED
      </Tag>
    );
  if (value === AppStatus.VEHICLE_ACCEPTED)
    return <Tag color="green">DRIVER ACCEPTED</Tag>;
  if (value === AppStatus.TRANSPORT_PICKINGUP)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        TRANSPORT PICKINGUP
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_IN_PROGRESS)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        TRANSPORT INPROGRESS
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_DECLINED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="red">
        TRANSPORT DECLINED
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_DELIVERED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        TRANSPORT ARRIVED
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_SAMPLE_COLLECTION)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        SAMPLE COLLECTION
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_SAMPLE_COLLECTION_FINISHED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        SAMPLE COLLECTION FINISHED
      </Tag>
    );
  if (value === AppStatus.TRANSPORT_TRANSFERING)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        TRANSPORT TRANSFERING
      </Tag>
    );
  if (value === AppStatus.ACKNOWLEDGE_DELIVERED)
    return <Tag color="green">DELIVERED</Tag>;
  if (value === AppStatus.TRANSPORT_SAMPLE_ACCEPTED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        SAMPLE COLLECTION ACCEPTED
      </Tag>
    );
  if (value === AppStatus.RECEIVER_ACCEPTED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        RECEIVER ACCEPTED
      </Tag>
    );
  if (value === AppStatus.RECEIVER_REJECTED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="red">
        RECEIVER REJECTED
      </Tag>
    );
  if (value === AppStatus.ORDER_COMPLETED)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="green">
        COMPLETED
      </Tag>
    );
  if (value === AppStatus.ARCHIVE)
    return (
      <Tag style={{ width: "auto", whiteSpace: "normal" }} color="red">
        ARCHIVE
      </Tag>
    );
  else return <></>;
};

const { ORDER_TYPE_LIST } = constants;
export const getOrderTypeStatus = (value) => {
  const idx = ORDER_TYPE_LIST.findIndex((x) => x.value === value);
  if (idx !== -1) {
    return (
      <Tag color={ORDER_TYPE_LIST[idx].color}>{ORDER_TYPE_LIST[idx].label}</Tag>
    );
  } else {
    return <Tag>Other</Tag>;
  }
};
