import React from "react";
import "leaflet/dist/leaflet.css";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Input,
  InputNumber,
  Row,
  Space,
} from "antd";
import { Form } from "antd";
// import ModalTakePicture from './ModalTakePicture';
import { Guid } from "js-guid";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../invoices.css";
import axios from "../../../configs";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ClientInput from "./ClientInput";
import { generateInvoiceNumber } from "../invoiceUtils";
const ModalRevenue = ({ onOk, onCancel, isOpen, item }) => {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");

  const [isLoading, setIsLoading] = React.useState(false);
  const [workingItem, setWorkingItem] = React.useState(null);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    updateData(values);
  };
  const onFinishFailed = () => {};
  const [hasEdited, setHasEdited] = React.useState(false);
  const updateData = async (sampleData) => {
    let data = {
      ...sampleData,
      id: sampleData?.revenue_id
        ? sampleData?.revenue_id
        : Guid.newGuid().toString(),
    };

    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await axios
        .post(`/bills/${company_id}/revenue/add`, data, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setIsLoading(false);

          toast.success(response.data.result);
          onOk(response);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  React.useEffect(() => {
    let model = { ...item };
    model.value_date = dayjs(item.value_date);
    model.due_date = dayjs(item.due_date);
    model.title_code = item.revenue_number;
    model.lines = item.lines?.data;
    if (!item.revenue_number) {
      model.title_code = generateInvoiceNumber("INV");
    }
    setWorkingItem(model);
    form.setFieldsValue(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  React.useEffect(() => {
    const fetchData = async (value) => {
      try {
        setIsLoading(true);
        await axios
          .get(`/accounting/${company_id}/common/company-input`, {
            headers: { Authorization: token },
          })
          .then((response) => {
            let data = response.data.result;
            if (data) {
              form.setFieldsValue({
                business: {
                  from_id: data.invoice_info_id,
                  from_name: data.invoice_company_name,
                  from_email: data.invoice_email,
                  from_address: data.invoice_address,
                },
              });
            }

            setIsLoading(false);
          });
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotal = (items, taxPercent, discountPercent) => {
    const subtotal = items.reduce((sum, item) => {
      if (item) {
        const quantity = item.quantity || 0;
        const price = item.price || 0;
        return sum + quantity * price;
      }
      return sum;
    }, 0);
    const tax = (taxPercent / 100) * subtotal;
    const discount = (discountPercent / 100) * subtotal;
    const total = subtotal + tax - discount;
    form.setFieldsValue({
      base_amount: subtotal,
      total_amount: total,
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    setHasEdited(true);
    if (
      "lines" in changedValues ||
      "discount_value_amount" in changedValues ||
      "tax_amount" in changedValues
    ) {
      calculateTotal(
        allValues.lines || [],
        allValues.tax_amount || 0,
        allValues.discount_value_amount || 0,
      );
    }
  };

  return (
    <Drawer
      title=""
      placement={"right"}
      open={isOpen}
      onClose={onCancel}
      maskClosable={false}
      one
      width={"100%"}
      footer={
        <Space style={{ display: "flex", justifyContent: "right" }}>
          <Button
            disabled={!hasEdited}
            size={"large"}
            loading={isLoading}
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Confirm
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={workingItem}
      >
        <div style={{ padding: "0" }} className="invoice">
          <h5 className="title">Details</h5>
          <Card bordered={false}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="revenue_id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="title_code" label="Invoice Number">
                  <Input placeholder="Input Invoice Number" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="value_date" label="Issue Date">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="due_date" label="Due Date">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <h5 className="title">Bussiness Information</h5>

          <Row gutter={16}>
            <Col span={12}>
              <Card bordered={false}>
                <Form.Item name={["business", "from_id"]} hidden>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={["business", "from_name"]}
                  label="From Company"
                >
                  <Input placeholder="Input Company Name" />
                </Form.Item>
                <Form.Item name={["business", "from_email"]} label="Email">
                  <Input placeholder="Input Company Email" />
                </Form.Item>
                <Form.Item
                  name={["business", "from_address"]}
                  label="Company Address"
                >
                  <Input placeholder="Input Company address" />
                </Form.Item>
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <Form.Item name={["business", "to_name"]} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={["business", "to_id"]} label="To Company">
                  <ClientInput
                    onDataChange={(record) => {
                      console.log(record);
                      form.setFieldsValue({
                        business: {
                          to_name: record.name,
                          to_email: record.email,
                          to_address: record.address,
                        },
                      });
                    }}
                    placeholder="Input Company Name"
                  ></ClientInput>
                </Form.Item>
                <Form.Item name={["business", "to_email"]} label="Email">
                  <Input placeholder="Input Company Email" />
                </Form.Item>
                <Form.Item
                  name={["business", "to_address"]}
                  label="Client Address"
                >
                  <Input placeholder="Input client address" />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <h5 className="title">Item Detail</h5>
          <Card bordered={false}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.List name="lines">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Row key={field.key} gutter={8}>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, "item"]}
                              fieldKey={[field.fieldKey, "item"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing item name",
                                },
                              ]}
                            >
                              <Input placeholder="Item Name" />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, "quantity"]}
                              fieldKey={[field.fieldKey, "quantity"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing quantity",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Quantity"
                                step="1"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, "price"]}
                              fieldKey={[field.fieldKey, "price"]}
                              rules={[
                                { required: true, message: "Missing price" },
                              ]}
                            >
                              <InputNumber
                                placeholder="Price (USD)"
                                step="0.01"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={2}
                            style={{
                              width: "40%",
                            }}
                          >
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Item
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Card>

          <h5 className="title">Summary</h5>
          <Card bordered={false}>
            <Row gutter={16}>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                Subtotal (USD)
              </Col>
              <Col span={12}>
                <Form.Item name="base_amount">
                  <InputNumber
                    placeholder="Subtotal (USD)"
                    step="0.01"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                Tax (%)
              </Col>
              <Col span={12}>
                <Form.Item name="tax_amount">
                  <InputNumber
                    placeholder="Tax (%)"
                    step="0.01"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                Discount (%)
              </Col>
              <Col span={12}>
                <Form.Item name="discount_value_amount">
                  <InputNumber
                    placeholder="Discount (%)"
                    step="0.01"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                Total (USD)
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Missing item name",
                    },
                  ]}
                  name="total_amount"
                >
                  <InputNumber
                    placeholder="Total (USD)"
                    step="0.01"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </div>
      </Form>
    </Drawer>
  );
};

export default ModalRevenue;
