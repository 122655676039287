import React, { useState } from "react";
import { Select } from "antd";
import axios from "../../../configs";

const ClientInput = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");
  const { value, onChange, onDataChange } = props;
  const [statusFilter, setStatusFilter] = useState({
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
    },
    search: "",
    status: "",
    sort: "",
  });

  let timeout;
  let currentValue;
  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    const fetchSummary = async () => {
      try {
        setIsLoading(true);
        await axios
          .get(`/accounting/${company_id}/common/client-input`, {
            params: {
              page: statusFilter.pagination?.current,
              per_page: statusFilter.pagination?.pageSize,
              sort: statusFilter.sort,
              search: currentValue,
            },
            headers: { Authorization: token },
          })
          .then((response) => {
            let rows = response.data.result.rows;

            if (currentValue === value) {
              let datas = [];
              rows.forEach((item) => {
                datas.push({
                  ...item,
                  value: item.client_id,
                  text: item.name,
                });
              });
              callback(datas);
            }
            setIsLoading(false);
          });
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    };

    if (value) {
      timeout = setTimeout(fetchSummary, 300);
    } else {
      callback([]);
    }
  };

  const fetchData = async (value) => {
    try {
      setIsLoading(true);
      await axios
        .get(`/accounting/${company_id}/common/client-input`, {
          params: {
            page: statusFilter.pagination?.current,
            per_page: statusFilter.pagination?.pageSize,
            sort: statusFilter.sort,
            search: "",
            value: value,
          },
          headers: { Authorization: token },
        })
        .then((response) => {
          currentValue = value;
          let rows = response.data.result.rows;

          let datas = [];
          rows.forEach((item) => {
            // if (item.client_id === value) {
            //   if (onChange) {
            //     onChange(item.name);
            //   }
            // }
            datas.push({
              ...item,
              value: item.client_id,
              text: item.name,
            });
          });
          setData(datas);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (selectValue !== value || value === undefined) {
      fetchData(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };
  const handleChange = (newValue) => {
    // setValue(newValue)
    if (onDataChange) {
      let dataChange = data.find((t) => t.value === newValue);
      onDataChange(dataChange);
    }
    if (onChange) {
      setSelectValue(newValue);
      onChange(newValue);
    }
  };
  return (
    <Select
      loading={isLoading}
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      // notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};

export default ClientInput;
