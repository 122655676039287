import React, { useEffect, useState } from "react";
import { Button, Card, Col, Drawer, Form, Input, Row, Space } from "antd";
import Utils from "../../../utils/utils";
import axios from "../../../configs";
import { toast } from "react-toastify";
import InvoiceReportComponent from "./InvoiceReportComponent";
const ModalSendInvoice = ({ onOk, onEdit, onCancel, isOpen, item }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");

  useEffect(() => {
    if (item) {
      const subj = `${item.expense_number || item.revenue_number} due ${Utils.formatDate(item.due_date)}`;
      form.setFieldsValue({
        email_to: item.business?.to_email,
        email_reply: item.business?.from_email,
        subject: subj,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  const handleSend = () => {
    form.submit();
  };
  // send email invoice
  const sendEmailInvoice = async (formData) => {
    let id = `invoice-${item.revenue_number || item.expense_number}`;
    let element = document.getElementById(id);
    let content = element.innerHTML;
    debugger;
    const dataAdd = {
      id: item.revenue_id || item.expense_id,
      from_email: formData.email_reply,
      to_email: formData.email_to,
      subject: formData.subject,
      content: content,
    };

    setLoading(true);
    await axios
      .post(`/invoice/${company_id}/send-invoice`, dataAdd, {
        headers: { Authorization: token },
      })
      .then((response) => {
        debugger;

        toast.success(response.data.detail);
        setLoading(false);
        // setModalAdd({}); // close modal
        //onCancel = true;
        onOk(response.data.result);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.detail);
        setLoading(false);
      });
  };

  //TODO: Edit invoice
  const editInvoiceAction = async () => {
    onEdit(item);
  };
  //--------------

  return (
    <>
      <Drawer
        title=""
        placement={"right"}
        open={isOpen}
        onClose={onCancel}
        maskClosable={false}
        one
        width={"100%"}
        footer={
          <Space style={{ display: "flex", justifyContent: "right" }}>
            <Button
              size={"large"}
              loading={loading}
              onClick={() => {
                editInvoiceAction();
              }}
            >
              Edit Invoice
            </Button>
            <Button
              size={"large"}
              loading={loading}
              type="primary"
              onClick={() => {
                handleSend();
              }}
            >
              Send Invoice
            </Button>
          </Space>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Card bordered={false}>
              <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={sendEmailInvoice}
              >
                <Form.Item
                  name="email_to"
                  label="To"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email_reply"
                  label="Reply to"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="subject"
                  label="Subject"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={14}>
            <Card title={null} bordered={false} style={{ minHeight: "70vh" }}>
              <Row align="top" justify={"center"}>
                <InvoiceReportComponent item={item}></InvoiceReportComponent>
              </Row>
            </Card>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
export default ModalSendInvoice;
