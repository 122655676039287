import axios from "../../configs";

export class DestinationAPI {
  constructor() {
    this.companyId = localStorage.getItem("auth_company_id");
    if (!this.companyId) {
      this.companyId = "common";
    }
    this.token = localStorage.getItem("auth_token");
    this.auth_token_type = localStorage.getItem("auth_token_type");
    this.headers = {
      Authorization: `Bearer ${this.token}`,
      "X-Company-Id": this.companyId,
    };
  }

  async getOrderById(transport_order_id) {
    return await axios.get(
      `/transportation/${this.companyId}/get-company-origination-by-transport-order-id`,
      {
        headers: this.headers,
        params: { transport_order_id: transport_order_id },
      },
    );
  }

  async getAllOrders(page, searchKeyword) {
    return await axios.get(
      `/transportation/${this.companyId}/get-all-transportation-order-for-table?page=${page}&search=${searchKeyword}`,
      {
        headers: this.headers,
      },
    );
  }

  async destinationOrderApprove(data) {
    if (
      data &&
      (data.position_signature === 1 || data.position_signature === 2)
    ) {
      return await axios.post(
        `/destination/${this.companyId}/destination-order-approve-signature`,
        data,
        {
          headers: this.headers,
        },
      );
    } else {
      //
      return await axios.post(
        `/destination/${this.companyId}/destination-order-reject-signature`,
        data,
        {
          headers: this.headers,
        },
      );
    }
  }

  async profileCompleteSignature(data) {
    return await axios.post(
      `/profile/signature-for-term-of-use-complete-profile-by-user-id`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  async getDestinationCheckList() {
    return await axios.get(
      `/destination/${this.companyId}/get-destination-checklist`,
      {
        headers: this.headers,
      },
    );
  }

  async destinationOrderComplete(data) {
    return await axios.post(
      `/destination/${this.companyId}/destination-order-complete`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  async destinationOrderArchive(data) {
    return await axios.post(
      `/archive/${this.companyId}/destination-order-archive`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  async destinationOrderUnArchive(data) {
    return await axios.post(
      `/archive/${this.companyId}/destination-order-unarchive`,
      data,
      {
        headers: this.headers,
      },
    );
  }
}
