import React, { useState } from "react";
import { useAuth } from "../utils/auth";
import { useAppStore } from "../utils/appstate";
import Utils from "../utils/utils";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { API } from "./../configs";
import { useIdleTimer } from "react-idle-timer/legacy";
import {
  CopyrightCircleOutlined,
  FlagOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  Popover,
  Space,
  Divider,
  Drawer,
  Modal,
  Spin,
  Flex,
} from "antd";
import { Layout, Menu, theme } from "antd";
import TermsAndPolicyModal from "./TermsAndPolicyModal";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";

const { defaultAlgorithm, darkAlgorithm } = theme;
const { Header, Content, Footer, Sider } = Layout;

function MainLayout({ children }) {
  const authContext = useAuth();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const appInfo = useAppStore((state) => state.AppInfo);
  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const [menus, setMenus] = useState([]);
  const maxNested = 5;
  const [width, setWidth] = React.useState(window.innerWidth);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [modalPolicyTerms, setModalPolicyTerms] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [visible, setVisible] = useState(false);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  const logout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_token_type");
    localStorage.removeItem("external_token");
    localStorage.removeItem("auth_company_id");
    toast.success("You have been logged out");
    navigate("/login");
  };

  let user = Utils.getUserInfo();

  function buildCurrentSection(sections, rootSection, currentMaxNested) {
    if (!rootSection.id) {
      return;
    }

    currentMaxNested += 1;
    if (currentMaxNested > maxNested) {
      return rootSection;
    }

    let childrenOfRoot = sections.filter(
      (t) => t.parent_menu_id === rootSection.id && t.menu_type === 0,
    );
    if (childrenOfRoot.length === 0) {
      return rootSection;
    }
    childrenOfRoot.forEach((child, index) => {
      child.key = child.id;
      child.label = child.menu_name;
      buildCurrentSection(sections, child, currentMaxNested);
    });
    childrenOfRoot.sort((a, b) => {
      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    });
    rootSection.children = childrenOfRoot;
  }

  const acceptNotification = () => {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          try {
            getToken(messaging, {
              vapidKey:
                "BKW4S2GV-9ejdC1rjRWocvcFn9yQv1hKiRDjkgOYKiQ5hchAtxmkZBAnbGquwSE-RbEkqp0TNIXqHbX2mk3yMHw",
            })
              .then((token) => {
                let noti_token = localStorage.getItem("noti_token");
                if (noti_token !== token) {
                  const device_id = Utils.getDeviceId();
                  const userAgent = window.navigator.userAgent;
                  const now = new Date();
                  let data = {
                    name: userAgent,
                    device_id: device_id,
                    token: token,
                    timezone: now.getTimezoneOffset(),
                  };
                  API.profile.updateDeviceToken(data).then(
                    () => {},
                    (error) => {},
                  );
                  localStorage.setItem("noti_token", token);
                }
              })
              .catch((err) => {
                console.log("error", err);
              });
          } catch (error) {}
        } else {
          const deniedCount = Number(
            localStorage.getItem("deniedCount") || "0",
          );
          localStorage.setItem("deniedCount", (deniedCount + 1).toString());
        }

        setVisible(false);
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    try {
      const deniedCount = Number(localStorage.getItem("deniedCount") || "0");
      if (
        Notification.permission !== "denied" &&
        Notification.permission !== "granted" &&
        deniedCount < 2
      ) {
        if (isIOS) {
          setVisible(true);
        } else {
          acceptNotification();
        }
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cancelNotification = () => {
    setVisible(false);
    const deniedCount = Number(localStorage.getItem("deniedCount") || "0");
    localStorage.setItem("deniedCount", (deniedCount + 1).toString());
  };

  const onAction = (event, idleTimer) => {
    // if (idleTimer.isPrompted()) {
    //   idleTimer.activate()
    // }
  };

  const onActive = (event, idleTimer) => {
    // if (idleTimer.isPrompted()) {
    //   setOpen(false)
    // }
  };

  const onPrompt = () => {
    // setOpen(true)
  };

  const onIdle = () => {
    let onTransport = localStorage.getItem("_on_transport_tracking");
    if (onTransport !== "yes") {
      logout();
      toast.success("You have been logged out");
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 20,
    promptBeforeIdle: 0, //1000 * 30,
    onAction,
    onActive,
    onPrompt,
    onIdle,
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    const checkLoginDuration = () => {
      const is_driver = localStorage.getItem("is_driver");
      if (is_driver === "1") {
        return;
      }
      const lastUnloadTime = Number(localStorage.getItem("lastUnloadTime"));
      const currentTime = Date.now();
      const timeDifference = currentTime - lastUnloadTime;

      if (!isIOS && timeDifference > 180000) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_token_type");
        localStorage.removeItem("external_token");
        localStorage.removeItem("auth_company_id");
      } else {
        const lastUnloadTime = Date.now();
        localStorage.setItem("lastUnloadTime", lastUnloadTime.toString());
      }
    };

    checkLoginDuration();
  }, []);

  const isMobile = width <= 768;

  Utils.IsMobile = isMobile;

  React.useEffect(() => {
    // if (!authContext.currentUser) {
    //   logout();
    // }
    const getMenus = async () => {
      // axios.get(`/users/get-menu-permission`, {
      //      headers: { Authorization: Utils.getToken() },
      //    })
      if (path === "/administrator/company-migration") {
        navigate(path);
        return;
      }
      setLoading(true);
      API.menu
        .getMenu()
        .then((response) => {
          setLoading(false);
          if (response.data.result.length === 0) navigate("/login");
          setMenus(response.data.result);
          //my profile

          const { user_info } = authContext.currentUser;
          const external_token = localStorage.getItem("external_token");
          if (user_info.avatar_id && user_info.avatar_id !== "") {
            user_info.avatar = `${Utils.ApiEndpoint}/media/${user_info.company_id}/avatar/${user_info.id}?token=${external_token}&aid=${user_info.avatar_id}`;
          } else {
            user_info.avatar = "";
          }

          let menus = response.data.result;

          let url = response.data.result.find((x) => x.menu_url === path);
          let is_driver = response.data.result.find(
            (x) => x.menu_code === "my_transport",
          );

          let newInfo = { ...appInfo };
          newInfo.isDriver = false;

          if (is_driver) {
            newInfo.isDriver = true;
            setIsDriver(true);
            localStorage.setItem("is_driver", "1");
          } else {
            localStorage.setItem("is_driver", "0");
          }

          let rootMenus = menus.filter(
            (t) => t.parent_menu_id === "" && t.menu_type === 0,
          );
          let actions = menus.filter((t) => t.menu_type !== 0);
          newInfo.Actions = actions;
          rootMenus.sort((a, b) => {
            if (a.sort < b.sort) {
              return -1;
            }
            if (a.sort > b.sort) {
              return 1;
            }
            return 0;
          });
          rootMenus.forEach((node) => {
            let curNested = 0;
            node.key = node.id;
            node.label = node.menu_name;
            buildCurrentSection(menus, node, curNested);
          });

          newInfo.user = user;
          newInfo.Menus = rootMenus;

          newInfo.IsReady = true;

          updateAppInfo(newInfo);
          // authContext.updateCurrentUser(user_info);
          setUserInfo(user_info);
          if (user_info && user_info.is_complete_profile === false) {
            navigate("/myaccount/my-profile");
            return;
          }

          if (!url) {
            if (is_driver) {
              url = is_driver;
              navigate(url.menu_url);
            } else {
              url = home_menu;
              navigate("/home");
            }
          }
          setCurrent(url);
          toast.success(response.data.result);
        })
        .catch((error) => {
          //  console.error(error);
        })
        .finally(() => setLoading(false));
    };

    getMenus();
    setDark(false);
  }, [authContext.currentUser]);

  const onClick = (e) => {
    let navMenu = menus.find((t) => t.id === e.key);
    navigate(navMenu.menu_url);
    setCurrent(navMenu);
    setCollapsed(false);
  };

  const home_menu = {
    id: "ac3d8ce1-3f47-49b7-91b7-5d7ad3b9ce47",
    menu_name: "Home",
    parent_menu_id: "",
    menu_code: "home",
    menu_url: "/home",
    sort: 0,
  };
  const [current, setCurrent] = useState(home_menu);

  const onSubmitHandlerLogout = async (event) => {
    let user = Utils.parseJwt(Utils.getToken());
    //event.preventDefault();
    let userData = {
      username: user.username,
      password: "",
    };
    setOpenProfileMenu(false);
    await axios
      .post("/auth/logout", userData)
      .then((response) => {
        // Save token to local storage
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_token_type");
        localStorage.removeItem("external_token");
        localStorage.removeItem("auth_company_id");
        toast.success("You have been logged out");
        navigate("/login");
      })
      .catch((error) => {
        // add error notif
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_token_type");
        localStorage.removeItem("external_token");
        localStorage.removeItem("auth_company_id");
        toast.success("You have been logged out");
        navigate("/login");
        toast.error(error.response.data.detail);
      });
  };

  const [dark, setDark] = useState(false);
  const { token } = theme.useToken();
  // const toggleTheme = () => {
  //   setDark(!dark);
  // };
  const onClose = () => {
    setCollapsed(false);
  };

  //TODO: view policy and terms of use
  const handleViewPolicyTerms = () => {
    setModalPolicyTerms(true);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: dark ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <Modal
        title="Enable Push Notifications"
        open={visible}
        onOk={acceptNotification}
        onCancel={cancelNotification}
      >
        <p>
          We'd like to send you push notifications. Would you like to enable
          them?
        </p>
      </Modal>
      <Layout className="layout" style={{ height: "100vh" }}>
        <Header
          style={{
            display: "flex",
            flexFlow: "row",
            padding: "0px",
            justifyContent: "space-between",
            background: dark ? "#000" : "#FFF",
            color: token.colorPrimaryText,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <img
              src="/static/logo_word.png"
              loading="lazy"
              alt=""
              style={{ height: "50px", borderRadius: 4 }}
            />
          </div>
          <div style={{ marginRight: "2rem" }}>
            <Popover
              open={openProfileMenu}
              trigger="click"
              onOpenChange={(newOpen) => {
                setOpenProfileMenu(newOpen);
              }}
              placement="bottomRight"
              content={
                <Space direction="vertical">
                  <Button
                    onClick={() => {
                      navigate("/myaccount/my-profile");
                      setOpenProfileMenu(false);
                    }}
                    type="text"
                    icon={<UserSwitchOutlined />}
                  >
                    My Profile
                  </Button>
                  {/* <Button onClick={toggleTheme} type="text" icon={<BlockOutlined />}>  Switch Theme</Button> */}
                  <Button
                    onClick={onSubmitHandlerLogout}
                    type="text"
                    icon={<LogoutOutlined />}
                  >
                    Logout
                  </Button>
                </Space>
              }
            >
              {userInfo && userInfo.avatar ? (
                <Avatar src={<img src={userInfo.avatar} alt="avatar" />} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
            </Popover>
          </div>
        </Header>
        <Layout>
          {Utils.IsMobile ? (
            <Drawer
              title=""
              placement={"left"}
              width={300}
              open={collapsed === true}
              onClose={onClose}
            >
              <Menu
                mode="inline"
                style={{
                  minWidth: 0,
                  flex: "auto",
                  maxHeight: "calc(100vh - 120px)",
                  overflow: "auto",
                }}
                onClick={onClick}
                inlineIndent={3}
                selectedKeys={[current.id]}
                items={appInfo.Menus}
              />
            </Drawer>
          ) : (
            <Sider
              collapsedWidth={80}
              trigger={null}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              theme={dark ? "dark" : "light"}
              width={200}
            >
              <Menu
                mode="inline"
                style={{
                  minWidth: 0,
                  flex: "auto",
                  maxHeight: "calc(100vh - 120px)",
                  overflow: "auto",
                }}
                onClick={onClick}
                inlineIndent={3}
                selectedKeys={[current.id]}
                items={appInfo.Menus}
              />
            </Sider>
          )}
          <Layout>
            <Content className="site-layout-content">
              {loading ? (
                <Flex
                  justify="center"
                  align="center"
                  style={{
                    height: "100vh",
                    background: "rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Spin />
                </Flex>
              ) : children ? (
                children
              ) : authContext.currentUser ? (
                <Outlet />
              ) : (
                <></>
              )}
            </Content>
          </Layout>
        </Layout>
        {Utils.IsMobile ? (
          <Footer
            style={{
              padding: "10px",
              background: "#FFF",
              borderRadius: "20px 20px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ height: "60px" }}
                  icon={<HomeOutlined />}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <div>Home</div>
                </Button>
              </div>
              {isDriver ? (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="text"
                    style={{ height: "60px" }}
                    icon={<FlagOutlined />}
                    onClick={() => {
                      navigate("/transportation/my-transport?status=ongoing");
                    }}
                  >
                    <div>Tasks</div>
                  </Button>
                </div>
              ) : (
                <></>
              )}

              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ height: "60px" }}
                  icon={<UserOutlined />}
                  onClick={() => {
                    navigate("/myaccount/my-profile");
                  }}
                >
                  <div>My Profile</div>
                </Button>
              </div>
            </div>
          </Footer>
        ) : (
          <Footer style={{ textAlign: "center", padding: 8 }}>
            <Divider style={{ width: "100%", margin: "5px 0" }}></Divider>
            <a
              href="#/"
              onClick={() => {
                handleViewPolicyTerms();
              }}
            >
              <span style={{ marginRight: "20px" }}>Privacy & terms</span>
            </a>
            <a href="#/">
              Powered by Phamiliar Technologies LLC <CopyrightCircleOutlined />{" "}
              2024
            </a>

            {modalPolicyTerms && (
              <TermsAndPolicyModal
                open={modalPolicyTerms}
                onCancel={() => {
                  setModalPolicyTerms(false);
                }}
              />
            )}
          </Footer>
        )}
      </Layout>
    </ConfigProvider>
  );
}

export default MainLayout;
