import React from "react";
import { Modal } from "antd";

const ViewTermsModal = ({ open, onCancel }) => {
  return (
    <Modal
      title="Terms of Use"
      centered
      open={open}
      onCancel={onCancel}
      width={1200}
      footer={null}
    >
      <embed
        id="ifameviewPdf"
        type="application/pdf"
        title="Terms of Use"
        src="/static/term_policy/terms-of-service_2024.pdf"
        width="100%"
        height="800px"
      />
    </Modal>
  );
};

export default ViewTermsModal;
