import { Flex } from "antd";
import Description from "../components/Description";

export default function About() {
  const data = {
    "System Name": "Transport by aClassiA",
    Version: "1.0.0.1000",
    "Release Date": "08/08/2024",
    "Troubleshoot Information": "thuan@aclassia.com",
  };
  return (
    <Flex justify="center">
      <div
        style={{
          width: 500,
          gap: 16,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          width={200}
          src="/static/logo_word.png"
          loading="lazy"
          alt="logo"
        />
        <Description data={data} column={1} />
      </div>
    </Flex>
  );
}
