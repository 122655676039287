// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFBoQ_dc19gRibPluBNCfhvCtB2UoHbGc",
  authDomain: "aclassia.firebaseapp.com",
  projectId: "aclassia",
  storageBucket: "aclassia.appspot.com",
  messagingSenderId: "373184813681",
  appId: "1:373184813681:web:41c2f40a5d8cf2cd8c99d0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export { messaging };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("payload", payload);
      resolve(payload);
    });
  });
