import { Guid } from "js-guid";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

var Utils = {
  ApiEndpoint: window.location.host.startsWith("localhost")
    ? "http://localhost:4544"
    : `https://${window.location.host}`,
  SocketEndpoint: window.location.host.startsWith("localhost")
    ? "ws://localhost:4544"
    : `wss://${window.location.host}`,
  formatDateView: "MM-DD-YYYY",
  formatTimeView: "HH:mm",
  formatDatetimeView: "MM-DD-YYYY HH:mm",
  formatDateFormView: "MM/DD/YYYY",
  formatDatetimeDB: "YYYY-MM-DD HH:mm:ss",

  isNumeric: (value) => {
    return /^-?\d+$/.test(value);
  },
  formatMoney: (value, type) => {
    if (!value) value = 0;
    let formatter = new Intl.NumberFormat("en-US", {
      style: type === "decimal" ? "decimal" : "currency",
      currency: "USD",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    let valueData = 0;
    if (typeof value === "string" || value instanceof String) {
      valueData = parseFloat(value);
    } else {
      valueData = value;
    }

    return formatter.format(valueData);
  },
  getDeviceId: () => {
    let device_id = localStorage.getItem("device_id");
    if (!device_id) {
      device_id = Guid.newGuid().toString();
      localStorage.setItem("device_id", device_id);
    }
    return device_id;
  },
  toDatetime: (value) => {
    let valueData = null;
    if (!value) {
      return valueData;
    }
    if (typeof value === "string" || value instanceof String) {
      if (!value.endsWith("Z")) {
        valueData = new Date(value + "Z");
      } else {
        valueData = new Date(value);
      }
    } else {
      valueData = value;
    }
    return valueData;
  },
  formatDateTime: (value) => {
    let valueData = 0;
    if (!value) {
      return "";
    }
    if (typeof value === "string" || value instanceof String) {
      if (!value.endsWith("Z")) {
        valueData = new Date(value + "Z");
      } else {
        valueData = new Date(value);
      }
    } else {
      valueData = value;
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(valueData);
  },
  formatDate: (value) => {
    let valueData = 0;
    if (!value) {
      return "N/A";
    }
    if (typeof value === "string" || value instanceof String) {
      if (!value.endsWith("Z")) {
        valueData = new Date(value + "Z");
      } else {
        valueData = new Date(value);
      }
    } else {
      valueData = value;
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(valueData);
  },
  formatTime: (value) => {
    let valueData = 0;
    if (!value) {
      return "";
    }
    if (typeof value === "string" || value instanceof String) {
      if (!value.endsWith("Z")) {
        valueData = new Date(value + "Z");
      } else {
        valueData = new Date(value);
      }
    } else {
      valueData = value;
    }
    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(valueData);
  },
  parseJwt: (token) => {
    if (!token) {
      return { IsAdmin: false, IsTrainingManager: false, IsLogin: false };
    }
    try {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      let user = JSON.parse(jsonPayload);
      user.IsLogin = true;
      //debugger
      if (user.roles) {
        let isAdmin = user.roles.indexOf("admin") !== -1;
        let isTrainingManager = user.roles.indexOf("training manager") !== -1;
        if (isAdmin) {
          user.IsAdmin = true;
        } else {
          user.IsAdmin = false;
        }
        if (isTrainingManager) {
          user.IsTrainingManager = true;
        } else {
          user.IsTrainingManager = false;
        }
      } else {
        user.IsAdmin = false;
        user.IsTrainingManager = false;
      }

      return user;
    } catch (error) {
      return { IsAdmin: false, IsTrainingManager: false, IsLogin: false };
    }
  },
  getUserInfo: () => {
    const auth_token = localStorage.getItem("auth_token");
    let user = Utils.parseJwt(auth_token);
    return { ...user, IsSA: user.roles?.split(", ").includes("SA") };
  },
  getToken: () => {
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const bearTk = auth_token_type + " " + auth_token;
    return bearTk;
  },
  isExpiredLicense: (expired_date) => {
    const _expired_date = dayjs.utc(expired_date);
    const now = dayjs();
    const daysDiff = now.diff(_expired_date, "day", true);
    if (daysDiff > 0) {
      return true;
    }
    return false;
  },
};

export default Utils;
