import axios from "../../configs";
let invoiceApiInstance = null;

export class InvoiceApi {
  constructor() {
    this.companyId = localStorage.getItem("auth_company_id");
    if (!this.companyId) {
      this.companyId = "common";
    }
    this.token = localStorage.getItem("auth_token");
    this.auth_token_type = localStorage.getItem("auth_token_type");
    this.headers = {
      Authorization: `Bearer ${this.token}`,
      "X-Company-Id": this.companyId,
    };
  }

  static getInstance() {
    if (!invoiceApiInstance) {
      invoiceApiInstance = new InvoiceApi();
    }
    return invoiceApiInstance;
  }

  static clearInstance() {
    invoiceApiInstance = null;
  }

  async acknowledgeOrder(data) {
    return await axios.post(
      `/transportation/${this.companyId}/acknowledge-transport-order-by id`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  async getOrders(page, statusFilter, searchKeyword) {
    let response = await axios.get(
      `/my-transport/${this.companyId}/orders?page=${page}&status=${statusFilter}&search=${searchKeyword}`,
      {
        headers: this.headers,
      },
    );

    let orders = response.data.result;
    return orders;
  }
}
