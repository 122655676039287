import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { toast } from "react-toastify";
import axios from "./../configs";
import "./register.css";
import { Button, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";

const Register = () => {
  const navigate = useNavigate();

  // Register Form
  const [formRegister, setFormRegister] = useState({
    name: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    key: "",
    confirmpass: "",
    is_active: "",
    locked: "",
  });

  const onChangeForm = (label, event) => {
    switch (label) {
      case "name":
        setFormRegister({ ...formRegister, name: event.target.value });
        break;
      // case "username":
      //     setFormRegister({ ...formRegister, username: event.target.value });
      //     break;
      case "email":
        // email validation
        const email_validation = /\S+@\S+\.\S+/;
        if (email_validation.test(event.target.value)) {
          setFormRegister({ ...formRegister, email: event.target.value });
        }
        break;

      case "phone_number":
        setFormRegister({ ...formRegister, phone_number: event.target.value });
        break;
      case "password":
        setFormRegister({ ...formRegister, password: event.target.value });
        break;

      case "key":
        setFormRegister({ ...formRegister, key: event.target.value });
        break;

      case "confirmpass":
        setFormRegister({ ...formRegister, confirmpass: event.target.value });
        break;
      default:
        break;
    }
  };

  //   Submit handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // Post to register API
    await axios
      .post(`/auth/register`, formRegister)
      .then((response) => {
        // move to sign in page
        navigate("/login");
        // add successfully notif
        toast.success(response.data.detail);
      })
      .catch((error) => {
        // add error notif
        console.log("error", error.response.data);
        toast.error(error.response.data.detail);
      });
  };

  function validateForm() {
    if (
      formRegister.name.length > 0 &&
      formRegister.email.length > 0 &&
      formRegister.password.length > 0 &&
      formRegister.confirmpass.length > 0
    ) {
      return true;
    }

    return false;
  }

  return (
    <div className="login_container">
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <p className="login_header" style={{ padding: 0, margin: 0 }}>
          Create An Account
        </p>

        <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
      </div>
      <form onSubmit={onSubmitHandler}>
        <div
          className="form_login"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <div style={{ paddingBottom: 10 }}>
            <label
              htmlFor="name"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Full Name
            </label>

            <Input
              size="large"
              prefix={<UserOutlined />}
              onChange={(event) => {
                onChangeForm("name", event);
              }}
            />
          </div>

          <div style={{ paddingBottom: 10 }}>
            <label
              htmlFor="email"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Email
            </label>

            <Input
              size="large"
              prefix={<UserOutlined />}
              onChange={(event) => {
                onChangeForm("email", event);
              }}
            />
          </div>

          <div style={{ paddingBottom: 10 }}>
            <label
              htmlFor="phone_number"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Phone Number
            </label>

            <Input
              size="large"
              prefix={<UserOutlined />}
              onChange={(event) => {
                onChangeForm("phone_number", event);
              }}
            />
          </div>

          <div style={{ paddingBottom: 20 }}>
            <label
              htmlFor="password"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Password
            </label>
            <Input
              size="large"
              prefix={<UserOutlined />}
              type="password"
              onChange={(event) => {
                onChangeForm("password", event);
              }}
            />
          </div>
          <div style={{ paddingBottom: 20 }}>
            <label
              htmlFor="confirmpass"
              className="font_text"
              style={{ paddingBottom: 10 }}
            >
              Confirm Password
            </label>
            <Input
              size="large"
              prefix={<UserOutlined />}
              type="password"
              onChange={(event) => {
                onChangeForm("confirmpass", event);
              }}
            />
          </div>

          <div
            style={{
              paddingBottom: 20,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Button
              disabled={!validateForm()}
              onClick={onSubmitHandler}
              type="primary"
              size="large"
            >
              Create
            </Button>

            <p style={{ textAlign: "center" }}>
              Already have an account?{" "}
              <Link to="/login">
                <span>Sign In</span>
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
