import { Drawer } from "antd";
import React from "react";
import ManifestDetailView from "./ManifestDetailView";

const ManifestViewModal = ({ onCancel, isOpen, manifest_id }) => {
  return (
    <Drawer
      open={isOpen}
      style={{ top: 5, padding: "0" }}
      onClose={onCancel}
      width="100VW"
      title="Manifest Detail"
      placement={"right"}
    >
      {manifest_id && <ManifestDetailView manifest_id={manifest_id} />}
    </Drawer>
  );
};

export default ManifestViewModal;
