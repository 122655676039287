import React, { Suspense, lazy } from "react";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Navigate,
  Outlet,
} from "react-router-dom";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import "./App.css";
import MainLayout from "./components/MainLayout";
import LoginLayout from "./components/LoginLayout";
import ExternalLogin from "./components/ExternalLogin";
import MyProfile from "./pages/MyProfile";
import About from "./pages/About";
import Help from "./pages/Help";
import { Button, Flex, Result, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Main from "./pages/Main";
import { AuthProvider } from "./utils/auth";
import CompanyReports from "./pages/CompanyReports";
import Migration from "./pages/Migration";
import { ErrorBoundary } from "./components/Error";
import ChangeEmailConfirmation from "./pages/ChangeEmailConfirmation";
import InvoiceRevenue from "./modules/invoices/pages/InvoiceRevenue";
import InvoiceDashboard from "./modules/invoices/pages/InvoiceDashboard";
import InvoiceBills from "./modules/invoices/pages/InvoiceBills";
import InvoiceExpense from "./modules/invoices/pages/InvoiceExpense";
import InvoiceVendors from "./modules/invoices/pages/InvoiceVendors";
import InvoiceClients from "./modules/invoices/pages/InvoiceClients";
import PageGoogleDriveRequestCallback from "./modules/driver/pages/PageGoogleDriveRequestCallback";

const OriginationOrderList = lazy(
  () => import("./modules/origination/pages/OriginationOrderList"),
);
const OriginationOrderDetail = lazy(
  () => import("./modules/origination/pages/OriginationOrderDetail"),
);
const TransportationOrderManagement = lazy(
  () => import("./modules/transportation/pages/TransportationOrderManagement"),
);
const TransportationOrderDetail = lazy(
  () => import("./modules/transportation/pages/TransportationOrderDetail"),
);
const DestinationOrderManagement = lazy(
  () => import("./modules/destination/pages/DestinationOrderManagement"),
);
const DestinationProductsDetail = lazy(
  () => import("./modules/destination/pages/DestinationProductsDetail"),
);
const MyCompanyManagement = lazy(
  () => import("./modules/my_company/pages/MyCompanyManagement"),
);
const MyCompanyLicenseeDetail = lazy(
  () => import("./modules/my_company/pages/CompanyLicenseeDetail"),
);
const MyCompanyVehicleDetail = lazy(
  () => import("./modules/my_company/pages/CompanyVehicleDetail"),
);
const MyCompanyUserDetail = lazy(
  () => import("./modules/my_company/pages/CompanyUserDetail"),
);
const UserManagement = lazy(() => import("./pages/UserManagement"));
const UsersManagementTabs = lazy(() => import("./pages/UsersManagementTabs"));
const AuthorizedAccess = lazy(() => import("./pages/AuthorizedAccess"));
const RoleManagement = lazy(() => import("./pages/Users/RoleManagement"));
const PermissionManagement = lazy(
  () => import("./pages/Users/PermssionManagement"),
);
const CompanyLicenseeDetail = lazy(
  () => import("./pages/CompanyLicenseeDetail"),
);

const CompanyManagement = lazy(() => import("./pages/CompanyManagement"));

const CompanyUserDetail = lazy(() => import("./pages/CompanyUserDetail"));

const CompanyVehicleDetail = lazy(() => import("./pages/CompanyVehicleDetail"));

const OriginationOrderDetailView = lazy(
  () => import("./modules/origination/pages/OriginationOrderDetailView"),
);

const CheckListTransport = lazy(
  () => import("./modules/my_company/pages/CheckListTransport"),
);

const CheckListOrigination = lazy(
  () => import("./modules/my_company/pages/CheckListOrigination"),
);

const CheckListDestination = lazy(
  () => import("./modules/my_company/pages/CheckListDestination"),
);

const PrintManifestDetail = lazy(
  () => import("./components/PrintManifestDetail"),
);

const TestRequestPackageDetail = lazy(
  () => import("./modules/price/pages/TestRequestPackageDetail"),
);

const TestRequestPackage = lazy(
  () => import("./modules/price/pages/TestRequestPackage"),
);

const TestRequestItem = lazy(
  () => import("./modules/price/pages/TestRequestItem"),
);

const TransportPackage = lazy(
  () => import("./modules/price/pages/TransportPackage"),
);

const TransportPackageDetail = lazy(
  () => import("./modules/price/pages/TransportPackageDetail"),
);

const TransportItem = lazy(() => import("./modules/price/pages/TransportItem"));

const MyTransportManagement = lazy(
  () => import("./modules/driver/pages/MyTransportManagement"),
);

const PrintManifestChainOfCustody = lazy(
  () => import("./components/PrintManifestChainOfCustody"),
);
const PrintManifestChainOfCustodyVersion1 = lazy(
  () => import("./components/PrintManifestChainOfCustodyVersion1"),
);

function ErrorPage() {
  return (
    <MainLayout>
      <Flex vertical>
        <Result
          status="404"
          title="Not found."
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/">
              <Button type="primary" icon={<ArrowLeftOutlined />}>
                Back Home
              </Button>
            </Link>
          }
        />
      </Flex>
    </MainLayout>
  );
}

const routers = createBrowserRouter([
  {
    path: "login",
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: "handle-drive-external",
    element: (
      <LoginLayout>
        <PageGoogleDriveRequestCallback />
      </LoginLayout>
    ),
  },
  {
    path: "google-drive/callback/grant",
    element: (
      <LoginLayout>
        <PageGoogleDriveRequestCallback />
      </LoginLayout>
    ),
  },
  {
    path: "login-external",
    element: (
      <LoginLayout>
        <ExternalLogin></ExternalLogin>
      </LoginLayout>
    ),
  },
  {
    path: "reset",
    element: (
      <LoginLayout>
        <Reset></Reset>
      </LoginLayout>
    ),
  },
  {
    path: "change-email",
    element: (
      <LoginLayout>
        <ChangeEmailConfirmation></ChangeEmailConfirmation>
      </LoginLayout>
    ),
  },
  {
    path: "register",
    element: (
      <LoginLayout>
        <Register />
      </LoginLayout>
    ),
  },
  {
    path: "forgot",
    element: (
      <LoginLayout>
        <Forgot />
      </LoginLayout>
    ),
  },
  {
    path: "print-manifest-detail",
    element: (
      // <PrintDestinationProductsDetail />
      <PrintManifestDetail />
    ),
  },
  {
    path: "print-manifest-chain-of-custody",
    element: (
      // <PrintDestinationProductsDetail />
      <PrintManifestChainOfCustody />
    ),
  },
  {
    path: "print-manifest-chain-of-custody-version-1",
    element: (
      // <PrintDestinationProductsDetail />
      <PrintManifestChainOfCustodyVersion1 />
    ),
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { index: true, element: <Navigate to="/" replace /> },
      {
        path: "/myaccount",
        children: [
          {
            path: "",
            element: <Main />,
          },
          {
            path: "my-profile",
            element: <MyProfile />,
          },
        ],
      },
      {
        path: "origination",
        element: <Outlet />,
        children: [
          { index: true, element: <Navigate to="/order" replace /> },
          {
            path: "order",
            element: <OriginationOrderList />,
          },
          {
            path: "order/:slug",
            element: <OriginationOrderDetail />,
          },
          {
            path: "order/:slug/view",
            element: <OriginationOrderDetailView />,
          },
        ],
      },
      {
        path: "invoice",
        element: <Outlet />,
        children: [
          { index: true, element: <Navigate to="/dashboard" replace /> },
          {
            path: "dashboard",
            element: <InvoiceDashboard />,
          },

          {
            path: "bills",
            element: <InvoiceBills />,
          },
          {
            path: "expense",
            element: <InvoiceExpense />,
          },
          {
            path: "revenue",
            element: <InvoiceRevenue />,
          },
          {
            path: "vendors",
            element: <InvoiceVendors />,
          },
          {
            path: "clients",
            element: <InvoiceClients />,
          },
        ],
      },
      {
        path: "transportation",
        children: [
          {
            index: true,
            element: <Navigate to="/transportation-order" replace />,
          },
          {
            path: "transportation-order",
            element: <TransportationOrderManagement />,
          },
          {
            path: "transportation-order-detail",
            element: <TransportationOrderDetail />,
          },
          {
            path: "my-transport",
            element: <MyTransportManagement />,
          },
        ],
      },
      {
        path: "destination",
        element: <Outlet />,
        children: [
          { index: true, element: <Navigate to="/order" replace /> },
          {
            path: "order",
            element: <DestinationOrderManagement />,
          },
          {
            path: "order-detail",
            element: <DestinationProductsDetail />,
          },
        ],
      },

      {
        path: "/administrator",
        children: [
          {
            path: "user-management-detail",
            element: <UsersManagementTabs />,
          },
          {
            path: "user-management",
            element: <UserManagement />,
          },
          {
            path: "role-management",
            element: <RoleManagement />,
          },
          {
            path: "permission-management",
            element: <PermissionManagement />,
          },
          {
            path: "access-log",
            element: <AuthorizedAccess />,
          },
          {
            path: "company-reports",
            element: <CompanyReports />,
          },
          {
            path: "company-migration",
            element: <Migration />,
          },
        ],
      },
      {
        path: "/management",
        children: [
          {
            path: "company-management",
            element: <CompanyManagement />,
          },
          {
            path: "company-licensee-detail-management",
            element: <CompanyLicenseeDetail />,
          },
          {
            path: "company-vehicle-detail-management",
            element: <CompanyVehicleDetail />,
          },
          {
            path: "company-user-detail-management",
            element: <CompanyUserDetail />,
          },
        ],
      },
      {
        path: "/mycompany",
        children: [
          {
            path: "company",
            element: <MyCompanyManagement />,
          },
          {
            path: "company-licensee",
            element: <MyCompanyLicenseeDetail />,
          },
          {
            path: "company-vehicle",
            element: <MyCompanyVehicleDetail />,
          },
          {
            path: "company-user",
            element: <MyCompanyUserDetail />,
          },
          {
            path: "transportation-check-list",
            element: <CheckListTransport />,
          },
          {
            path: "origination-check-list",
            element: <CheckListOrigination />,
          },
          {
            path: "destination-check-list",
            element: <CheckListDestination />,
          },
          {
            path: "price-lab/item",
            element: <TestRequestItem />,
          },
          {
            path: "price-lab/package",
            element: <TestRequestPackage />,
          },
          {
            path: "price-lab/package/:id",
            element: <TestRequestPackageDetail />,
          },
          {
            path: "price-transport/item",
            element: <TransportItem />,
          },
          {
            path: "price-transport/package",
            element: <TransportPackage />,
          },
          {
            path: "price-transport/package/:id",
            element: <TransportPackageDetail />,
          },
        ],
      },
      {
        path: "/home",
        element: <Main />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },

  {
    path: "*",
    element: <ErrorPage />,
  },
]);

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Spin />
            </div>
          }
        >
          <ToastContainer />
          <RouterProvider router={routers} />
        </Suspense>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
