import { Form, Input, Modal } from "antd";

export default function ModalAddClients({
  modal: [item, setModalAddClients],
  formAction,
  form,
  loading,
}) {
  return (
    <>
      <Modal
        style={{ top: 10 }}
        {...item}
        maskClosable={false}
        onCancel={() => setModalAddClients({})}
        forceRender
        onOk={() => form.submit()}
        okButtonProps={{ loading: loading, icon: item.icon }}
      >
        <Form
          form={form}
          onFinish={formAction}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item name="client_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            required
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            required
            rules={[{ required: true, message: "Phone is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            required
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            required
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
