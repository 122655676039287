import { Form, Select } from "antd";
import React from "react";
const { Option } = Select;

const FormItemSuffix = ({ name, items = [], onChange, width = 100 }) => {
  return (
    <Form.Item name={name} noStyle>
      <Select style={{ width: width }} onChange={onChange}>
        {items.map((item, idx) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormItemSuffix;
