import React, { useEffect } from "react";
import { useState } from "react";
import { SaveOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Space,
  Input,
  Button,
  List,
  Flex,
  Descriptions,
  Form,
  Modal,
} from "antd";
import { Card } from "antd";
import HighText from "../../../components/HL";
import { GenData } from "../../../components/Description";
import axios from "../../../configs";
import { toast } from "react-toastify";
import ModalAddVendors from "../components/ModalAddVendors";
import ModalEditVendors from "../components/ModalEditVendors";

const InvoiceVendors = () => {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");
  const query = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [search, setSearch] = useState();

  const [isOpenModalEditVendors, setIsOpenModalEditVendors] = useState(false);
  const [isOpenModalAddVendors, setIsOpenModalAddVendors] = useState(false);

  const [formadd] = Form.useForm();
  const [modalAddVendors, setModalAddVendors] = useState({});
  const [modalEditVendors, setModalEditVendors] = useState({});

  const [isClickButton, setIsClickButton] = useState(false);

  const [statusFilter, setStatusFilter] = useState({
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
    },
    search: "",
    status: "",
    sort: "",
  });

  const handleInit = () => {
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      fetchData();
    }, 100);
  };

  const onLoadMore = () => {
    setStatusFilter({
      ...statusFilter,
      pagination: {
        ...statusFilter.pagination,
        current: statusFilter.pagination.current + 1,
      },
    });
  };

  let reftimeout = React.useRef(null);

  const fetchData = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await axios
        .get(`/vendors/${company_id}/get-all-vendors-for-list`, {
          params: {
            page: statusFilter.pagination?.current,
            per_page: statusFilter.pagination?.pageSize,
            sort: statusFilter.sort,
            search: statusFilter.search,
            status: statusFilter.status,
          },
          headers: { Authorization: token },
        })
        .then((response) => {
          let listVendors = response.data.result.rows;

          if (
            listVendors.length === 0 &&
            statusFilter.pagination.current === 1
          ) {
            setData([]);
          } else {
            setData((state) => state.concat(listVendors));
          }

          setIsLoading(false);
          setStatusFilter({
            ...statusFilter,
            pagination: {
              ...statusFilter.pagination,
              total: response.data.result.totalCount,
            },
            search: statusFilter?.search,
          });
          toast.success(response.data.result);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    handleInit();
  }, [statusFilter?.status, statusFilter?.pagination?.current]);

  const loadMore =
    !isLoading && data.length < statusFilter?.pagination?.total ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  const actions = (record) => {
    let result = (
      <>
        <Button
          type="primary"
          shape="round"
          onClick={(event) => {
            EditVendorsAction(record);
          }}
          size={"large"}
        >
          Edit
        </Button>
        <Button
          type="primary"
          danger
          shape="round"
          onClick={(event) => {
            RemoveVendorsAction(record);
          }}
          size={"large"}
        >
          Remove
        </Button>
      </>
    );
    return result;
  };

  // add new vendors
  const AddVendorsAction = () => {
    setIsOpenModalAddVendors(true);
    formadd.resetFields();
    //formadd.setFieldsValue({ is_active: 1, method: 'get' })
    setModalAddVendors({
      open: true,
      title: <>Add Vendor</>,
      okText: "Save",
      type: "add",
      icon: <SaveOutlined />,
    });
  };

  const EditVendorsAction = (record) => {
    setIsOpenModalEditVendors(true);
    formadd.resetFields();
    formadd.setFieldsValue(record);
    setModalEditVendors({
      open: true,
      title: <>Edit Vendor</>,
      okText: "Save",
      type: "add",
      icon: <SaveOutlined />,
    });
  };

  const onSubmitAddVendors = async (formData) => {
    const dataAdd = {
      vendor_id: "",
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
    };
    setIsLoading(true);
    if (isLoading) {
      return;
    }
    await axios
      .post(`/vendors/${company_id}/add-new-vendors`, dataAdd, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setIsLoading(false);

        fetchData();

        toast.success(response.data.detail);
        setModalAddVendors({
          open: false,
          title: <>Add Vendor</>,
          okText: "Save",
          type: "add",
          icon: <SaveOutlined />,
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.detail);
        setIsLoading(false);
      });
  };

  const onSubmitEditVendors = async (formData) => {
    const dataUpdate = {
      vendor_id: formData.vendor_id,
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
    };
    setIsLoading(true);
    if (isLoading) {
      return;
    }
    await axios
      .post(`/vendors/${company_id}/edit-vendors`, dataUpdate, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setIsLoading(false);

        fetchData();

        toast.success(response.data.detail);
        setModalEditVendors({
          open: false,
          title: <>Edit Vendor</>,
          okText: "Save",
          type: "add",
          icon: <SaveOutlined />,
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.detail);
        setIsLoading(false);
      });
  };

  // remove
  const RemoveVendorsAction = (record) => {
    setIsClickButton(true);
    const dataUpdate = {
      vendor_id: record.vendor_id,
      name: record.name,
      phone: record.phone,
      email: record.email,
      address: record.address,
    };
    const handleDelete = async () => {
      setIsLoading(true);
      await axios
        .post(`/vendors/${company_id}/remove-vendors`, dataUpdate, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setIsLoading(false);

          fetchData();

          toast.success("Success");
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          setIsLoading(false);
        });
    };

    // TODO: open duplicate modal delete
    const handleConfirmDelete = () => {
      if (!isLoading) {
        setIsLoading(true);
        handleDelete();
        setIsClickButton(false);
      }
    };

    const handleModalCancel = () => {
      setIsClickButton(false);
    };
    //-----------------------

    Modal.confirm({
      title: "Are you sure you want to remove this vendor?",
      okText: "Remove",
      okButtonProps: { loading: isLoading, danger: true },
      onOk: handleConfirmDelete,
      onCancel: handleModalCancel,
      maskClosable: false,
    });
  };

  return (
    <>
      <div>
        <Space
          wrap
          style={{
            display: "flex",
            padding: "0 1rem",
            marginTop: "10px",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Input
            onInput={(e) => setSearch(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
            placeholder="Search name"
          />
          <Button
            type="primary"
            shape="round"
            onClick={(event) => {
              AddVendorsAction();
            }}
            loading={isLoading}
            size={"large"}
          >
            New vendor
          </Button>
        </Space>

        <List
          loading={isLoading}
          style={{ flex: 1, overflow: "auto", paddingTop: 16 }}
          loadMore={loadMore}
          grid={{ column: 1 }}
          //dataSource={data}
          dataSource={data.filter(
            (x) =>
              !search || x.name.toLowerCase().includes(search.toLowerCase()),
          )}
          renderItem={(record) => {
            return (
              <List.Item>
                <Card hoverable>
                  <Flex gap={16} justify="space-between">
                    <Card.Meta
                      onClick={(event) => {}}
                      title={
                        <>
                          <HighText search={search}>{record.name}</HighText>
                        </>
                      }
                      description={
                        <Descriptions
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 4,
                            xxl: 4,
                          }}
                          items={GenData({
                            //Status: statusItem,
                            Name: <HighText>{record.name}</HighText>,
                            Email: (
                              <HighText search={search}>
                                {record.email}
                              </HighText>
                            ),
                            Phone: <HighText>{record.phone}</HighText>,
                            Address: <HighText>{record.address}</HighText>,
                          })}
                        />
                      }
                    ></Card.Meta>
                  </Flex>
                  <Space size="middle" wrap={true}>
                    {actions(record)}
                  </Space>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
      {isOpenModalAddVendors && (
        <ModalAddVendors
          form={formadd}
          modal={[modalAddVendors, setModalAddVendors]}
          formAction={onSubmitAddVendors}
          loading={isLoading}
        ></ModalAddVendors>
      )}

      {isOpenModalEditVendors && (
        <ModalEditVendors
          form={formadd}
          modal={[modalEditVendors, setModalEditVendors]}
          formAction={onSubmitEditVendors}
          loading={isLoading}
        ></ModalEditVendors>
      )}
    </>
  );
};
export default InvoiceVendors;
