import React from "react";
import "leaflet/dist/leaflet.css";
// import ModalTakePicture from './ModalTakePicture';
import "../invoices.css";
import Utils from "../../../utils/utils";
const InvoiceReportComponent = ({ item }) => {
  const [workingItem, setWorkingItem] = React.useState();

  React.useEffect(() => {
    let data = { ...item };
    if (data.lines && data.lines.data) {
      data.lines = data.lines.data;
    }
    setWorkingItem(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  if (!workingItem) {
    return <></>;
  }

  return (
    <div
      style={{ maxWidth: "800px", width: "100%" }}
      id={`invoice-${workingItem.revenue_number || workingItem.expense_number}`}
    >
      <div style={{ width: "100%" }}>
        <h2>Invoice</h2>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>From sender</td>
              <td style={{ textAlign: "right" }}>
                {workingItem.business?.from_name}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>
                {workingItem.business?.address}
              </td>
            </tr>
            <tr>
              <td>To client</td>
              <td style={{ textAlign: "right" }}>
                {workingItem.business?.to_name}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>
                {workingItem.business?.to_address}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>
                {workingItem.business?.to_email}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Details</td>
              <td></td>
            </tr>
            <tr>
              <td>
                Invoice No:{" "}
                {workingItem.revenue_number || workingItem.expense_number}
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Payment Terms: </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                Created Date:
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>
                {Utils.formatDate(workingItem.created_at)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
                Due Date:
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "right" }}>
                {Utils.formatDate(workingItem.due_date)}
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ padding: "10px" }}>&nbsp;</div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ fontWeight: "bold", borderBottom: "1px solid #ccc" }}>
              <th style={{ textAlign: "left", width: "55%" }}>Description</th>
              <th style={{ textAlign: "left" }}>Unit Price</th>
              <th style={{ textAlign: "right" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {workingItem.lines.map((line) => {
              return (
                <tr style={{ borderBottom: "1px solid #ccc" }}>
                  <td style={{ padding: "10px 0", width: "55%" }}>
                    {line.quantity} x {line.item}
                  </td>
                  <td>{Utils.formatMoney(line.price, "decimal")}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {Utils.formatMoney(
                      line.price * line.quantity,
                      "decimal",
                    )}{" "}
                    USD
                  </td>
                </tr>
              );
            })}

            <tr>
              <td style={{ width: "55%" }}></td>
              <td style={{ padding: "3px 0" }}>Subtotal:</td>
              <td style={{ textAlign: "right" }}>
                {Utils.formatMoney(workingItem.base_amount, "decimal")} USD
              </td>
            </tr>
            <tr>
              <td style={{ width: "55%" }}></td>
              <td style={{ padding: "3px 0" }}>Discount:</td>

              <td style={{ textAlign: "right" }}>
                {" "}
                {workingItem.discount_value_amount} %
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ padding: "3px 0" }}>Total Tax:</td>

              <td style={{ textAlign: "right" }}>{workingItem.tax_amount} %</td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td></td>
              <td style={{ padding: "3px 0", borderBottom: "1px solid #ccc" }}>
                Total:
              </td>
              <td
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {Utils.formatMoney(workingItem.total_amount, "decimal")} USD
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceReportComponent;
