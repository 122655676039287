import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "./../utils/auth";
import axios from "./../configs";

import { Button, Form } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
const FormItem = Form.Item;

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const query = new URLSearchParams(window.location.search);
  const returnUrl = query.get("ReturnUrl");

  const [loginForm, setLoginform] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setLoginform({ ...loginForm, username: event.target.value });
        break;
      case "password":
        setLoginform({ ...loginForm, password: event.target.value });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    auth.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await axios
        .post("/auth/login", loginForm)
        .then(async (response) => {
          //debugger;
          // Save token to local storage
          await auth.login(
            response.data.result.company_id,
            response.data.result.access_token,
            response.data.result.token_type,
            response.data.result.external_token,
          );

          toast.success("You have been logged in");
          if (returnUrl) {
            navigate(returnUrl);
          } else {
            navigate("/home");
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            toast.error(error.response.data.detail);
          } else if (error && error.message) {
            toast.error(error.message);
          }
          return navigate("/login");
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "120px",
          }}
        >
          {/* <h2>
            Sign in
          </h2>
          <ExternalLogin></ExternalLogin>
          <Divider style={{ width: '100%' }}>Or</Divider> */}

          <img
            src="/static/logo_word.png"
            loading="lazy"
            alt=""
            style={{
              height: "25%",
              borderRadius: 4,
              marginBottom: "30px",
            }}
          />

          <form
            onSubmit={onSubmitHandler}
            noValidate
            style={{ minWidth: "300px" }}
          >
            <FormItem>
              <Input
                prefix={<UserOutlined />}
                margin="normal"
                required
                id="username"
                label="Email"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={(event) => {
                  onChangeForm("username", event);
                }}
              />
            </FormItem>
            <FormItem>
              <Input
                prefix={<LockOutlined />}
                margin="normal"
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  onChangeForm("password", event);
                }}
              />
            </FormItem>
            <FormItem style={{ textAlign: "center" }}>
              <Button
                type="link"
                onClick={() => {
                  navigate("/forgot");
                }}
              >
                Forgot password?
              </Button>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Sign In
              </Button>
            </FormItem>
            {/* <FormItem>
              <Button type="link" onClick={() => { navigate('/forgot') }}>Forgot password?</Button>
              <Button type="link" onClick={() => { navigate('/register') }}>Don't have an account? Sign Up</Button>

            </FormItem> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
