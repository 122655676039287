import { Form, Input, Modal, Avatar } from "antd";
import { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Utils from "../utils/utils";

export default function EditMyProfile({
  modal: [item, setModal],
  formAction,
  form,
  userInfo,
  loading,
}) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const companyId = localStorage.getItem("auth_company_id");
  const external_token = localStorage.getItem("external_token");
  const [loadingImg, setLoadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState(() => {
    let url = "";
    if (
      userInfo &&
      userInfo != null &&
      userInfo.avatar_id != null &&
      userInfo.avatar_id !== ""
    ) {
      url = `${Utils.ApiEndpoint}/media/${companyId}/avatar/${userInfo.id}?id=${userInfo.avatar_id}&token=${external_token}`;
      return url;
    }
    return null;
  });

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingImg(false);
        setImageUrl(url);
        //setModal({ ...item, profile_image_path: url })
        //formAction.setFieldsValue({ ...item, profile_image_path: url })
      });
      const resp = info.file.response.result;
      form.setFieldValue("avatar_id", resp.media_id);
      form.setFieldValue("media_info", JSON.stringify(resp));
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const uploadProps = {
    name: "file",
    action: `${Utils.ApiEndpoint}/media/${companyId}/single_upload`,
    headers: {
      authorization: token,
    },
  };

  return (
    <>
      <Modal
        style={{ top: 10 }}
        {...item}
        onCancel={() => setModal({})}
        forceRender
        onOk={() => form.submit()}
        okButtonProps={{ loading: loading, icon: item.icon }}
      >
        <Form
          form={form}
          onFinish={formAction}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="avatar_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="media_info" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            required
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone_number"
            label="Phone"
            required
            rules={[{ required: true, message: "Phone is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            required
            rules={[{ required: true, message: "Addresss is required" }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item name="profile_image_path" label="Issued Date" required rules={[{ required: true, message: "Issued date is required" }]} >
                        <Input />
                    </Form.Item> */}
          <Form.Item name="image_file" label="Avatar Images">
            <Upload
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              accept=".png, .jpg, .jpeg"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              {...uploadProps}
            >
              {imageUrl ? (
                <Avatar size={100} src={<img src={imageUrl} alt="avatar" />} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

// {imageUrl ?
//     <img src={imageUrl} alt="avatar" style={{ width: '100%', height:'100%',borderRadius:'50%',objectFit:'cover' }} /> : uploadButton}
