import React from "react";
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from "./../configs";
import { useNavigate } from "react-router-dom";

function Migration() {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;

  const navigate = useNavigate();

  const updateRoleForVersion = async (event, version) => {
    event.preventDefault();

    const default_version = "1000.0.2";
    if (!version) {
      version = default_version;
    }
    // const url = `/migrate?version=${version}`;

    const data = {
      version: version,
    };

    await axios
      .post("/company/migration-for-version", data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        toast.success(response.data.detail);
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
      }}
    >
      <h1 style={{ fontSize: "30px", marginBottom: "20px" }}>
        Migration database for version
      </h1>
      <Button
        onClick={(e) => {
          updateRoleForVersion(e, "1000.0.6");
        }}
      >
        Version 1.0.6
      </Button>
    </div>
  );
}

export default Migration;
