import React from "react";
import { Modal } from "antd";
import Utils from "../utils/utils";

const ViewPdfModal = ({ media_info, open, onCancel }) => {
  const external_token = localStorage.getItem("external_token");
  const companyIdToken = localStorage.getItem("auth_company_id");
  debugger;
  return (
    <Modal
      title={media_info.file_name}
      centered
      open={open}
      onCancel={onCancel}
      width={1200}
      footer={null}
    >
      <embed
        id="ifameviewPdf"
        type="application/pdf"
        title={media_info.file_name}
        src={`${Utils.ApiEndpoint}/media/${companyIdToken}/download_view_file?id=${media_info.media_id}&token=${external_token}`}
        width="100%"
        height="800px"
      />
    </Modal>
  );
};

export default ViewPdfModal;
