import React from "react";
import { Layout, Divider, Button } from "antd";
import { CopyrightCircleOutlined } from "@ant-design/icons";
import TermsAndPolicyModal from "./TermsAndPolicyModal";

const { Footer } = Layout;

function LoginLayout({ children }) {
  const [modalPolicyTerms, setModalPolicyTerms] = React.useState(false);

  //TODO: view policy and terms of use
  const handleViewPolicyTerms = () => {
    setModalPolicyTerms(true);
  };

  return (
    <>
      {/* <Header style={{ textAlign: 'right', margin: '20%' }}>
        <a href="">Contact us</a>
      </Header> */}

      <div
        style={{
          textAlign: "right",
          marginRight: "10%",
          paddingTop: "30px",
        }}
      >
        <a href="mailto:thuan@aclassia.com?subject=[Aclassia]: ">Contact us</a>
      </div>

      {children}

      <Footer
        style={{
          textAlign: "center",
          padding: 8,
          backgroundColor: "white",
        }}
      >
        <Divider style={{ width: "100%" }}></Divider>
        <a
          href="#/"
          onClick={() => {
            handleViewPolicyTerms();
          }}
        >
          <span style={{ marginRight: "20px" }}>Privacy & terms</span>
        </a>
        <a href="#/">
          Powered by Phamiliar Technologies LLC <CopyrightCircleOutlined /> 2024
        </a>

        {modalPolicyTerms && (
          <TermsAndPolicyModal
            open={modalPolicyTerms}
            onCancel={() => {
              setModalPolicyTerms(false);
            }}
          />
        )}
      </Footer>
    </>
  );
}

export default LoginLayout;
