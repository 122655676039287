import {
  Col,
  Form,
  Modal,
  Row,
  Divider,
  Select,
  Input,
  InputNumber,
  List,
  Checkbox,
  Collapse,
  Button,
  Flex,
  Drawer,
} from "antd";
import React, { useEffect, useState } from "react";
import constants from "../../utils/constants";

import { toast } from "react-toastify";
import { ClearOutlined, PlusOutlined } from "@ant-design/icons";
import FormItemSuffix from "../../modules/origination/components/FormItemSuffix";
import ManifestIndividualFormModal from "./ManifestIndividualFormModal";
import { ManifestAPI } from "../../modules/ManifestAPI";

const { Option } = Select;
const ManifestAddProductItemModal = ({
  isOpen,
  onOk,
  onCancel,
  model,
  company_id,
  config,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenExtraModal, setIsOpenExtraModal] = useState(false);
  const [testRequest, setTestRequest] = useState();
  const [extraTestRequest, setExtraTestRequest] = useState();
  debugger;
  const test_request_package_list = config.price_package_list.filter(
    (x) => x.type === 1 && x.company_id === company_id,
  );
  const CustomPackageList = test_request_package_list.filter(
    (x) => x.is_individual === true,
  );
  const [form] = Form.useForm();
  const {
    BATCH_SIZE_UNIT_LIST,
    WEIGHT_UNIT_LIST,
    ITEM_UNIT_LIST,
    SAMPLE_TYPE_LIST,
  } = constants;

  const onCheckAllChange = (e) => {
    const _testRequest = { ...testRequest };
    const _elements = _testRequest.elements.map((x) => {
      x.selected = e.target.checked ? true : false;
      return x;
    });
    _testRequest.elements = [..._elements];

    _testRequest.unit_value =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.unit_value).reduce((a, b) => a + b)
        : 0;
    _testRequest.price =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.price).reduce((a, b) => a + b)
        : 0;
    setTestRequest(_testRequest);
  };

  const onCheckElement = (elm) => {
    const _testRequest = { ...testRequest };
    const idx = _testRequest.elements.findIndex((x) => x.code === elm.code);
    if (idx !== -1) {
      _testRequest.elements[idx].selected =
        _testRequest.elements[idx].selected === true ? false : true;
    }
    _testRequest.unit_value =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.unit_value).reduce((a, b) => a + b)
        : 0;
    _testRequest.price =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.price).reduce((a, b) => a + b)
        : 0;
    setTestRequest(_testRequest);
  };

  const onChangeTestRequest = (test_request_code) => {
    const idx = test_request_package_list.findIndex(
      (x) => x.code === test_request_code,
    );
    if (idx !== -1) {
      const pak = { ...test_request_package_list[idx] };
      pak.elements = pak.elements.map((x) => {
        x.selected = !pak.is_individual;
        return x;
      });

      setTestRequest(pak);
      // reset extra
      setExtraTestRequest();
    }
  };
  const onSubmit = (values) => {
    if (isLoading) return;
    if (testRequest && testRequest.is_individual) {
      const idx = testRequest.elements.findIndex((x) => x.selected === true);
      if (idx === -1) {
        toast.warn("Please select item on the test request.");
        return;
      }
    }
    let _testRequest = null;
    if (testRequest) {
      _testRequest = { ...testRequest };
      if (testRequest.is_individual) {
        _testRequest.elements = testRequest.elements.filter(
          (x) => x.selected === true,
        );
      }
    }
    let _extraTestRequest = null;
    if (extraTestRequest) {
      _extraTestRequest = { ...extraTestRequest };
      if (extraTestRequest.is_individual) {
        _extraTestRequest.elements = extraTestRequest.elements.filter(
          (x) => x.selected === true,
        );
      }
    }
    const data = {
      ...model, //model[0]
      ...values,
      test_request: _testRequest,
      extra_test_request: _extraTestRequest,
    };
    setIsLoading(true);
    new ManifestAPI()
      .addManifestProduct(data)
      .then((response) => {
        debugger;
        setIsLoading(false);
        const resp = response.data.result;
        onOk(resp);
      })
      .finally((x) => setIsLoading(false));
  };
  const handleCancel = (ev) => {
    onCancel();
  };
  const handleOk = (ev) => {
    form.submit();
  };
  const initProduct = () => {
    if (model != null) {
      if (model.test_request) {
        model.test_request_code = model.test_request.code;
        const idx = test_request_package_list.findIndex(
          (x) => x.code === model.test_request.code,
        );
        if (idx !== -1) {
          const pak = JSON.parse(
            JSON.stringify(test_request_package_list[idx]),
          );

          pak.elements = pak.elements.map((x) => {
            x.selected =
              model.test_request.elements.findIndex(
                (elm) => elm.code === x.code,
              ) !== -1;
            return x;
          });
          setTestRequest(pak);
        }
        if (model.extra_test_request) {
          const idx = test_request_package_list.findIndex(
            (x) => x.code === model.extra_test_request.code,
          );
          if (idx !== -1) {
            const pak = JSON.parse(
              JSON.stringify(test_request_package_list[idx]),
            );

            pak.elements = pak.elements.map((x) => {
              x.selected =
                model.extra_test_request.elements.findIndex(
                  (elm) => elm.code === x.code,
                ) !== -1;
              return x;
            });

            setExtraTestRequest(pak);
          }
        }
      } else {
        if (test_request_package_list && test_request_package_list.length > 0) {
          const pakIdx = test_request_package_list.findIndex(
            (x) => x.is_individual === false,
          );
          const pak = {
            ...test_request_package_list[pakIdx !== -1 ? pakIdx : 0],
          };
          pak.elements = pak.elements.map((x) => {
            x.selected = !pak.is_individual;
            return x;
          });
          setTestRequest(pak);
          model.test_request_code = pak.code;
        }
      }

      setTimeout(() => {
        form.setFieldsValue(model);
      }, 0);
    } else {
    }
  };
  useEffect(() => {
    if (isOpen) {
      initProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Drawer
        style={{ top: 10 }}
        //closable={false}
        open={isOpen}
        onClose={handleCancel}
        //width={1000}
        placement={"right"}
        width="100VW"
        title={type}
        extra={
          <Button type="primary" loading={isLoading} onClick={handleOk}>
            Save
          </Button>
        }
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Cancel
        //   </Button>,
        //   <Button
        //     key="submit"
        //     type="primary"
        //     loading={isLoading}
        //     onClick={handleOk}
        //   >
        //     Save
        //   </Button>,
        // ]}
      >
        <Form
          form={form}
          preserve={false}
          layout="horizontal"
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="manifest_id">
            <Input />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              sm={24}
              md={test_request_package_list.length > 0 ? 14 : 24}
              //md={24}
            >
              <Form.Item
                label="Sample Type"
                name="sample_type"
                labelCol={{ span: 24 }}
                required
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Select>
                  {SAMPLE_TYPE_LIST.map((item, idx) => (
                    <Option key={`sample_type_${idx}`} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={{ xs: 0, sm: 0, md: 8 }}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Product Description"
                    name="product_description"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Finished or Intermediate Product"
                    name="finished_or_intermediate_product"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select>
                      <Option value={"Finished"}>Finished</Option>
                      <Option value={"Intermediate Product"}>
                        Intermediate Product
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Lot Or Batch Id"
                    name="lot_or_batch_id"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Confirm Lot Or Batch Id"
                    name="confirm_lot_or_batch_id"
                    dependencies={["lot_or_batch_id"]}
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("lot_or_batch_id") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The Lot Or Batch Id that you entered do not match!",
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Total Batch Size"
                    name="total_batch_size"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject();
                          }
                          if (isNaN(value)) {
                            return Promise.reject(
                              "This field has to be a number.",
                            );
                          }
                          if (value < 0) {
                            return Promise.reject("This field greater than 0");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      addonAfter={
                        <FormItemSuffix
                          name="batch_size_unit"
                          items={BATCH_SIZE_UNIT_LIST}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Total Weight Shipped"
                    name="total_weight"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      addonAfter={
                        <FormItemSuffix
                          name="weight_unit"
                          items={WEIGHT_UNIT_LIST}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Total Unit Shipped"
                    name="total_item"
                    labelCol={{ span: 24 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      addonAfter={
                        <FormItemSuffix
                          name="item_unit"
                          items={ITEM_UNIT_LIST}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {testRequest && (
              <Col xs={24} sm={24} md={10}>
                <Collapse
                  style={{
                    //maxHeight: 1000,
                    overflow: "auto",
                  }}
                  collapsible="icon"
                  items={[
                    {
                      key: "1",
                      label: (
                        <Flex gap={8}>
                          <div
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Test Request
                          </div>
                          {test_request_package_list &&
                          test_request_package_list.length > 0 ? (
                            <div style={{ flex: 1 }}>
                              <Form.Item
                                noStyle
                                label="Test Request"
                                name="test_request_code"
                                required
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={onChangeTestRequest}
                                >
                                  {test_request_package_list.map(
                                    (item, idx) => (
                                      <Option
                                        key={`test_request_code${idx}`}
                                        value={item.code}
                                      >
                                        {item.name}
                                      </Option>
                                    ),
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          ) : null}
                        </Flex>
                      ),

                      children: (
                        <div>
                          {testRequest.is_individual ? null : (
                            <>
                              <Flex
                                justify="space-between"
                                style={{
                                  marginBottom: 8,
                                }}
                              >
                                <Button
                                  color="teal"
                                  icon={<PlusOutlined />}
                                  title="Add Individual Test(s)"
                                  onClick={() => {
                                    setIsOpenExtraModal(true);
                                  }}
                                >
                                  Add Individual Test(s)
                                </Button>
                                <div>
                                  <Button
                                    danger
                                    icon={<ClearOutlined />}
                                    onClick={() => {
                                      setExtraTestRequest();
                                    }}
                                  ></Button>
                                </div>
                              </Flex>
                            </>
                          )}

                          <List
                            header={
                              testRequest.is_individual ? (
                                <Checkbox
                                  onChange={(e) => {
                                    onCheckAllChange(e);
                                  }}
                                  checked={
                                    testRequest.elements.findIndex(
                                      (x) => !x.selected,
                                    ) === -1
                                  }
                                >
                                  Check All
                                </Checkbox>
                              ) : null
                            }
                          >
                            {testRequest.elements.map((elm, idx2) => (
                              <List.Item
                                key={elm.code}
                                onClick={() => {
                                  if (!testRequest.is_individual) return;
                                  onCheckElement(elm);
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Checkbox
                                      value={elm.code}
                                      checked={elm.selected}
                                      disabled={!testRequest.is_individual}
                                    />
                                  }
                                  title={`${elm.name}`}
                                />
                                <div>{`${elm.unit_value ?? 2}${elm.unit}`}</div>
                              </List.Item>
                            ))}
                          </List>
                          {extraTestRequest && (
                            <>
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              >
                                Individual Test(s)
                              </Divider>
                              <List>
                                {extraTestRequest.elements
                                  .filter((x) => x.selected === true)
                                  .map((elm, idx2) => (
                                    <List.Item key={elm.code}>
                                      <List.Item.Meta
                                        avatar={
                                          <Checkbox
                                            value={elm.code}
                                            checked={true}
                                            disabled={true}
                                          />
                                        }
                                        title={`${elm.name}`}
                                      />
                                      <div>{`${elm.unit_value ?? 2}${elm.unit}`}</div>
                                    </List.Item>
                                  ))}
                              </List>
                            </>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  defaultActiveKey={["1"]}
                ></Collapse>
              </Col>
            )}
          </Row>
        </Form>
        {isOpenExtraModal && (
          <ManifestIndividualFormModal
            isOpen={isOpenExtraModal}
            model={extraTestRequest}
            package_list={CustomPackageList}
            onCancel={() => {
              setIsOpenExtraModal(false);
            }}
            onOk={(value) => {
              setIsOpenExtraModal(false);
              setExtraTestRequest(value);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default ManifestAddProductItemModal;
