import { Flex } from "antd";
import React from "react";
import ChatManagement from "../modules/chat/pages/ChatManagement";

export default function Main() {
  return (
    <>
      {/* <Flex justify="center">
        <div
          style={{
            width: 500,
            height: 100,
            gap: 16,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            width={200}
            loading="lazy"
            src="/static/logo_word.png"
            alt="logo"
          />
          <h3>Transport by aClassiA</h3>
        </div>
      </Flex> */}
      <ChatManagement />
    </>
  );
}
