import { Button, Checkbox, List, Modal, Select } from "antd";
import React, { useState } from "react";
const { Option } = Select;
const ManifestIndividualFormModal = ({
  model,
  package_list,
  isOpen,
  onOk,
  onCancel,
}) => {
  const [isLoading] = useState(false);
  const custom_package_list = [...package_list]; //deep copy
  const [testRequest, setTestRequest] = useState(() => {
    if (model != null) {
      return JSON.parse(JSON.stringify(model));
    } else {
      return JSON.parse(JSON.stringify(custom_package_list[0]));
    }
  });
  const handleCancel = () => {
    onCancel();
  };
  const handleOk = () => {
    if (
      !testRequest ||
      testRequest.elements.findIndex((x) => x.selected === true) === -1
    ) {
      onOk();
    } else {
      const data = JSON.parse(JSON.stringify(testRequest));
      onOk(data);
    }
  };
  const onCheckAllChange = (e) => {
    const _testRequest = { ...testRequest };
    const _elements = _testRequest.elements.map((x) => {
      x.selected = e.target.checked ? true : false;
      return x;
    });
    _testRequest.elements = [..._elements];

    _testRequest.unit_value =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.unit_value).reduce((a, b) => a + b)
        : 0;
    _testRequest.price =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.price).reduce((a, b) => a + b)
        : 0;
    setTestRequest(_testRequest);
  };
  const onCheckElement = (elm) => {
    const _testRequest = { ...testRequest };
    const idx = _testRequest.elements.findIndex((x) => x.code === elm.code);
    if (idx !== -1) {
      _testRequest.elements[idx].selected =
        _testRequest.elements[idx].selected === true ? false : true;
    }
    _testRequest.unit_value =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.unit_value).reduce((a, b) => a + b)
        : 0;
    _testRequest.price =
      _testRequest.elements.length > 0
        ? _testRequest.elements.map((x) => x.price).reduce((a, b) => a + b)
        : 0;
    setTestRequest(_testRequest);
  };

  const onChangeTestRequest = (test_request_code) => {
    const idx = custom_package_list.findIndex(
      (x) => x.code === test_request_code,
    );
    if (idx !== -1) {
      const pak = { ...custom_package_list[idx] };
      pak.elements = pak.elements.map((x) => {
        x.selected = !pak.is_individual;
        return x;
      });

      setTestRequest(pak);
    }
  };

  return (
    <Modal
      style={{ top: 10 }}
      maskClosable={false}
      closable={false}
      open={isOpen}
      title={"Add Individual Test(s)"}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <List
        header={
          <>
            <Select
              style={{
                width: "100%",
              }}
              defaultValue={testRequest.code}
              onChange={onChangeTestRequest}
            >
              {custom_package_list.map((item, idx) => (
                <Option key={`test_request_code${idx}`} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </>
        }
        bordered
      >
        <List.Item>
          <Checkbox
            onChange={(e) => {
              onCheckAllChange(e);
            }}
            checked={testRequest.elements.findIndex((x) => !x.selected) === -1}
          >
            Check All
          </Checkbox>
        </List.Item>
        {testRequest.elements.map((elm, idx2) => (
          <List.Item
            key={elm.code}
            onClick={() => {
              if (!testRequest.is_individual) return;
              onCheckElement(elm);
            }}
          >
            <List.Item.Meta
              avatar={
                <Checkbox
                  value={elm.code}
                  checked={elm.selected}
                  disabled={!testRequest.is_individual}
                />
              }
              title={`${elm.name}`}
            />
            <div>{`${elm.unit_value ?? 2}${elm.unit}`}</div>
          </List.Item>
        ))}
      </List>
    </Modal>
  );
};

export default ManifestIndividualFormModal;
