import React from "react";
import { useState } from "react";
import {
  ExclamationCircleFilled,
  PieChartOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Space,
  Input,
  Button,
  List,
  Flex,
  Descriptions,
  Statistic,
  Modal,
} from "antd";
import { Card, Col, Row } from "antd";
import HighText from "../../../components/HL";
import { GenData } from "../../../components/Description";
import Utils from "../../../utils/utils";
import axios from "../../../configs";
import { toast } from "react-toastify";
import ModalExpense from "../components/ModalExpense";
import { InvocieStatus, getInvoiceStatus } from "../invoiceUtils";
import ModalReviewInvoice from "../components/ModalReviewInvoice";

const InvoiceExpense = () => {
  const [search, setSearch] = useState();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");
  const query = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [isOpenModalView, setIsOpenModalView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [statusFilter, setStatusFilter] = useState({
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
    },
    search: "",
    status: "",
    sort: "",
  });
  const [summary, setSummary] = useState({
    total: 0,
    paid: 0,
    project: 0,
    overdue: 0,
  });

  const handleInit = () => {
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      fetchData();
      fetchSummary();
    }, 100);
  };

  const changeStatus = (status) => {
    debugger;
    // setStatusFilter(status);
    // setData([]);
    // // setStatusFilter({
    // //   ...statusFilter,
    // //   pagination: {
    // //     ...statusFilter.pagination,
    // //     current: 1,
    // //   },
    // // });
    // handleInit();
    setStatusFilter((prevStatusFilter) => ({
      ...prevStatusFilter,
      status,
      pagination: {
        ...prevStatusFilter.pagination,
        current: 1, // Reset to first page when status changes
      },
    }));
    setData([]); // Reset data to prevent duplicates
  };

  const onLoadMore = () => {
    setStatusFilter({
      ...statusFilter,
      pagination: {
        ...statusFilter.pagination,
        current: statusFilter.pagination.current + 1,
      },
    });
  };

  let reftimeout = React.useRef(null);
  const handleSearch = (value) => {
    setSearch(value);
    setData([]);
    // setCurrentPage(1);
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      if (value) {
        // fetchData(currentPage, value);
      } else {
        // fetchData(currentPage, "");
      }
    }, 1000);
  };

  const fetchSummary = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(`/bills/${company_id}/expense/summary`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          let listUser = response.data.result;

          setSummary(listUser);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  const fetchData = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await axios
        .get(`/bills/${company_id}/expense/list`, {
          params: {
            page: statusFilter.pagination?.current,
            per_page: statusFilter.pagination?.pageSize,
            sort: statusFilter.sort,
            search: statusFilter.search,
            //status: statusFilter,
            status: statusFilter.status,
          },
          headers: { Authorization: token },
        })
        .then((response) => {
          let listUser = response.data.result.rows;

          if (listUser.length === 0 && statusFilter.pagination.current === 1) {
            setData([]);
          } else {
            setData((state) => state.concat(listUser));
          }
          setIsLoading(false);
          setStatusFilter({
            ...statusFilter,
            pagination: {
              ...statusFilter.pagination,
              total: response.data.result.totalCount,
            },
            search: statusFilter?.search,
          });
          toast.success(response.data.result);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  React.useEffect(() => {
    debugger;
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter?.status, statusFilter?.pagination?.current]);
  //}, [statusFilter, statusFilter?.pagination?.current]);

  const loadMore =
    !isLoading && data.length < statusFilter?.pagination?.total ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  const confirmPaidInvoice = async (record) => {
    try {
      setIsLoading(true);
      await axios
        .post(`/bills/${company_id}/expense/paid`, record, {
          headers: { Authorization: token },
        })
        .then((response) => {
          toast.success(response.data.result);
          setIsLoading(false);
          changeStatus("");
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const { confirm } = Modal;
  const showConfirm = (record) => {
    confirm({
      title: "Do you want to this invoice as paid?",
      icon: <ExclamationCircleFilled />,
      content: "",
      onOk() {
        confirmPaidInvoice(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const actions = (record) => {
    let result = (
      <>
        <Button
          type="primary"
          shape="round"
          onClick={(event) => {
            setSelectedItem(record);
            setIsOpenModalView(true);
          }}
          size={"large"}
        >
          Detail
        </Button>
        {record.status < 2 && (
          <Button
            type="primary"
            shape="round"
            onClick={(event) => {
              showConfirm(record);
            }}
            size={"large"}
          >
            Payment out
          </Button>
        )}
      </>
    );
    return result;
  };

  return (
    <>
      <div className="invoice">
        <div style={{ marginLeft: "8px", marginRight: "8px" }}>
          <h3 className="title">Expense Management</h3>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter.status === "total"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter.status === "total"
                      ? "2px solid green"
                      : "none",
                }}
                onClick={() => {
                  changeStatus("total");
                }}
              >
                <Statistic
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Space>Total</Space>
                      <PieChartOutlined />
                    </div>
                  }
                  value={summary.total}
                  precision={0}
                  valueStyle={{
                    color: InvocieStatus.NEW.color,
                  }}
                />
                <Space>{Utils.formatMoney(summary.value_total)} in total</Space>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter.status === "paid"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter.status === "paid" ? "2px solid green" : "none",
                }}
                onClick={() => {
                  changeStatus("paid");
                }}
              >
                <Statistic
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: InvocieStatus.PAID.color,
                      }}
                    >
                      <Space>Paid</Space>
                      <PieChartOutlined />
                    </div>
                  }
                  value={summary.paid}
                  precision={0}
                  valueStyle={{
                    color: InvocieStatus.PAID.color,
                  }}
                />
                <Space>{Utils.formatMoney(summary.value_paid)} in total</Space>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter.status === "project"}
                style={{
                  cursor: "project",
                  border:
                    statusFilter.status === "project"
                      ? "2px solid green"
                      : "none",
                }}
                onClick={() => {
                  changeStatus("project");
                }}
              >
                <Statistic
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: InvocieStatus.PROJECTED.color,
                      }}
                    >
                      <Space>Project</Space>
                      <PieChartOutlined />
                    </div>
                  }
                  value={summary.project}
                  precision={0}
                  valueStyle={{
                    color: InvocieStatus.PROJECTED.color,
                  }}
                />
                <Space>
                  {Utils.formatMoney(summary.value_project)} in total
                </Space>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter.status === "overdue"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter.status === "overdue"
                      ? "2px solid green"
                      : "none",
                }}
                onClick={() => {
                  changeStatus("overdue");
                }}
              >
                <Statistic
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: InvocieStatus.OVERDUE.color,
                      }}
                    >
                      <Space>Over due</Space>
                      <PieChartOutlined />
                    </div>
                  }
                  value={summary.overdue}
                  precision={0}
                  valueStyle={{
                    color: InvocieStatus.OVERDUE.color,
                  }}
                />
                <Space>
                  {Utils.formatMoney(summary.value_overdue)} in total
                </Space>
              </Card>
            </Col>
          </Row>
        </div>
        <Space
          wrap
          style={{
            display: "flex",
            padding: "0 1rem",
            marginTop: "10px",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Input
            onInput={(e) => setSearch(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
            placeholder="Search number"
          />
          <Button
            type="primary"
            shape="round"
            onClick={(event) => {
              setIsOpenModalDetail(true);
              setSelectedItem({});
            }}
            loading={isLoading}
            size={"large"}
          >
            New Expense
          </Button>
        </Space>

        <List
          loading={isLoading}
          style={{ flex: 1, overflow: "auto", paddingTop: 16 }}
          loadMore={loadMore}
          grid={{ column: 1 }}
          // dataSource={data}
          dataSource={data.filter(
            (x) =>
              !search ||
              x.expense_number.toLowerCase().includes(search.toLowerCase()),
          )}
          renderItem={(record) => {
            let statusItem = getInvoiceStatus(record.status);
            return (
              <List.Item>
                <Card hoverable onClick={() => {}}>
                  <Flex gap={16} justify="space-between">
                    <Card.Meta
                      onClick={(event) => {}}
                      title={
                        <>
                          <HighText search={search}>
                            {record.expense_number}
                          </HighText>
                        </>
                      }
                      description={
                        <Descriptions
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 4,
                            xxl: 4,
                          }}
                          items={GenData({
                            Status: statusItem,
                            Total: (
                              <HighText search={search}>
                                {record.total_amount.toString()}
                              </HighText>
                            ),
                            "Due date": (
                              <HighText>
                                {Utils.formatDateTime(record.due_date)}
                              </HighText>
                            ),
                            Description: <HighText>{record.notes}</HighText>,
                          })}
                        />
                      }
                    ></Card.Meta>
                  </Flex>
                  <Space size="middle" wrap={true}>
                    {actions(record)}
                  </Space>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
      {isOpenModalView && (
        <ModalReviewInvoice
          item={selectedItem}
          isOpen={isOpenModalView}
          onCancel={() => {
            setIsOpenModalView(false);
          }}
          onOk={(item) => {
            // setSelectedItem(item);
            setIsOpenModalView(false);
            setIsOpenModalDetail(true);
          }}
        ></ModalReviewInvoice>
      )}
      {isOpenModalDetail && (
        <ModalExpense
          item={selectedItem}
          isOpen={isOpenModalDetail}
          onCancel={() => {
            setIsOpenModalDetail(false);
          }}
          onOk={() => {
            setData([]);
            setStatusFilter({
              ...statusFilter,
              pagination: {
                ...statusFilter.pagination,
                current: 1,
              },
            });
            handleInit();
            handleInit();
            setIsOpenModalDetail(false);
          }}
        ></ModalExpense>
      )}
    </>
  );
};
export default InvoiceExpense;
