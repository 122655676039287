import React from "react";
import { Card, Row, Col, Flex, Image } from "antd";
import Utils from "../../utils/utils";

const ManifestTransportationEntityView = ({ model, loading, editable }) => {
  // TODO: get signature image
  const external_token = localStorage.getItem("external_token");
  const company_id = localStorage.getItem("auth_company_id");

  const getSignatureByid = (signature_id) => {
    const urlSign1 = `${Utils.ApiEndpoint}/signature/${company_id}/read?id=${signature_id}&token=${external_token}`;
    return urlSign1;
  };
  return (
    <>
      <Card style={{ width: "100%" }} loading={loading}>
        {model ? (
          <>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Company Name:</strong>
              </Col>
              <Col span={6}>{model.company_name ?? "--"}</Col>

              <Col span={6}>
                <strong>Company Phone:</strong>
              </Col>
              <Col span={6}>{model.company_phone ?? "--"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 2 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Vehicle Make:</strong>
              </Col>
              <Col span={6}>{model.vehicle_make ?? "--"}</Col>

              <Col span={6}>
                <strong>Vehicle Model: </strong>
              </Col>
              <Col span={6}>{model.vehicle_model ?? "--"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 3 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>License Plate #:</strong>
              </Col>
              <Col span={6}>{model.license_plate_number ?? "--"}</Col>

              <Col span={6}>
                <strong>State on License Plate </strong>
              </Col>
              <Col span={6}>{model.state_on_license_plate ?? "--"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 4 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Pre-determined Travel Route:</strong>
              </Col>
              <Col span={18}>{model.pre_determined_travel_route ?? "--"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 5 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Employee #1 Name:</strong>
              </Col>
              <Col span={6}>{model.employee_1_name ?? "--"}</Col>

              <Col span={6}>
                <strong>Signature & Date: </strong>
              </Col>
              <Col span={6}>
                {model.info_signature_driver_1 &&
                  model.info_signature_driver_1.length > 0 && (
                    <Flex gap={10}>
                      {/* TODO: image */}
                      <Flex style={{ height: 50, width: 150 }} justify="center">
                        <Image
                          height={50}
                          src={getSignatureByid(
                            model.info_signature_driver_1[0].signature_id,
                          )}
                        />
                      </Flex>
                      {/* TODO: signature date */}
                      <Flex justify="center" vertical>
                        {Utils.formatDate(
                          model.info_signature_driver_1[0].created_at,
                        )}
                      </Flex>
                    </Flex>
                  )}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 6 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Employee #1 Driver’s License #</strong>
              </Col>
              <Col span={6}>
                {/* {model.list_license_for_driver1 &&
                  model.list_license_for_driver1.length > 0 &&
                  (model.list_license_for_driver1[0].license_number ?? "_ _")} */}
                {model.employee_1_driver_license_number ?? "_ _"}
              </Col>

              <Col span={6}>
                <strong>Driver’s License Issuing State </strong>
              </Col>
              <Col span={6}>
                {/* {model.list_license_for_driver1 &&
                  model.list_license_for_driver1.length > 0 &&
                  (model.list_license_for_driver1[0].issue_state ?? "_ _")} */}
                {model.employee_1_issuing_state ?? "_ _"}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 7 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Employee #2 Name:</strong>
              </Col>
              <Col span={6}>{model.employee_2_name ?? "--"}</Col>

              <Col span={6}>
                <strong>Signature & Date: </strong>
              </Col>
              <Col span={6}>
                {model.info_signature_driver_2 &&
                  model.info_signature_driver_2.length > 0 && (
                    <Flex gap={10}>
                      {/* TODO: image */}
                      <Flex style={{ height: 50, width: 150 }} justify="center">
                        <Image
                          height={50}
                          src={getSignatureByid(
                            model.info_signature_driver_2[0].signature_id,
                          )}
                        />
                      </Flex>
                      {/* TODO: signature date */}
                      <Flex justify="center" vertical>
                        {Utils.formatDate(
                          model.info_signature_driver_2[0].created_at,
                        )}
                      </Flex>
                    </Flex>
                  )}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 8 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Employee #2 Driver’s License #</strong>
              </Col>
              <Col span={6}>
                {/* {model.list_license_for_driver2 &&
                  model.list_license_for_driver2.length > 0 &&
                  (model.list_license_for_driver2[0].license_number ?? "_ _")} */}
                {model.employee_2_driver_license_number ?? "_ _"}
              </Col>

              <Col span={6}>
                <strong>Driver’s License Issuing State </strong>
              </Col>
              <Col span={6}>
                {/* {model.list_license_for_driver2 &&
                  model.list_license_for_driver2.length > 0 &&
                  (model.list_license_for_driver2[0].issue_state ?? "_ _")} */}
                {model.employee_2_issuing_state ?? "_ _"}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 9 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Details of any Existing Circumstances:</strong>
              </Col>
              <Col span={18}>
                {model.details_of_any_existing_circumstances ?? "--"}
              </Col>
            </Row>
            <hr style={{ opacity: "10%" }} />
            {/* row 10 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Note:</strong>
              </Col>
              <Col span={18}>{model.note ?? "--"}</Col>
            </Row>
          </>
        ) : (
          <p>No transport data</p>
        )}
      </Card>
    </>
  );
};

export default ManifestTransportationEntityView;
