import { ClearOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Input, Modal, Row, Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

import SignatureCanvas from "react-signature-canvas";
import { API } from "../configs";
import Utils from "../utils/utils";

const ModalSignatureCanvas = ({
  title,
  onOk,
  onCancel,
  isOpen,
  width,
  displayInput,
  reasonLabel,
  comment = "",
}) => {
  const targetRef = useRef();
  const resize = useResizeDetector({ targetRef });
  const rWidth = resize.width;
  const rHeight = resize.height;
  const [isDrawSignature, setIsDrawSignature] = useState(true);
  const [signatureResult, setSignatureResult] = useState();
  const [signatureList, setSignatureList] = useState(Array(3).fill(null));
  const [loadSignatureList, setLoadSignatureList] = useState(true);
  const [reason, setReason] = useState(comment);
  const sigCanvas = useRef();
  const isInputValidIfRequired = (displayInput && !!reason) || !displayInput;
  const isSignatureValid = !!signatureResult;
  const isFormValid = isInputValidIfRequired && isSignatureValid;
  const external_token = localStorage.getItem("external_token");
  const companyIdToken = localStorage.getItem("auth_company_id");

  const setReasonOnChange = (event) => {
    setReason(event.target.value);
  };

  const setSignatureOnChange = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    setSignatureResult({
      signature_id: null,
      signature_data: dataURL,
    });
  };

  const clearInput = () => {
    if (!isDrawSignature) {
      setIsDrawSignature(true);
    } else {
      sigCanvas.current.clear();
    }
    setSignatureResult();
  };

  const handleOk = () => {
    const _now = new Date();
    const _timezone = _now.getTimezoneOffset();
    const data = {
      ...signatureResult,
      reason_for_change: reason,
      timezone: _timezone,
    };
    onOk(data);
  };
  const handleCancel = () => {
    onCancel();
  };
  const onSelectSignature = (item) => {
    setSignatureResult(item);
    setIsDrawSignature(false);
  };

  useEffect(() => {
    function getSignatureList() {
      API.profile
        .get_signature_list()
        .then((resp) => {
          const signatureData = resp.data.result;
          if (signatureData != null && signatureData.length > 0) {
            var data = [];
            for (var i = 0; i < 3; i++) {
              if (signatureData[i]) {
                data.push(signatureData[i]);
              } else {
                data.push(null);
              }
            }
            setSignatureList(data);
          }
        })
        .finally((x) => setLoadSignatureList(false));
    }
    getSignatureList();
  }, []);

  // TODO: get signature image
  const getSignatureByid = (signature_id) => {
    const urlSign1 = `${Utils.ApiEndpoint}/signature/${companyIdToken}/read?id=${signature_id}&token=${external_token}`;
    return urlSign1;
  };

  return (
    <Modal
      style={{ top: 10 }}
      maskClosable={false}
      closable={false}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width ?? 550}
      okButtonProps={{ disabled: !isFormValid }}
      title={title ?? "Confirmation"}
      footer={[
        <Button size={"large"} key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          size={"large"}
          key="submit"
          onClick={handleOk}
          disabled={!isFormValid}
        >
          Submit
        </Button>,
      ]}
    >
      <div className="sigPadContainer" ref={targetRef}>
        {isDrawSignature && (
          <SignatureCanvas
            canvasProps={{
              className: "sigCanvas",
              width: rWidth,
              height: rHeight,
            }}
            ref={sigCanvas}
            onEnd={setSignatureOnChange}
          />
        )}
        {!isDrawSignature && (
          <div
            style={{
              width: rWidth,
              height: rHeight,
              position: "relative",
            }}
          >
            <img
              alt="signature"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "180px",
              }}
              //src={signatureResult.signature_data}
              src={getSignatureByid(signatureResult.signature_id)}
            />
          </div>
        )}
      </div>
      <div style={{ width: rWidth }}>
        <hr style={{ marginTop: 0 }} />
        <div>
          <Flex justify="space-between">
            <Flex flex={1}>
              <Row style={{ width: "100%" }} gutter={[4, 4]}>
                {signatureList.map((val, idx) => {
                  return (
                    <Col
                      key={`signature_item_${idx}`}
                      span={8}
                      style={{
                        height: 60,
                      }}
                      onClick={() => (val ? onSelectSignature(val) : null)}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: 50,
                          paddingTop: 10,
                          paddingBottom: 10,
                          border: `1px solid ${signatureResult && signatureResult?.signature_id === val?.signature_id ? "#1677ff" : "rgba(0, 0, 0, .12)"}`,
                          borderRadius: 4,
                          // backgroundColor:
                          //     "rgb(238 235 235)",
                        }}
                      >
                        {val && (
                          <img
                            //src={val.signature_data}
                            src={getSignatureByid(val.signature_id)}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              maxWidth: "100%",
                              maxHeight: 50,
                              display: "block",
                              margin: "0 auto",
                            }}
                            alt="Signature"
                            // style="text-align: center; max-width: 100%; max-height: 60px;display: block;margin: 0 auto;"  alt="Signature Image"
                          />
                        )}
                        {loadSignatureList && (
                          <div
                            style={{
                              textAlign: "center",
                              margin: "15px 0",
                            }}
                          >
                            <Spin size="small" />
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Flex>
            <Button
              size="small"
              type="default"
              danger
              icon={<ClearOutlined />}
              onClick={clearInput}
            ></Button>
          </Flex>
        </div>
      </div>
      {displayInput && (
        <div className="reason_input">
          <Typography.Title level={5} style={{ marginTop: 16 }}>
            {reasonLabel && reasonLabel !== "" ? `${reasonLabel}:` : "Comment:"}
          </Typography.Title>
          <Input type="text" onChange={setReasonOnChange} value={reason} />
        </div>
      )}
    </Modal>
  );
};

export default ModalSignatureCanvas;
