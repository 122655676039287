import React from "react";
import axios from "../../../configs";
const PageGoogleDriveRequestCallback = () => {
  let code = (window.location.search.match(/code=([^&]+)/) || [])[1];
  let state = (window.location.search.match(/state=([^&]+)/) || [])[1];
  let scope = (window.location.search.match(/scope=([^&]+)/) || [])[1];

  React.useEffect(() => {
    const closeWindow = () => {
      window.open("", "_self").close();
    };

    if (code && state) {
      axios.get(
        `/google-drive/callback/grant?code=${code}&state=${state}&scope=${scope}`,
      );
    } else {
      try {
        closeWindow();
      } catch (error) {}
    }
  }, [state]);

  return (
    <>
      <div>Redirect to request google drive.....</div>
    </>
  );
};
export default PageGoogleDriveRequestCallback;
