const BATCH_SIZE_UNIT_LIST = [
  { value: "gram", label: "gram" },
  { value: "ounce", label: "ounce" },
  { value: "pound (lbs)", label: "pound (lbs)" },
  { value: "count", label: "count" },
];
const WEIGHT_UNIT_LIST = [
  { value: "gram", label: "gram" },
  { value: "ounce", label: "ounce" },
  { value: "pound (lbs)", label: "pound (lbs)" },
];
const ITEM_UNIT_LIST = [{ value: "count", label: "count" }];
const SAMPLE_TYPE_LIST = [
  { value: 1, label: "Flower" },
  { value: 2, label: "Concentrate" },
  { value: 3, label: "Edible" },
  { value: 4, label: "Other" },
];

const ORDER_TYPE_LIST = [
  { value: 1, label: "R&D", color: "green" },
  { value: 2, label: "Compliant", color: "blue" },
];

const LICENSEE_TYPES = [
  { code: "AUCP", name: "AUCP - Processor" },
  { code: "Microbusiness", name: "Microbusiness" },
  { code: "AUCC", name: "AUCC - Grower or Cultivator" },
  { code: "PermittedLab", name: "Permitted Lab" },
  { code: "PermittedSample", name: "Permitted Sample" },
  { code: "Other", name: "Other" },
];
const LICENSEE_TYPES_ENUMS = {
  AUCP: "AUCP",
  AUCC: "AUCC",
  PermittedLab: "PermittedLab",
  PermittedSample: "PermittedSample",
  Microbusiness: "Microbusiness",
  Other: "Other",
};

// TODO: Invoice
const DISCOUNT_TYPE_LIST = [
  { value: "1", label: "USD" },
  { value: "2", label: "%" },
];

const CURRENCY_UNIT_LIST = [
  { value: 1, label: "USD" },
  //{ value: 2, label: "EUR" },
];

const PAYMENT_TERMS_LIST = [
  { value: "1", label: "Due Immediately" },
  { value: "2", label: "Net15" },
  { value: "3", label: "Net30" },
];

const con = {
  BATCH_SIZE_UNIT_LIST,
  WEIGHT_UNIT_LIST,
  ITEM_UNIT_LIST,
  SAMPLE_TYPE_LIST,
  ORDER_TYPE_LIST,
  LICENSEE_TYPES,
  LICENSEE_TYPES_ENUMS,
  DISCOUNT_TYPE_LIST,
  CURRENCY_UNIT_LIST,
  PAYMENT_TERMS_LIST,
};

export default con;

export const AppAction = {
  ORIGINATION_DETAIL_EDITOR: "origination_detail_editor",
  TRANSPORT_DETAIL_EDITOR: "transport_detail_editor",
  DESTINATION_DETAIL_EDITOR: "destination_detail_editor",
  PRODUCT_DETAIL_EDITOR: "product_detail_editor",
  SUPER_EDITOR: "super_editor",
  ORDER_ARCHIVAL: "order_archival",
  UPLOAD_GOOGLE_DRIVE: "upload_google_drive",
  VIEW_VIDEO_GOOGLE_DRIVE: "view_video_google_drive",
};
