import React, { useRef, useState } from "react";
import { Button, Image, Modal, Tabs, Space } from "antd";
import Utils from "../utils/utils";
import {
  InboxOutlined,
  UploadOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { message, Upload } from "antd";
import { useResizeDetector } from "react-resize-detector";
import SignatureCanvas from "react-signature-canvas";

const CreateSignatureModal = ({ open, onCancel, onOk }) => {
  const external_token = localStorage.getItem("external_token");
  const companyId = localStorage.getItem("auth_company_id");
  const [imageInfo, setImageInfo] = useState();
  const [currentTab, setCurrentTab] = useState("import");

  const sigCanvas = useRef();
  const targetRef = useRef();
  const [signatureResult, setSignatureResult] = useState();
  const resize = useResizeDetector({ targetRef });
  const rWidth = resize.width;
  const rHeight = resize.height;
  //const [isDrawSignature, setIsDrawSignature] = useState(true);
  //TODO: Image Info
  /*
    media_id:'',
    file_name:'',
    uri:'',
    thumb_uri:'',
    ...
  */
  const onChangeTab = (key) => {
    console.log(key);
    setCurrentTab(key);
  };

  // send data for parent modal
  const handleOk = () => {
    debugger;
    if (currentTab === "import") {
      const data = {
        company_id: companyId,
        type: "import",
        media_info: {
          media_id: imageInfo.media_id,
          file_name: imageInfo.file_name,
          extension: imageInfo.extension,
          content_type: imageInfo.content_type,
          //uri: imageInfo.uri,
        },
      };
      onOk(data);
    }
    if (currentTab === "draw") {
      const _now = new Date();
      const _timezone = _now.getTimezoneOffset();
      const data = {
        type: "draw",
        signature_info: {
          ...signatureResult,
          reason_for_change: "create signature template",
          timezone: _timezone,
        },
      };
      onOk(data);
    }
  };
  //-----------------------

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(null);
    });
  const handleUploadImage = async (resp, file) => {
    const uri = await getBase64(file.originFileObj);
    setImageInfo({ ...resp, uri: uri });
  };
  // TODO: Tab import
  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false, // only 1 image
    accept: "image/*",
    action: `${Utils.ApiEndpoint}/media/${companyId}/single_upload`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // setImageInfo({
        //   id:
        // })
        const resp = info.file.response.result;
        handleUploadImage(resp, info.file);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setImageInfo();
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  //--------------------
  // TODO: tab draw
  const setSignatureOnChange = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    setSignatureResult({
      signature_id: null,
      signature_data: dataURL,
    });
  };

  const clearInput = () => {
    sigCanvas.current.clear();
    setSignatureResult();
  };
  //-------------

  return (
    <Modal
      title={"Create Signature"}
      centered
      open={open}
      onCancel={onCancel}
      //width={900}
      //style={{ height: 600 }}
      //width={width ?? 550}
      width={550}
      onOk={handleOk}
      //footer={null}
    >
      <Tabs
        onChange={onChangeTab}
        activeKey={currentTab}
        type="card"
        items={[
          {
            label: "Import",
            key: "import",
            children: (
              <>
                <Upload
                  className="custom-ant-upload"
                  {...props}
                  itemRender={(originNode, file) => <></>}
                  style={{ width: "100%" }}
                >
                  {imageInfo ? (
                    <Button type="link" style={{ float: "right" }}>
                      Replace
                    </Button>
                  ) : (
                    // </div>
                    <div className="ant-upload-drag">
                      <div
                        style={{
                          height: "200px",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single upload.
                        </p>
                      </div>
                    </div>
                  )}
                </Upload>
                {/* TODO: VIEW */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {imageInfo && <Image src={imageInfo.uri} height={200} />}
                </div>
              </>
            ),
          },
          {
            label: "Draw",
            key: "draw",
            children: (
              <div
                className="sigPadContainer"
                ref={targetRef}
                //style={{ height: "300px", overflowY: "auto", width: "800px" }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 9999,
                  }}
                >
                  <Button
                    size="small"
                    type="default"
                    danger
                    icon={<ClearOutlined />}
                    onClick={clearInput}
                  ></Button>
                </div>
                <SignatureCanvas
                  canvasProps={{
                    className: "sigCanvas",
                    width: rWidth,
                    height: rHeight,
                  }}
                  ref={sigCanvas}
                  onEnd={setSignatureOnChange}
                />
              </div>
            ),
          },
        ]}
      />
    </Modal>
    //    <Dragger {...props} itemRender={(originNode, file) => <></>}>
    //    <div style={{ height: "300px", overflowY: "auto" }}>
    //      <p className="ant-upload-drag-icon">
    //        <InboxOutlined />
    //      </p>
    //      <p className="ant-upload-text">
    //        Click or drag file to this area to upload
    //      </p>
    //      <p className="ant-upload-hint">
    //        Support for a single or bulk upload. Strictly prohibited
    //        from uploading company data or other banned files.
    //      </p>
    //    </div>
    //  </Dragger>
  );
};

export default CreateSignatureModal;
