import { Tag } from "antd";

export const InvocieStatus = {
  NEW: {
    color: "#8291c1",
    name: "NEW",
    key: 0,
  },
  SENT: {
    color: "#e5c732",
    name: "SENT",
    key: 1,
  },
  PAID: {
    color: "#14c7b9",
    name: "PAID",
    key: 2,
  },
  PROJECTED: {
    color: "#5b0099",
    name: "PROJECTED",
    key: 70,
  },
  OVERDUE: {
    color: "#ff1958",
    name: "OVER DUE",
    key: 3,
  },
};
export const getInvoiceStatus = (value) => {
  if (value === InvocieStatus.NEW.key)
    return (
      <Tag
        style={{ width: "auto", whiteSpace: "normal" }}
        color={InvocieStatus.NEW.color}
      >
        NEW
      </Tag>
    );
  if (value === InvocieStatus.SENT.key)
    return (
      <Tag
        style={{ width: "auto", whiteSpace: "normal" }}
        color={InvocieStatus.SENT.color}
      >
        SENT
      </Tag>
    );
  if (value === InvocieStatus.PAID.key)
    return (
      <Tag
        style={{ width: "auto", whiteSpace: "normal" }}
        color={InvocieStatus.PAID.color}
      >
        PAID
      </Tag>
    );
  else return <></>;
};

export const generateInvoiceNumber = (prefix) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const milliseconds = date.getMilliseconds();
  const millisecondLetter = String.fromCharCode(
    65 + Math.floor(milliseconds / (1000 / 26)),
  ); // Chuyển đổi từ 0-1000 thành A-Z

  return `${prefix}${year}${month}${day}${hours}${minutes}${seconds}${millisecondLetter}`;
};
