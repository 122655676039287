import React from "react";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Space,
  Input,
  Button,
  List,
  Flex,
  Descriptions,
  Statistic,
  Table,
} from "antd";
import { Card, Col, Row } from "antd";
import HighText from "../../../components/HL";
import { GenData } from "../../../components/Description";
import Utils from "../../../utils/utils";
import axios from "../../../configs";
import { toast } from "react-toastify";
import ModalBill from "../components/ModalBill";
import { getInvoiceStatus } from "../invoiceUtils";

const InvoiceBills = () => {
  const [search, setSearch] = useState("");
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  let company_id = localStorage.getItem("auth_company_id");
  const query = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [statusFilter, setStatusFilter] = useState({
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
    },
    search: "",
    status: "",
    sort: "",
  });

  const handleInit = () => {
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      fetchData();
    }, 100);
  };

  const changeStatus = (status) => {
    setStatusFilter(status);
    setData([]);
    setStatusFilter({
      ...statusFilter,
      pagination: {
        ...statusFilter.pagination,
        current: 1,
      },
    });
    handleInit();
  };

  const onLoadMore = () => {
    setStatusFilter({
      ...statusFilter,
      pagination: {
        ...statusFilter.pagination,
        current: statusFilter.pagination.current + 1,
      },
    });
  };

  let reftimeout = React.useRef(null);
  const handleSearch = (value) => {
    setSearch(value);
    setData([]);
    // setCurrentPage(1);
    if (reftimeout.current) {
      clearTimeout(reftimeout.current);
    }
    reftimeout.current = setTimeout(() => {
      if (value) {
        // fetchData(currentPage, value);
      } else {
        // fetchData(currentPage, "");
      }
    }, 1000);
  };

  const fetchData = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await axios
        .get(`/bills/${company_id}/bill/list`, {
          params: {
            page: statusFilter.pagination?.current,
            per_page: statusFilter.pagination?.pageSize,
            sort: statusFilter.sort,
            search: statusFilter.search,
          },
          headers: { Authorization: token },
        })
        .then((response) => {
          let listUser = response.data.result.rows;

          if (listUser.length === 0 && statusFilter.pagination.current === 1) {
            setData([]);
          } else {
            setData((state) => state.concat(listUser));
          }
          setIsLoading(false);
          setStatusFilter({
            ...statusFilter,
            pagination: {
              ...statusFilter.pagination,
              total: response.data.result.totalCount,
            },
            search: statusFilter?.search,
          });
          toast.success(response.data.result);
        });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  React.useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter?.status, statusFilter?.pagination?.current]);

  const loadMore =
    !isLoading && data.length < statusFilter?.pagination?.total ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  const actions = (record) => {
    let result = (
      <>
        <Button
          type="primary"
          shape="round"
          onClick={(event) => {
            setSelectedItem(record);
            setIsOpenModalDetail(true);
          }}
          size={"large"}
        >
          View
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={(event) => {}}
          size={"large"}
        >
          Pay
        </Button>
      </>
    );
    return result;
  };

  return (
    <>
      <div className="invoice">
        <div style={{ marginLeft: "8px", marginRight: "8px" }}>
          <h3 className="title">Bill Management</h3>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter === "available"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter === "available" ? "2px solid green" : "none",
                }}
                onClick={() => {
                  changeStatus("available");
                }}
              >
                <Statistic
                  title="Total Bills"
                  value={0}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter === "ongoing"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter === "ongoing" ? "2px solid green" : "none",
                }}
                onClick={() => {
                  changeStatus("ongoing");
                }}
              >
                <Statistic
                  title="Paid"
                  value={0}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter === "finished"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter === "finished" ? "2px solid green" : "none",
                }}
                onClick={() => {
                  changeStatus("finished");
                }}
              >
                <Statistic
                  title="Pending"
                  value={0}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6} span={8}>
              <Card
                bordered={statusFilter === "declined"}
                style={{
                  cursor: "pointer",
                  border:
                    statusFilter === "declined" ? "2px solid green" : "none",
                }}
                onClick={() => {
                  changeStatus("declined");
                }}
              >
                <Statistic
                  title="Over due"
                  value={0}
                  precision={0}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
        <Space
          wrap
          style={{
            display: "flex",
            padding: "0 1rem",
            marginTop: "10px",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Input
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: 250 }}
            prefix={<SearchOutlined />}
            placeholder="Search Order Number"
          />
          <Button
            type="primary"
            shape="round"
            onClick={(event) => {
              setIsOpenModalDetail(true);
              setSelectedItem({});
            }}
            loading={isLoading}
            size={"large"}
          >
            New Bill
          </Button>
        </Space>

        <List
          loading={isLoading}
          style={{ flex: 1, overflow: "auto", paddingTop: 16 }}
          loadMore={loadMore}
          grid={{ column: 1 }}
          dataSource={data}
          renderItem={(record) => {
            let statusItem = getInvoiceStatus(record.status);
            return (
              <List.Item>
                <Card hoverable>
                  <Flex gap={16} justify="space-between">
                    <Card.Meta
                      onClick={(event) => {}}
                      title={
                        <>
                          <HighText search={search}>{record.title}</HighText>
                        </>
                      }
                      description={
                        <Descriptions
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 4,
                            xxl: 4,
                          }}
                          items={GenData({
                            Status: statusItem,
                            Total: (
                              <HighText search={search}>
                                {record.total_amount.toString()}
                              </HighText>
                            ),
                            "Due date": (
                              <HighText>
                                {Utils.formatDateTime(record.due)}
                              </HighText>
                            ),
                            Description: <HighText>{record.notes}</HighText>,
                          })}
                        />
                      }
                    ></Card.Meta>
                  </Flex>
                  <Space size="middle" wrap={true}>
                    {actions(record)}
                  </Space>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
      {isOpenModalDetail && (
        <ModalBill
          item={selectedItem}
          isOpen={isOpenModalDetail}
          onCancel={() => {
            setIsOpenModalDetail(false);
          }}
          onOk={() => {
            setIsOpenModalDetail(false);
          }}
        ></ModalBill>
      )}
    </>
  );
};
export default InvoiceBills;
