import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Space, Table } from "antd";
import constants from "../../utils/constants";
import TestRequestModalView from "../../modules/origination/components/TestRequestModalView";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ManifestAPI } from "../../modules/ManifestAPI";
import ManifestAddProductItemModal from "./ManifestAddProductItemModal";
const ManifestProductEntityView = ({
  model,
  order_number,
  loading,
  editable,
  company_id,
}) => {
  const { Column } = Table;
  const [slcPackage, setSlcPackage] = useState();
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState();
  const [productEdit, setProductEdit] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState();

  const SAMPLE_TYPE_LIST = constants.SAMPLE_TYPE_LIST;
  const onShowItemDetail = (record) => {
    setSlcPackage(record);
  };
  // useEffect(()=>{
  //   const data = model.sort((a,b) => (a.sort_index > b.sort_index) ? 1: -1);
  //   setData(data);
  // },[]);

  const getConfig = () => {
    setIsLoading(true);
    new ManifestAPI()
      .getOriginationConfig()
      .then(
        (response) => {
          const configs = response.data.result;
          setConfig(configs);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sort_product = (raw_products) => {
    if (raw_products && raw_products.length > 0) {
      const _data = raw_products.sort((a, b) =>
        a.sort_index > b.sort_index ? 1 : -1,
      );
      return _data;
    }
    return [];
  };
  useEffect(() => {
    if (model && model.length > 0) {
      const _model = [...model];
      // const _data = _model.sort((a, b) =>
      //   a.sort_index > b.sort_index ? 1 : -1,
      // );
      const _data = sort_product(_model);
      setData(_data);
    } else {
      setData([]);
    }
  }, [model]);
  useEffect(() => {
    getConfig();
    if (!loading) {
      // console.log("model", model);
      // if (model && model.length > 0) {
      //   const _model = [...model];
      //   // const _data = _model.sort((a, b) =>
      //   //   a.sort_index > b.sort_index ? 1 : -1,
      //   // );
      //   const _data = sort_product(_model);
      //   setData(_data);
      // }
    }
  }, [loading]);

  const handleEditProduct = (product) => {
    console.log(product);
    setEditMode("products");
    setProductEdit(product);
  };

  // remove product
  const handleRemoveProduct = (product, idx) => {
    if (isLoading) return;
    Modal.confirm({
      title: `Do you want to remove a product?`,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk: () => {
        setIsLoading(true);
        new ManifestAPI()
          .removeManifestProduct(product)
          .then(
            function (response) {
              setIsLoading(false);
              const resp = response.data.result;
              //setManifestDetail(resp);
              // set form change
              // setFormValid(false);
              const _data = sort_product(resp.products);
              setData(_data);
            },
            (err) => {
              setIsLoading(false);
            },
          )
          .finally(() => {
            setIsLoading(false);
          });
      },
      onCancel: () => {},
    });
  };
  //-----end remove product----

  return (
    <>
      <Card
        title={<span>Order number: {order_number ?? ""}</span>}
        style={{
          width: "100%",
        }}
        loading={loading}
      >
        {data && data.length > 0 ? (
          <>
            <div>
              <Table rowKey="id" dataSource={data} pagination={false}>
                <Column title="" dataIndex="sort_index" key="sort_index" />
                <Column
                  title="Product Description"
                  dataIndex="product_description"
                  key="product_description"
                />
                <Column
                  title="Finished or Intermediate Product"
                  dataIndex="finished_or_intermediate_product"
                  key="finished_or_intermediate_product"
                />
                <Column
                  title="Lot or Batch ID"
                  dataIndex="lot_or_batch_id"
                  key="lot_or_batch_id"
                />
                <Column
                  title="Sample Type"
                  dataIndex="sample_type"
                  key="sample_type"
                  render={(item, record) => {
                    const idx = SAMPLE_TYPE_LIST.findIndex(
                      (x) => x.value === item,
                    );
                    if (idx >= 0) {
                      return <>{SAMPLE_TYPE_LIST[idx].label}</>;
                    }
                    return null;
                  }}
                />
                <Column
                  title="Total Batch Size"
                  dataIndex="total_batch_size"
                  key="total_batch_size"
                  render={(item, record) => (
                    <>
                      {item != null
                        ? `${item} ${record.batch_size_unit}`
                        : null}
                    </>
                  )}
                />
                <Column
                  title="Total Weight Shipped"
                  dataIndex="total_weight"
                  key="total_weight"
                  render={(item, record) => (
                    <>{item != null ? `${item} ${record.weight_unit}` : null}</>
                  )}
                />
                <Column
                  title="Total Unit Shipped"
                  dataIndex="total_item"
                  key="total_item"
                  render={(item, record) => (
                    <>{item != null ? `${item} ${record.item_unit}` : null}</>
                  )}
                />
                <Column
                  title="Test Request"
                  dataIndex="test_request"
                  key="test_request"
                  render={(item, record) => {
                    if (item?.name) {
                      return (
                        <a
                          type="link"
                          onClick={() => {
                            onShowItemDetail(record);
                          }}
                        >
                          {item?.name}
                        </a>
                      );
                    }
                    return <></>;
                  }}
                />
                <Column
                  title="Status"
                  key="action"
                  render={(_, record) => (
                    <Space size="middle">
                      {(data[0].status === 15 ||
                        data[0].status === -16 ||
                        data[0].status === 99) && (
                        <div>
                          {record.is_agree ? (
                            <span>Accepted</span>
                          ) : (
                            <span>Returned</span>
                          )}
                        </div>
                      )}
                      {data[0].status !== 15 &&
                        data[0].status !== -16 &&
                        data[0].status !== 99 && (
                          <div>
                            <span>_ _ _</span>
                          </div>
                        )}
                    </Space>
                  )}
                />
                <Column
                  title="Action"
                  dataIndex="action"
                  key="action"
                  render={(item, record) => {
                    return editable && record.status !== 99 ? (
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            handleEditProduct(record);
                          }}
                        />
                        <Button
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={() => {
                            handleRemoveProduct(record);
                          }}
                        />
                      </Space>
                    ) : (
                      <></>
                    );
                  }}
                />
              </Table>
            </div>
          </>
        ) : (
          <p>No product data</p>
        )}
      </Card>
      {slcPackage && (
        <TestRequestModalView
          title={slcPackage.test_request.name}
          isOpen={slcPackage != null}
          onOk={() => {
            setSlcPackage(null);
          }}
          items={slcPackage.test_request.elements}
          extraItems={slcPackage.extra_test_request?.elements ?? null}
        />
      )}

      {editMode === "products" && (
        // <ManifestEditProductModal
        //   model={productEdit}
        //   onCancel={() => {
        //     setEditMode();
        //   }}
        //   onOk={(resp) => {
        //     setEditMode();
        //     setData(resp);
        //   }}
        //   isOpen={editMode && editMode !== ""}
        //   type={editMode}
        // />

        <ManifestAddProductItemModal
          model={productEdit}
          onCancel={() => {
            setEditMode();
            // setDataProduct(); // dataProduct=undefine
          }}
          onOk={(resp) => {
            setEditMode();

            const _data = sort_product(resp.products);
            setData(_data);
          }}
          isOpen={editMode && editMode !== ""}
          type={"Edit Product"}
          config={config}
          company_id={company_id}
        />
      )}
    </>
  );
};
export default ManifestProductEntityView;
