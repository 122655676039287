import React from "react";
import { Card, Row, Col } from "antd";
import Utils from "../../utils/utils";

const ManifestDestinationEntityView = ({ model, loading, editable }) => {
  return (
    <>
      <Card
        style={{
          width: "100%",
        }}
        loading={loading}
      >
        {model ? (
          <>
            {/* row 1 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Company Name:</strong>
              </Col>
              <Col span={6}>{model.company_name ?? "---"}</Col>

              <Col span={6}>
                <strong>Company Phone:</strong>
              </Col>
              <Col span={6}>{model.company_phone ?? "---"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee #:</strong>
              </Col>
              <Col span={6}>{model.license_number}</Col>

              <Col span={6}>
                <strong>Stop Number/ Max Number: </strong>
              </Col>
              <Col span={6}>
                {model.stop_number}/ {model.max_number}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 2 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee Name:</strong>
              </Col>
              <Col span={6}>{model.licensee_name}</Col>

              <Col span={6}>
                <strong>Arrival Date: </strong>
              </Col>
              <Col span={6}>{Utils.formatDate(model.arrival_date_on)}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 3 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee Address:</strong>
              </Col>
              <Col span={6}>{model.licensee_address}</Col>

              <Col span={6}>
                <strong>Arrival Time: </strong>
              </Col>
              <Col span={6}>{Utils.formatTime(model.arrival_date_on)}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 4 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong> Destination Address:</strong>
              </Col>
              <Col span={6}>{model.destination_address}</Col>

              <Col span={6}>
                <strong> Licensee Phone:</strong>
              </Col>
              <Col span={6}>{model.licensee_phone}</Col>
            </Row>
          </>
        ) : (
          <p>No destination data</p>
        )}
      </Card>
    </>
  );
};

export default ManifestDestinationEntityView;
