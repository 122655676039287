import React from "react";
import { Card, Row, Col, Flex, Image, Button } from "antd";
import Utils from "../../utils/utils";
import ViewListFileModal from "../../components/ViewListFileModal";
import { EditOutlined } from "@ant-design/icons";

const ManifestOriginationEntityView = ({ model, loading, editable }) => {
  const [modalListFile, setModalListFile] = React.useState(false);

  const external_token = localStorage.getItem("external_token");
  const company_id = localStorage.getItem("auth_company_id");

  // TODO: view list Attached certificate files
  const openModalListFile = () => {
    setModalListFile(true);
  };

  // TODO: get signature image
  const getSignatureByid = (signature_id) => {
    const urlSign1 = `${Utils.ApiEndpoint}/signature/${company_id}/read?id=${signature_id}&token=${external_token}`;
    return urlSign1;
  };
  //---------

  return (
    <>
      <Card
        style={{
          width: "100%",
        }}
        loading={loading}
      >
        {model ? (
          <>
            {/* row 1 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Company Name:</strong>
              </Col>
              <Col span={6}>{model.company_name ?? "---"}</Col>

              <Col span={6}>
                <strong>Company Phone:</strong>
              </Col>
              <Col span={6}>{model.company_phone ?? "---"}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 1 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>License #:</strong>
              </Col>
              <Col span={6}>{model.license_number}</Col>

              <Col span={12}>
                <strong>
                  Contact Name and Phone number Law Enforcement <br /> Can Call
                  with Questions:{" "}
                </strong>
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 2 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee Name:</strong>
              </Col>
              <Col span={6}>{model.licensee_name}</Col>

              <Col span={6}>
                <strong>Name:</strong>
              </Col>
              <Col span={6}>{model.contact_name}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 3 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee Address</strong>
              </Col>
              <Col span={6}>{model.licensee_address}</Col>

              <Col span={6}>
                <strong>Phone:</strong>
              </Col>
              <Col span={6}>{model.contact_phone}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row add */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Origination Address</strong>
              </Col>
              <Col span={18}>{model.origination_address}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />

            {/* row 4 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Licensee Phone:</strong>
              </Col>
              <Col span={6}>{model.licensee_phone}</Col>

              <Col span={6}>
                <strong>Departure Date:</strong>
              </Col>
              <Col span={6}>{Utils.formatDate(model.departure_date_on)}</Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 5 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Date Created:</strong>
              </Col>
              <Col span={6}>
                {Utils.formatDate(model.created_date_on)}
                {/* {model.date_created_on.toISOString()} */}
              </Col>

              <Col span={6}>
                <strong>Departure Time:</strong>
              </Col>
              <Col span={6}>
                {Utils.formatTime(model.departure_date_on)}
                {/* {model.departure_time_on} */}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 6*/}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Certificate of Analysis Attached?</strong>
              </Col>

              <Col span={6}>
                <span>
                  {model.is_certificate_attached === true ? "Yes" : "No"}
                </span>
              </Col>
              {/* TODO: view file pdf and download file */}
              <Col span={12}>
                {model.is_certificate_attached === true && (
                  <>
                    <a
                      href="#/"
                      onClick={() => {
                        openModalListFile();
                      }}
                    >
                      View files
                    </a>
                    {modalListFile && (
                      <ViewListFileModal
                        open={modalListFile}
                        onCancel={() => {
                          setModalListFile(false);
                        }}
                        modelFile={model}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>

            <hr style={{ opacity: "10%" }} />
            {/* row 7 */}
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <strong>Is this transport to a laboratory?</strong>
              </Col>

              <Col span={6}>
                {model.is_transport_to_laboratory === true ? "Yes" : "No"}
              </Col>
              <Col span={12}></Col>
            </Row>

            <hr style={{ opacity: "10%" }} />

            {/* row 8 */}
            <Row gutter={[16, 16]}>
              <Col span={18}>
                <strong style={{ color: "red" }}>
                  I attest that the contents of this shipment match the records
                  entered in Section 4 and are securely packaged for transport.
                </strong>
              </Col>
              <Col span={6}></Col>
            </Row>
            <hr style={{ opacity: "10%" }} />

            {/* row 8 */}
            {model.signature_id_approve &&
              model.signature_id_approve !== "None" && (
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <strong>Signature Approve & Date:</strong>
                  </Col>
                  <Col span={18}>
                    <Flex gap={10}>
                      <Flex justify="center" vertical>
                        {model.signature_name_approve}
                      </Flex>
                      {/* TODO: image */}
                      <Flex style={{ height: 50, width: 150 }} justify="center">
                        <Image
                          height={50}
                          src={getSignatureByid(model.signature_id_approve)}
                        />
                      </Flex>
                      {/* TODO: signature date */}
                      <Flex justify="center" vertical>
                        {Utils.formatDate(model.signature_date_approve)}
                      </Flex>
                    </Flex>
                  </Col>
                </Row>
              )}

            <hr style={{ opacity: "10%" }} />

            {/* row 8 */}
            {model.signature_id_edit && model.signature_id_edit !== "None" && (
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <strong>Signature Edit & Date:</strong>
                </Col>
                <Col span={18}>
                  <Flex gap={10}>
                    <Flex justify="center" vertical>
                      {model.signature_name_edit}
                    </Flex>
                    {/* TODO: image */}
                    <Flex style={{ height: 50, width: 150 }} justify="center">
                      <Image
                        height={50}
                        src={getSignatureByid(model.signature_id_edit)}
                      />
                    </Flex>
                    {/* TODO: signature date */}
                    <Flex justify="center" vertical>
                      {Utils.formatDate(model.signature_date_edit)}
                    </Flex>
                  </Flex>
                </Col>
              </Row>
            )}

            <hr style={{ opacity: "10%" }} />
          </>
        ) : (
          <p>No originating entity data</p>
        )}
      </Card>
    </>
  );
};

export default ManifestOriginationEntityView;
